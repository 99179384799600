import React from "react";

export const OtherSVG = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10.3333C1 3.33277 1.07496 1 10.3333 1C19.5917 1 19.6667 3.33277 19.6667 10.3333C19.6667 17.3339 19.6962 19.6667 10.3333 19.6667C0.970472 19.6667 1 17.3339 1 10.3333Z"
        stroke="url(#paint0_linear_502_17)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3359 10.3333C30.3359 3.33277 30.4109 1 39.6693 1C48.9276 1 49.0026 3.33277 49.0026 10.3333C49.0026 17.3339 49.0321 19.6667 39.6693 19.6667C30.3064 19.6667 30.3359 17.3339 30.3359 10.3333Z"
        stroke="url(#paint1_linear_502_17)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 39.6673C1 32.6668 1.07496 30.334 10.3333 30.334C19.5917 30.334 19.6667 32.6668 19.6667 39.6673C19.6667 46.6679 19.6962 49.0007 10.3333 49.0007C0.970472 49.0007 1 46.6679 1 39.6673Z"
        stroke="url(#paint2_linear_502_17)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3359 39.6673C30.3359 32.6668 30.4109 30.334 39.6693 30.334C48.9276 30.334 49.0026 32.6668 49.0026 39.6673C49.0026 46.6679 49.0321 49.0007 39.6693 49.0007C30.3064 49.0007 30.3359 46.6679 30.3359 39.6673Z"
        stroke="url(#paint3_linear_502_17)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_502_17"
          x1="1"
          y1="5.78633"
          x2="19.8502"
          y2="6.14317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_502_17"
          x1="30.3359"
          y1="5.78633"
          x2="49.1861"
          y2="6.14317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_502_17"
          x1="1"
          y1="35.1203"
          x2="19.8502"
          y2="35.4772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_502_17"
          x1="30.3359"
          y1="35.1203"
          x2="49.1861"
          y2="35.4772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
      </defs>
    </svg>
  );
};
