import { useEffect, useState } from "react";
import { useGetCitiesMutation, useGetCountriesMutation, useGetStatesMutation } from "../store/app/country.api";
import { useGetFeeListMutation, useGetTierListMutation } from "../store/auth/auth.api";

export const useCountry = ({ country, state }) => {
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [feeList, setFeeList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [selectedTierValue, setSelectedTierValue] = useState(null);
  const [selectedFeeId, setSelectedFeeId] = useState(null);

  const [getCountries] = useGetCountriesMutation();
  const [getStates] = useGetStatesMutation();
  const [getCities] = useGetCitiesMutation();
  const [getFeeList] = useGetFeeListMutation();
  const [getTiers] = useGetTierListMutation();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const result = await getCountries(); // Assuming getCountries is a function that fetches data from your API
        if (result?.data && Array.isArray(result.data)) {
          const countries = result.data.map(({ country_id, country_name }) => ({
            value: country_name,
            label: country_name,
            isoCode: country_id
          }));
          setCountryList(countries);
        } else {
          setCountryList([]); // Handle empty or invalid data response
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const result = await getStates({ country: country.isoCode });

      setStatesList(
        result?.data?.map(({ state_name, state_id }) => ({
          value: state_name,
          label: state_name,
          isoCode: state_id
        })) || []
      );
    };

    if (countryList.length > 0 && country.isoCode) {
      fetch();
    }
  }, [country]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getCities({ country: country.isoCode, state: state.isoCode });

      setCitiesList(
        result?.data?.map(({ district_id, district_name }) => ({
          value: district_name,
          label: district_name,
          id: district_id
        })) || []
      );
    };

    if (statesList.length > 0 && state.isoCode && country.isoCode) {
      fetch();
    }
  }, [state]);
  // useEffect(() => {
  //   const selectedCity = citiesList.find((city) => city.value === state.value);
  //   if (selectedCity) {
  //     setSelectedCityId(selectedCity.id);
  //   } else {
  //     setSelectedCityId(null);
  //   }
  // }, [state, citiesList]);
  useEffect(() => {
    const fetchFees = async () => {
      try {
        const result = await getFeeList();
        if (result?.data && Array.isArray(result.data)) {
          const fees = result.data.map(({ fees, feeid }) => ({
            value: fees,
            label: fees,
            feeId: feeid
          }));
          setFeeList(fees);

          // Save selected fee ID
          if (fees.length > 0) {
            setSelectedFeeId(fees[0].feeId); // Assuming you want the fee ID of the first fee
          }
        } else {
          setFeeList([]);
        }
      } catch (error) {
        console.error("Error fetching fees:", error);
      }
    };

    fetchFees();
  }, []);
  useEffect(() => {
    const fetchTiers = async () => {
      try {
        const result = await getTiers(); // Assuming getTiers is a function that fetches tier data from your API
        if (result.data.tiers && Array.isArray(result.data.tiers)) {
          const tiers = result.data.tiers.map((tier) => ({
            value: tier,
            label: tier
          }));
          setTierList(tiers);
          if (tiers.length > 0) {
            setSelectedTierValue(tiers[0].value); // Assuming you want the first tier valu
          } else {
            setTierList([]);
          }
        }
      } catch (error) {
        console.error("Error fetching tiers:", error);
      }
    };

    fetchTiers();
  }, []);

  return {
    countryList,
    statesList,
    citiesList,
    feeList,
    tierList,
    selectedFeeId,
    selectedTierValue
  };
};
