import React from "react";
import {
  BioTechnologistSVG,
  ChessSVG,
  CivilEngineerSVG,
  CodingSVG,
  CommunicationSVG,
  DancingSVG,
  DesigningSVG,
  GuitarSVG,
  InternetOfThingsSVG,
  LawyerSVG,
  LeadershipSVG,
  MachineLearningSVG,
  MechanicalEngineerSVG,
  NotListedSVG,
  OtherSVG,
  RoboticsSVG,
  ScientistSVG,
  SelfConfidenceSVG,
  SingingSVG,
  SoftwareEngineerSVG,
  SportsSVG,
  SurgeonSVG,
  TimeManagementSVG
} from "./icons";

export const QUESTIONSL = [
  {
    question: {
      preText: "",
      text: "What do you aspire to become",
      code: "Q2",
      question_id: "2",
      idle: 1
    },
    answers: [
      {
        text: "Software Engineer",
        icon: <SoftwareEngineerSVG />,
        code: "Ans1"
      },
      {
        text: "Bio-Technologist",
        icon: <BioTechnologistSVG />,
        code: "Ans2"
      },
      {
        text: "Mechanical Engineer",
        icon: <MechanicalEngineerSVG />,
        code: "Ans3"
      },
      {
        text: "Lawyer",
        icon: <LawyerSVG />,
        code: "Ans4"
      },
      {
        text: "Scientist",
        icon: <ScientistSVG />,
        code: "Ans5"
      },
      {
        text: "Surgeon",
        icon: <SurgeonSVG />,
        code: "Ans6"
      },
      {
        text: "Civil engineer",
        icon: <CivilEngineerSVG />,
        code: "Ans7"
      },
      {
        text: "Other",
        icon: <OtherSVG />,
        code: "Ans8"
      }
    ]
  },
  {
    question: {
      preText: "We have brilliant courses for you to upskill yourself",
      text: "What skill would  you like to master",
      code: "Q3",
      question_id: "3",
      idle: 2
    },
    answers: [
      {
        text: "Machine Learning",
        icon: <MachineLearningSVG />,
        code: "Ans1"
      },
      {
        text: "Coding",
        icon: <CodingSVG />,
        code: "Ans2"
      },
      {
        text: "Robotics",
        icon: <RoboticsSVG />,
        code: "Ans3"
      },
      {
        text: "Internet of Things",
        icon: <InternetOfThingsSVG />,
        code: "Ans4"
      },
      {
        text: "Designing",
        icon: <DesigningSVG />,
        code: "Ans5"
      },
      {
        text: "Others",
        icon: <OtherSVG />,
        code: "Ans6"
      }
    ]
  },
  {
    question: {
      preText: "Apart from academics, we need soft skill to excel in life.",
      text: "Choose any one trait you want to improve",
      code: "Q4",
      question_id: "4",
      idle: 3
    },
    answers: [
      {
        text: "Leadership",
        icon: <LeadershipSVG />,
        code: "Ans1"
      },
      {
        text: "Communication",
        icon: <CommunicationSVG />,
        code: "Ans2"
      },
      {
        text: "Time Management",
        icon: <TimeManagementSVG />,
        code: "Ans3"
      },
      {
        text: "Self Confidence",
        icon: <SelfConfidenceSVG />,
        code: "Ans4"
      },
      {
        text: "Others",
        icon: <OtherSVG />,
        code: "Ans5"
      }
    ]
  },
  {
    question: {
      preText: "Hey Life is not all about academics, we are here to excel you hobbies too.",
      text: "Choose your favourite past time",
      code: "Q5",
      question_id: "5",
      idle: 4
    },
    answers: [
      {
        text: "Dancing",
        icon: <DancingSVG />,
        code: "Ans1"
      },
      {
        text: "Singing",
        icon: <SingingSVG />,
        code: "Ans2"
      },
      {
        text: "Guitar",
        icon: <GuitarSVG />,
        code: "Ans3"
      },
      {
        text: "Sports",
        icon: <SportsSVG />,
        code: "Ans4"
      },
      {
        text: "Chess",
        icon: <ChessSVG />,
        code: "Ans5"
      },
      {
        text: "Not Listed",
        icon: <NotListedSVG />,
        code: "Ans6"
      }
    ]
  }
];
