import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import blueIllustration from "../assets/images/frontside/blue-illustration.svg";
// import funkyLines from "../assets/images/frontside/funkylines.png";
// import orangeIllustration from "../assets/images/frontside/orange-illustration.svg";
// import partyIcon from "../assets/images/frontside/partyIcon.svg";
// import { LayererCubeDownArrow } from "../assets/svg/LayererCubeDownArrow";
import { ROUTES } from "../constants/routes";
import { CubesSection } from "./components/CubesSection";
import { ExperienceSection } from "./components/ExperienceSection";
import { MembershipBenefitsSection } from "./components/MembershipBenefitsSection";
import { SignupWidget } from "./auth-widget/SignupWidget";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const NoAuthPages = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isHomePage = history.location.pathname === ROUTES.HOME;
  const content = t("home_page.p3_content");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width condition as needed
    };

    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header className="frontheader__banner">
        {/* <img src={blueIllustration} className="top-illustartion-two" alt="shape" />
        <img src={orangeIllustration} className="top-illustartion-one" alt="shape" />
        <img src={funkyLines} className="img-fluid funkylines" alt="funkylines" /> */}
        <div className="white__layerbg"></div>
        <div className="banner__contentbox no_auth">
          <div className="container">
            <div className="row">
              <div className={`col-lg-${isHomePage ? "12" : "7"}`}>
                <h1 className="banner__contentbox--title">
                  <span className="text--secondary--grad">{t("home_page.p1_heading")}</span>
                </h1>
                <p className="banner__contentbox--text mt-2 pt-1">{content}</p>
              </div>
              <div id="signup" className="col-md-6 col-lg-5 position-relative">
                <Switch>
                  <Route exact path={ROUTES.HOME} component={SignupWidget} />
                  <Redirect to={ROUTES.STUDENT} />
                </Switch>
              </div>

              {/* <div className="col-12 text-center pt-md-1">
                <a href="#layercube__section">
                  <LayererCubeDownArrow />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </header>
      {isMobile && <ExperienceSection />}
      <CubesSection />
      {!isMobile && <ExperienceSection />}
      <MembershipBenefitsSection />
    </>
  );
};
