import React from "react";
import englishImage from "../../assets/svg/exampage/banners/english_banner.svg";
import scoreImage from "../../assets/svg/exampage/banners/score_banner.svg";
import mathImage from "../../assets/svg/exampage/banners/math_banner.svg";
import witImage from "../../assets/svg/exampage/banners/wit_banner.svg";
import hotsImage from "../../assets/svg/exampage/banners/hots_banner.svg";
import ScoreLogo from "../../assets/svg/exampage/scoreLogo.png";
import ArrowLeft from "../../assets/svg/exampage/ArrowLeft.svg";
import { ROUTES } from "../../constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ExamPageBanner = ({ currentExam }) => {
  const history = useHistory();
  const getImage = (currentExam) => {
    switch (currentExam) {
      case "english":
        return englishImage;
      case "score":
        return scoreImage;
      case "math olympiad":
        return mathImage;
      case "wit":
        return witImage;
      case "hots olympiad":
        return hotsImage;
      case "i-emsat":
        return englishImage;
      default:
        return null;
    }
  };
  const handleBackButtonClick = () => {
    history.push(ROUTES.DASHBOARD);
  };
  return (
    <div>
      <figure className="banner">
        {currentExam && <img className="banner-img" src={getImage(currentExam)} alt={currentExam} />}
        <img onClick={handleBackButtonClick} className="arrowLeft" src={ArrowLeft} alt="logo" />
        {currentExam === "score" ? (
          <img className="logo-img" src={ScoreLogo} alt="logo" />
        ) : (
          <>
            <h1 className="banner-heading">{currentExam.toUpperCase()}</h1>
          </>
        )}
        <p className="card-des">Win up to 100% scholarship and exciting rewards.</p>
      </figure>
    </div>
  );
};
