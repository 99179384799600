import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordFormResolver } from "../../../constants/resolvers";
import { closeModal } from "../../../store/app/app.slice";
import { useResetPasswordMutation } from "../../../store/auth/auth.api";
import { getUserInfo } from "../../../store/auth/selectors";

export const ResetPasswordModalContent = () => {
  const dispatch = useDispatch();
  const [resetPass] = useResetPasswordMutation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const userInfo = useSelector(getUserInfo);
  const handlePasswordChange = (e, fieldName) => {
    const value = e.target.value;
    if (fieldName === "newPassword") {
      if (value.length < 6) {
        console.log("Password mut be 6 digit " + fieldName);
        setIsButtonDisabled(true);
        setError(fieldName, { message: "password must be at least 6 characters" });
      } else {
        clearErrors(fieldName);
        setIsButtonDisabled(false);
      }
    } else if (fieldName === "confirmPassword") {
      if (value.length < 6) {
        setError(fieldName, { message: "password must be at least 6 characters" });
        setIsButtonDisabled(true);
      } else {
        clearErrors(fieldName);
        setIsButtonDisabled(false);
      }
    }
  };
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
    setError,
    clearErrors
  } = useForm({ resolver: resetPasswordFormResolver });

  const onSubmit = async (data) => {
    const body = {
      id: userInfo.crn_id,
      current_password: userInfo?.school_id, // + userInfo?.phone.slice(-3),
      //current_password: data.password,
      new_password: data.newPassword.toUpperCase()
    };

    const res = await resetPass(body);

    const errorMessage = res?.data?.error?.message || res?.error?.data?.detail;

    if (errorMessage) {
      setError("password", { type: "custom", message: errorMessage });
    } else if (res.data.detail) {
      dispatch(closeModal());
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="reset-password-form">
      <div className="row">
        {/* <div className="col-12 mb-12 mt-4">
          <p className="login--supporttext">Please enter a current password</p>
        </div>
        <div className="col-12 mb-12">
          <input
            type="password"
            className={`member--inputbox ${errors.password ? "error--strike" : ""}`}
            placeholder="Current Password"
            {...register("password")}
          />
          {errors.password && <span className="error--strike--text">{errors.password.message}</span>}
        </div> */}
        <div className="col-12 mb-12 mt-4">
          <p className="login--supporttext">Please enter a new password</p>
        </div>
        <div className="col-12 mb-12">
          <input
            type="password"
            className={`member--inputbox ${errors.newPassword ? "error--strike" : ""}`}
            placeholder="New Password"
            {...register("newPassword")}
            name="newPassword"
            onChange={(e) => handlePasswordChange(e, "newPassword")}
          />
          {errors.newPassword && <span className="error--strike--text">{errors.newPassword.message}</span>}
        </div>
        <div className="col-12 mb-12">
          <input
            type="password"
            className={`member--inputbox ${errors.confirmPassword ? "error--strike" : ""}`}
            placeholder="Confirm Password"
            {...register("confirmPassword")}
            name="confirmPassword"
            onChange={(e) => handlePasswordChange(e, "confirmPassword")}
          />
          {errors.confirmPassword && <span className="error--strike--text">{errors.confirmPassword.message}</span>}
        </div>
        <div className="col-12 mb-4 pt-2 text-center">
          <button className="button--membersubmit" disabled={isButtonDisabled} type="submit">
            Reset password
          </button>
        </div>
      </div>
    </form>
  );
};
