import React from "react";

const CellPhoneIcon = () => {
  return (
    <div className="iconPosition d-flex d-flex-row justify-content-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        opacity={0.7}
        style={{ marginTop: "1.8px" }}
      >
        <path
          d="M2.23622 2.44792H4.85539L5.69947 4.55842L4.34322 5.46258C4.26335 5.51587 4.19787 5.58805 4.15259 5.67271C4.10731 5.75737 4.08362 5.8519 4.08364 5.94792C4.08539 6.00275 4.08364 5.9485 4.08364 5.9485V5.96075C4.08398 5.98684 4.08515 6.0129 4.08714 6.03892C4.09064 6.08675 4.09647 6.1515 4.10755 6.23142C4.1303 6.38892 4.17405 6.60592 4.25922 6.86142C4.43072 7.37475 4.76672 8.03917 5.42122 8.69367C6.07572 9.34817 6.74014 9.68417 7.25289 9.85567C7.50897 9.94083 7.72539 9.984 7.88405 10.0073C7.97361 10.0198 8.06376 10.0276 8.15414 10.0307L8.16172 10.0312H8.16639C8.16639 10.0312 8.23172 10.0278 8.16697 10.0312C8.27527 10.0312 8.38142 10.001 8.47353 9.94401C8.56563 9.88704 8.64006 9.80555 8.68847 9.70867L9.0793 8.927L11.667 9.35867V11.8787C10.4356 12.0566 7.10939 12.2322 4.49605 9.61883C1.88272 7.0055 2.05772 3.67875 2.23622 2.44792ZM5.29289 6.23142L6.34697 5.52908C6.56965 5.38055 6.73449 5.15996 6.81383 4.90431C6.89317 4.64866 6.88218 4.37351 6.78272 4.125L5.93864 2.0145C5.85202 1.79803 5.70252 1.61248 5.50944 1.48178C5.31636 1.35108 5.08854 1.28123 4.85539 1.28125H2.20589C1.67564 1.28125 1.17689 1.64933 1.08997 2.2245C0.891635 3.53233 0.622719 7.39517 3.67122 10.4437C6.71972 13.4922 10.5826 13.2227 11.8904 13.0249C12.4656 12.9374 12.8336 12.4392 12.8336 11.909V9.35867C12.8337 9.08249 12.7357 8.81526 12.5572 8.60452C12.3788 8.39377 12.1313 8.25318 11.8589 8.20775L9.27122 7.77667C9.02511 7.73559 8.77231 7.77449 8.54994 7.88767C8.32757 8.00085 8.14734 8.18234 8.03572 8.4055L7.83389 8.80975C7.76251 8.79217 7.69187 8.77174 7.62214 8.7485C7.26047 8.62833 6.75822 8.381 6.24605 7.86883C5.73389 7.35667 5.48655 6.85442 5.36639 6.49217C5.33769 6.40654 5.31335 6.31951 5.29347 6.23142H5.29289Z"
          fill="#F2F2F2"
        />
      </svg>
      <span className="defaultvalue">+91</span>
    </div>
  );
};

export default CellPhoneIcon;
