import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ToolTip from "./ToolTip";
import { Tick } from "../../assets/svg/Tick";
import NavLogo from "../../assets/images/frontside/logo.svg";
import { getUserInfo, isSchoolUserType } from "../../store/auth/selectors";
import { CopySvg } from "../../assets/svg/CopySvg";
import profileIcon from "../../assets/svg/profileIcon.svg";
import phoneCall from "../../assets/svg/phoneCall.svg";
import ArrowLeft2 from "../../assets/svg/ArrowLeft2.svg";
import { ROUTES } from "../../constants/routes";

export const Navbar = () => {
  const userInfo = useSelector(getUserInfo);
  const isSchool = useSelector(isSchoolUserType);
  const history = useHistory();

  const [copied, setCopied] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isPlanner, setIsPlanner] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(userInfo.admission_id);
    setCopied(true);

    // After a few seconds, reset the copied state to show the CopySvg again
    setTimeout(() => {
      setCopied(false);
    }, 3000); // Adjust the time in milliseconds as needed
  };

  const handleProfileClick = () => {
    setIsProfileClicked(!isProfileClicked);
    const route = isProfileClicked ? ROUTES.DASHBOARD : ROUTES.PROFILE;
    history.push(route);
  };

  const pathname = history.location.pathname;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleTooltipClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      const newPathname = newLocation.pathname;
      setIsOnboarding(newPathname === "/student/onboarding");
      setIsProfile(newPathname === "/profile");
      setIsPlanner(newPathname === "/dashboard");
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const condition = pathname !== "/profile" || windowWidth >= 768;

  return (
    <>
      <nav className="navbar__custom">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {pathname !== "/profile" || windowWidth >= 768 ? (
              <div className="d-flex align-items-center">
                <div className="navbar-brand">
                  <img src={NavLogo} alt="log" className="logo" />
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div className="navbar-brand navbar-brand_mob">
                  <p className="mb-0 profile-logo">
                    <a href="#">
                      <img src={ArrowLeft2} onClick={handleProfileClick} style={{ marginRight: "13px" }} />
                    </a>
                    My Profile
                  </p>{" "}
                </div>
              </div>
            )}
            {!isSchool && condition && userInfo && userInfo.admission_id ? (
              <div className="admissionId-container">
                <div className="login-id-container">
                  {!isOnboarding ? (
                    <div className="user-names">
                      <img onClick={handleProfileClick} className="profile-img" src={profileIcon} alt="Profile" />
                      {pathname === "./profile" ? (
                        <ToolTip content="8792740465">
                          <img className="profile-img phone-icon" src={phoneCall} alt="Call" />
                        </ToolTip>
                      ) : (
                        ""
                      )}
                      <p className="admission-id Student_name">
                        {userInfo.first_name + " "}
                        {userInfo.last_name ? userInfo.last_name : ""}
                      </p>
                      <div className="vertical-line Student_name"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  <p className="login-id Student_name">Login ID:</p>
                  <p className="admission-id Student_name">{userInfo.admission_id}</p>
                </div>
                <span className="copy-btn Student_name">
                  {copied ? <Tick /> : <CopySvg />}
                  <p onClick={handleCopy} className="text-copy">
                    {copied ? "Copied" : "Copy"}
                  </p>
                </span>
                {(!isProfile && windowWidth <= 786) || ((isProfile || isPlanner) && windowWidth > 786) ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="vertical-line Student_name"></div>
                    <ToolTip
                      content="8792740465"
                      onClick={() => {
                        if (window.innerWidth <= 768) {
                          handleTooltipClick("8792740465");
                        }
                      }}
                    >
                      <img className="profile-img phone-icon" src={phoneCall} alt="Call" />
                    </ToolTip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
      {!isSchool && pathname !== "/profile" && userInfo && userInfo.admission_id ? (
        <div className="nav-bottom">
          <div className="nav-bottom-container">
            <p className="login-id">Login ID:</p>
            <p className="admission-id">{userInfo.admission_id}</p>
            <span onClick={handleCopy} className="copy-btn_b">
              {copied ? <Tick /> : <CopySvg />}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
