import React from "react";
import { ROUTES } from "../constants/routes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LoginOtp } from "./components/LoginOtp";

export const StudentOTPLoginPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <header className="frontheader__banner">
        <div className="white__layerbg"></div>
        <div className="banner__contentbox no_auth">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-7">
                <h1 className="banner__contentbox--title">
                  <span className="text--secondary--grad">{t("home_page.p1_heading")}</span>
                </h1>
                <p className="banner__contentbox--text mt-2 pt-1">{t("home_page.p1_content")}</p>{" "}
              </div>
              <div id="signup" className="col-md-12 col-lg-5 position-relative">
                <div className="membership__card mx-auto ms-md-auto">
                  <div className="tab-content">
                    <h3 className="tab-heading">Student Login</h3>

                    <LoginOtp />
                  </div>
                </div>
              </div>
              <div className="col-12 create-ac-link-web">
                <p className="login--supporttext">
                  <p>
                    Don&apos;t have an Inmöbius account?
                    {
                      <Link to={ROUTES.STUDENT_CREATE_ACCOUNT} className="gard--textflow">
                        Create Account
                      </Link>
                    }
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
