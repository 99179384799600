import React from "react";
import Green from "../../assets/svg/reportspage/greendot.svg";
import Red from "../../assets/svg/reportspage/reddot.svg";
import Gray from "../../assets/svg/reportspage/graydot.svg";

const CircleProgressBar = ({ totalMarks, correctMarks, incorrectMarks, unattempted }) => {
  const radius = 48.8857;
  const circumference = 2 * Math.PI * radius;

  const greenProgress = (correctMarks / totalMarks) * 100;
  const redProgress = (incorrectMarks / totalMarks) * 100;

  const greenStrokeLength = (greenProgress / 100) * circumference;
  const redStrokeLength = (redProgress / 100) * circumference;

  const neutralLength = circumference - greenStrokeLength - redStrokeLength;
  return (
    <div className="Rep_graph_card">
      <h3 className="Rep_graph_cardHead">Questions Attempted</h3>
      <div className="Rep_graph_cont">
        <div className="circle-cont1">
          <svg width="118" height="118" viewBox="0 0 118 118" fill="none">
            <circle cx="59" cy="59" r={radius} stroke="#EFF0F7" strokeWidth="20.2286" />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#2CBE6A"
              strokeWidth="20.2286"
              strokeDasharray={`${greenStrokeLength} ${neutralLength + redStrokeLength}`}
              strokeDashoffset={circumference}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#FF5353"
              strokeWidth="20.2286"
              strokeDasharray={`${redStrokeLength} ${neutralLength + greenStrokeLength}`}
              strokeDashoffset={circumference - greenStrokeLength}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
          </svg>
        </div>
        <div className="circle-cont2">
          <svg width="80" height="" viewBox="0 0 118 118" fill="none">
            <circle cx="59" cy="59" r={radius} stroke="#EFF0F7" strokeWidth="20.2286" />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#2CBE6A"
              strokeWidth="20.2286"
              strokeDasharray={`${greenStrokeLength} ${neutralLength + redStrokeLength}`}
              strokeDashoffset={circumference}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#FF5353"
              strokeWidth="20.2286"
              strokeDasharray={`${redStrokeLength} ${neutralLength + greenStrokeLength}`}
              strokeDashoffset={circumference - greenStrokeLength}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
          </svg>
        </div>
        <div className="Rep_lable_cont">
          <div className="Rep_lable_contIn">
            <img src={Green} />
            <p className="Rep_lable_title">Correct :</p>{" "}
            <p>
              {correctMarks}/{totalMarks}
            </p>
          </div>
          <div className="Rep_lable_contIn">
            <img src={Red} />
            <p className="Rep_lable_title">Incorrect :</p>{" "}
            <p>
              {incorrectMarks}/{totalMarks}
            </p>
          </div>
          <div className="Rep_lable_contIn">
            <img src={Gray} />
            <p className="Rep_lable_title">Unattempted :</p>{" "}
            <p>
              {unattempted}/{totalMarks}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleProgressBar;
