import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG_VARS } from "../../constants/configuration";

const { countryApiKey, baseUrl, apiVersion } = CONFIG_VARS;

export const countryApi = createApi({
  reducerPath: "countryApi",
  baseQuery: fetchBaseQuery({
    headers: {
      "X-CSCAPI-KEY": countryApiKey
    }
  }),
  endpoints: (build) => ({
    getCountries: build.mutation({
      query: () => ({
        url: `${baseUrl["1"]}/${apiVersion}/geo-codes/countries`,
        method: "GET"
      })
    }),
    getStates: build.mutation({
      query: ({ country }) => ({
        url: `${baseUrl["1"]}/${apiVersion}/geo-codes/states?country_id=${country}`,
        method: "GET"
      })
    }),
    getCities: build.mutation({
      query: ({ country, state }) => ({
        url: `${baseUrl["1"]}/${apiVersion}/geo-codes/districts?country_id=${country}&state_id=${state}`,
        method: "GET"
      })
    }),
    ValidatePincode: build.mutation({
      query: ({ City_id, Pincode }) => ({
        url: `${baseUrl["1"]}/${apiVersion}/geo-codes/validate-pincode?district_id=${City_id}&pincode=${Pincode}`,
        method: "GET"
      })
    })
  })
});

export const { useGetCountriesMutation, useGetStatesMutation, useGetCitiesMutation, useValidatePincodeMutation } =
  countryApi;
