export const studentCreateAccFormFields = {
  firstName: false,
  grade: false,
  schoolId: false,
  phone: false,
  TC: true
};

export const studentLoginIdPasswordFormFields = {
  loginId: false,
  password: false
};

export const schoolCreateAccFormFields = {
  firstName: false,
  emailId: false,
  schoolName: false,
  inviteCode: false,
  phone: false,
  TC: true
};
