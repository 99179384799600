import React from "react";
import school from "../../assets/svg/School.svg";
import student from "../../assets/svg/Student.svg";
import ArrowRight from "../../assets/svg/ArrowRightCircle.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

export const SignupWidget = () => {
  const history = useHistory();
  const handleStudenClick = () => {
    history.push(ROUTES.STUDENT);
  };
  const handleSchoolClick = () => {
    history.push(ROUTES.SCHOOL_CREATE_ACCOUNT);
  };
  return (
    <div className="card-container">
      <div className="card1" onClick={handleStudenClick}>
        <img src={student} alt="Student" className="card-img" />
        <div className="crdtitle-arrow-box">
          <h1 className="card-h1">{`I'm student`}</h1>
          <img src={ArrowRight} alt="" className="arrow-right" />
        </div>
      </div>
      <div className="card1" onClick={handleSchoolClick}>
        <img src={school} alt="School" className="card-img" />
        <div className="crdtitle-arrow-box">
          <h1 className="card-h1">{`I'm school`}</h1>
          <img src={ArrowRight} alt="" className="arrow-right" />
        </div>
      </div>
    </div>
  );
};
