import React from "react";

export const StudentOnboardingPopUp = ({ setApiError, setModal, errormsg, show, onSelect }) => {
  const handleYesClick = () => {
    onSelect(true);
  };

  return (
    <div
      className={`modal importmodal alertmodal newalert fade ${show ? "show" : ""}`}
      id="importModal"
      tabIndex="-1"
      aria-labelledby="importModalLabel"
      aria-hidden="true"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <svg
              data-bs-dismiss="modal"
              className="xs-closeicon d-md-none"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L13 13" stroke="#F2F2F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13 1L1 13" stroke="#F2F2F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="text-center">
              <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28.3218 3.88867C29.0916 2.55534 31.0161 2.55534 31.7859 3.88867L52.9554 40.5553C53.7252 41.8887 52.7629 43.5553 51.2233 43.5553H8.88431C7.34471 43.5553 6.38246 41.8887 7.15226 40.5553L28.3218 3.88867Z"
                  fill="#E06F6C"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.09555 40.6012C1.44518 40.6012 -0.830372 36.6427 0.994813 33.4901L18.8437 2.65155C20.6689 -0.501039 25.22 -0.501039 27.0452 2.65155L44.8941 33.4901C46.7192 36.6427 44.4437 40.6012 40.7933 40.6012H5.09555ZM40.7933 35.8604L22.9444 5.02192L5.09555 35.8604H40.7933ZM20.5741 16.8975V21.6382C20.5741 22.9419 21.6407 24.0086 22.9444 24.0086C24.2481 24.0086 25.3148 22.9419 25.3148 21.6382V16.8975C25.3148 15.5938 24.2481 14.5271 22.9444 14.5271C21.6407 14.5271 20.5741 15.5938 20.5741 16.8975ZM25.3148 33.4901V28.7493H20.5741V33.4901H25.3148Z"
                  fill="#F2F2F2"
                />
              </svg>
            </div>
            <div className="mt-2">
              <p className="alert-m-heading text-center">Are you sure?</p>
              <p className="mx-auto alert-m-text text-center mt-2 max--320">
                Do you want to submit your all responses?
              </p>
            </div>
            <div className="mt-3 d-btn-two">
              <button
                type="button"
                className="borderBtn"
                data-bs-dismiss="modal"
                onClick={() => {
                  setModal(false);
                  setApiError("");
                }}
              >
                <span>No</span>
              </button>
              <a href="#">
                <button type="button" style={{ width: "100%" }} className="saveBtn pink--fill" onClick={handleYesClick}>
                  Yes
                </button>
              </a>
            </div>
            {errormsg && (
              <span className="error-text " style={{ marginTop: "15px", fontSize: "12px" }}>
                {errormsg}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
