import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { ProfilePage } from "./profile/ProfilePage";
import { DashboardPage } from "./dashboard/DashboardPage";
import { StudentOnboarding } from "./student/onboarding";
import { useSelector } from "react-redux";
import { getUserInfo } from "../store/auth/selectors";
import ExamPage from "./examPage";
import { Reports } from "./Reports";
import { OlympaidReport } from "./OlympaidReport";
import { EmsatReports } from "./EmsatReports";

export const AuthPages = ({ setIsExamPage, isExamPage }) => {
  const userInfo = useSelector(getUserInfo);
  const isActive = userInfo.is_active;
  const [condition, setCondition] = useState(false);
  const history = useHistory();
  useEffect(() => {
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", function () {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
    window.location.hash = "no-back-button";
    window.location.hash = "Again-No-back-button";
    window.onhashchange = function () {
      window.location.hash = "no-back-button";
    };
  }, []);
  useEffect(() => {
    var screenWidth = window.screen.width;
    if (isExamPage && screenWidth <= 768) {
      setCondition(true);
    } else {
      setCondition(false);
    }
  }, [isExamPage, window.screen.width, condition]);
  useEffect(() => {
    const currentPath = history.location.pathname;
    if (
      currentPath === ROUTES.EXAMPAGE ||
      currentPath.includes("/reports") ||
      currentPath.includes("/olympaidreports") ||
      currentPath.includes("/iemsatreports")
    ) {
      setIsExamPage(true);
    } else {
      setIsExamPage(false);
    }
  }, [history.location.pathname]);

  return (
    <section className="student__onboarding__widget w-100" style={condition ? { padding: "0px" } : {}}>
      <Switch>
        <Route exact path={ROUTES.STUDENT} component={isActive ? DashboardPage : StudentOnboarding} />
        <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
        <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
        <Route exact path={ROUTES.EXAMPAGE} component={ExamPage} />
        <Route exact path="/reports/:crnId/:examId" component={Reports} />
        <Route exact path="/olympaidreports" component={OlympaidReport} />
        <Route exact path="/iemsatreports" component={EmsatReports} />
        <Route exact path={ROUTES.STUDENT_ONBOARDING} component={StudentOnboarding} />
        <Redirect to={ROUTES.DASHBOARD} />
      </Switch>
    </section>
  );
};
