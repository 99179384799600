import queryString from "query-string";
import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { studentSignUpFormResolver } from "../../../../constants/resolvers";
import { ROUTES } from "../../../../constants/routes";
import { invitationCodeSchema } from "../../../../constants/schemas";
import {
  useGetAllSchoolsQuery,
  useStudentSignUpMutation,
  useValidateInvitationCodeMutation,
  useValidatePhoneMutation
} from "../../../../store/auth/auth.api";
import { useSendOTPMutation, useVerifyOTPMutation } from "../../../../store/auth/otp.api";
import { CustomSelector } from "../../../components/CustomSelector";
import { useTimer } from "react-timer-hook";
import { PhoneInput } from "../../../components/form/PhoneInput";
import { phoneNumberSchema } from "../../../../constants/schemas";
import { studentCreateAccFormFields } from "../../../../constants/constants";
import OtpInput from "react-otp-input";
import loaderGif from "../../../../assets/gifs/LoaderGif.gif";
let { firstName, schoolId, grade, phone, TC } = studentCreateAccFormFields;

export const StudentSignUpForm = ({ toggleTAndC, togglePAndP }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otpValue, setOtpValue] = useState(Array(4).fill(""));
  const [sendOTP, { isLoading: sendOTPLoading, isUninitialized }] = useSendOTPMutation();
  const { restart, seconds, isRunning } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      setTimerExpired(true);
      setIsTimerCompleted(true);
    }
  });
  const [verifyPhoneNumber] = useValidatePhoneMutation();

  const { CODE = null } = queryString.parse(useLocation().search);

  const [onRegister] = useStudentSignUpMutation();
  const [validateInvitationCode, { isUninitialized: validateInvitationCodeIsUninitialized }] =
    useValidateInvitationCodeMutation();
  const [verifyOTP, { isSuccess: verifyOTPIsSuccess }] = useVerifyOTPMutation();
  const { data: schoolList, isLoading: schoolListLoading } = useGetAllSchoolsQuery();

  const form = useForm({
    resolver: studentSignUpFormResolver,
    defaultValues: { schoolCode: 0, invitationCode: CODE || "" },
    mode: "onChange"
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    clearErrors,
    trigger,
    setValue
  } = form;
  const { formState } = form;
  const { isSubmitting } = formState;

  const [invitationCode] = watch(["invitationCode"]);
  const defaultChecked = watch([
    "firstName",
    "lastName",
    "gradeCode",
    // "schoolName",
    "invitationCode",
    "otpCode",
    "phoneNumber"
  ]);

  useEffect(() => {
    checkValidCode();
  }, [invitationCode]);

  useEffect(() => {
    if (CODE) {
      checkValidCode();
    }
  }, []);

  const schoolListParse = useMemo(() => {
    if (!schoolListLoading && schoolList?.length) {
      return schoolList.map((value) => ({
        id: value.grade_id,
        value: value.grade_name
      }));
    }

    return [];
  }, [schoolListLoading, schoolList]);

  const checkValidCode = async () => {
    if (invitationCodeSchema.isValidSync(invitationCode)) {
      const result = await validateInvitationCode({ invitationCode });
      await clearErrors("invitationCode");

      if (!result?.data?.valid_code) {
        setError("invitationCode", { message: "Code is not valid" });
        setIsButtonDisabled(true);
      }
    } else if (!validateInvitationCodeIsUninitialized) {
      setError("invitationCode", { message: "Code is not valid" });
      setIsButtonDisabled(true);
    }
  };

  const onSubmit = async (data) => {
    if (verifyOTPIsSuccess) {
      const res = await onRegister(data);
      if (res.data.detail) {
        setError("phoneNumber", { type: "custom", message: res.data.detail });
      } else if (res.data.message) {
        setError("invitationCode", { type: "custom", message: res.data.message });
      }
    } else {
      setError("otpCode", { type: "custom", message: "Otp code must be verified!" });
    }
    localStorage.setItem("studentFormData", JSON.stringify(data));
  };

  const clickSendOTP = async () => {
    setOtpValue(Array(4).fill(""));
    setError("otpCode", { type: "custom", message: "" });
    setIsButtonDisabled(true);
    await trigger("phoneNumber");
    setIsTimerCompleted(false);

    if (phoneNumberIsValid) {
      const result = await sendOTP({ phoneNumber });

      if (result?.data) {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 30);
        restart(time);
        setIsEditEnabled(true);
      }

      if (result?.error) {
        // Handle error
      }
    }
  };
  const handlePhoneNumberChange = async (phoneNumber) => {
    if (phoneNumber.length === 10) {
      const result = await verifyPhoneNumber(phoneNumber);
      if (result?.data?.isVerified) {
        setError("phoneNumber", { message: "Phone number verification failed" });
        setIsButtonDisabled(false); // Disable the button if phone number is invalid
      } else {
        clearErrors("phoneNumber");
        setValue("phoneNumberVerified", true);
        //setIsButtonDisabled(false); // Enable the button if phone number is valid
      }
    } else {
      setError("phoneNumber", { message: "Phone number must be atleast 10 digits" });
      setIsButtonDisabled(true); // Disable the button if phone number is invalid
    }
  };

  const handleEditNumberClick = () => {
    setIsDisable(false);
    setIsButtonDisabled(true);
    setIsEditEnabled(false);
    setShowOtpInput(false); // hiding OtpInput box
    setIsOtpSent(false); // disable button and setting back to the send otp state
    setOtpValue(Array(4).fill("")); // Clear the OTP input field
    Array(4)
      .fill("")
      .forEach((_, index) => {
        clearErrors(`otpCode[${index}]`); // Clear any errors related to the OTP input field
      });
    restart();
    setIsTimerCompleted(true);
  };

  const [phoneNumber, ...otpCodes] = watch(["phoneNumber", ...Array(4).fill("otpCode")]);
  const phoneNumberIsValid = useMemo(() => phoneNumberSchema.isValidSync(phoneNumber), [phoneNumber]);

  const disabled = useMemo(() => {
    return {
      phoneForm: isRunning || sendOTPLoading || isSubmitting,
      otpForm: !(isRunning || !isUninitialized) || isSubmitting
    };
  }, [isRunning, isUninitialized, sendOTPLoading, isSubmitting, phoneNumberIsValid]);

  useEffect(() => {
    if (timerExpired && isOtpSent) {
      setTimerExpired(false);
    }
    if (timerExpired && !isOtpSent) {
      setShowOtpInput(false); // Hide OTP input
      setTimerExpired(false);
    }
  }, [timerExpired, isOtpSent]);
  const handleButtonAction = async () => {
    if (!isOtpSent) {
      // Send OTP on the first click
      setIsDisable(true);
      setIsOtpSent(true);
      setLoader(true);
      await clickSendOTP();
      setLoader(false);
      setIsTimerCompleted(false);
      setShowOtpInput(true);
      setIsButtonDisabled(true);
    } else {
      // Handle OTP verification and signup
      setLoader(true);
      await handleSignUp();
      setLoader(false);
    }
  };

  const onBlur = async (fieldName) => {
    await trigger(fieldName);
  };
  const handleSignUp = async () => {
    const otpString = otpValue.join("");
    setIsTimerCompleted(true);
    // Trigger validation for OTP field
    await trigger("otpCode");

    if (otpString.length === 4) {
      // If OTP is exactly 4 digits, proceed with verification
      setValue("otpCode", otpString);
      const result = await verifyOTP({ otpCode: otpString, phoneNumber });
      if (result?.error?.data?.message?.includes("Incorrect OTP, please try again")) {
        setError("otpCode", { type: "custom", message: "OTP is incorrect or not valid" });
        setIsButtonDisabled(true);
      } else if (!result?.data) {
        setError("otpCode", { type: "custom", message: "OTP is incorrect or not valid" });
        setIsButtonDisabled(true);
      } else if (result?.data) {
        const formData = form.getValues();
        const res = await onRegister(formData);

        if (res.data.detail?.includes("Similar users found")) {
          setError("phoneNumber", {
            type: "custom",
            message: "User already exists! Please use a different number to create account."
          });
          phone = false;
          handleEditNumberClick();
        } else if (res.data.message) {
          setError("invitationCode", { type: "custom", message: res.data.message });
          setIsButtonDisabled(true);
          handleEditNumberClick();
          schoolId = false;
        }
      }
    } else {
      // If OTP is not exactly 4 digits, show an error
      setError("otpCode", { type: "custom", message: "OTP must be exactly 4 digits." });
    }
  };

  const handleInputKeyPress = (field) => {
    switch (field.name) {
      case "firstName":
        // eslint-disable-next-line no-case-declarations
        const nameValue = field.value.replace(/^\s+/g, "").replace(/[^A-Za-z\s]/gi, "");
        if (nameValue.length === 0) {
          setValue(field.name, "");
          firstName = false;
        } else if (/^[A-Za-z][A-Za-z\s]*$/.test(nameValue)) {
          setValue(field.name, nameValue);
          firstName = true;
        } else {
          setValue(field.name, field.value.trimLeft());
          firstName = false;
        }
        break;
      case "lastName":
        // eslint-disable-next-line no-case-declarations
        const lastNameValue = field.value.replace(/[^A-Za-z\s]/gi, "");
        setValue(field.name, lastNameValue);
        break;
      case "gradeId":
        setValue(field.name, field.id);
        grade = field?.id > 0;
        break;
      case "invitationCode":
        // eslint-disable-next-line no-case-declarations
        const codeValue = field.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
        schoolId = codeValue.length > 0;
        setValue(field.name, codeValue);
        break;
      case "remember":
        TC = field?.checked;
        break;
      case "phoneNumber":
        setValue(field.name, field.value);
        phone = field.value.length === 10;
        break;
      default:
        break;
    }
    const isAllFieldsFilled = firstName && schoolId && phone && TC && grade;
    (otpValue.join("").length === 4 || !isOtpSent) && setIsButtonDisabled(!isAllFieldsFilled);
  };

  const setAllFieldsStatesToInitial = () => {
    firstName = false;
    schoolId = false;
    phone = false;
    TC = true;
    grade = false;
  };

  const handleOtpCodeChange = (e) => {
    if (e.length >= 0 && e.length <= 3) {
      setIsButtonDisabled(true);
      // Handle case where OTP string is not 4 digits
      setError("otpCode", { type: "custom", message: "OTP must be exactly 4 digits." });
    } else {
      // setError("otpCode", { type: "custom", message: "" });
      clearErrors("otpCode");
      setIsButtonDisabled(false);
    }
    setOtpValue([...e]);
    // setIsButtonDisabled(e.length !== 4);
  };

  return (
    <form onChange={(e) => handleInputKeyPress(e.target)} onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-6 pe-2 mb-12">
          <label className="tab-lable">First Name</label>
          <input
            type="text"
            style={{ fontFamily: "Source Code Pro" }}
            className={`member--inputbox ${errors.firstName ? "error--strike" : "input-highlight"}`}
            {...register("firstName")}
            required
            disabled={isDisable}
          />
          {errors.firstName && <span className="error--strike--text">{errors.firstName.message}</span>}
        </div>
        <div className="col-6 ps-2 mb-12">
          <label className="tab-lable">Last Name</label>
          <input
            type="text"
            style={{ fontFamily: "Source Code Pro" }}
            className={`member--inputbox ${errors.lastName ? "error--strike" : ""}`}
            {...register("lastName")}
            // required
            disabled={isDisable}
          />
          {errors.lastName && <span className="error--strike--text">{errors.lastName.message}</span>}
        </div>
        <label className="tab-lable">Select your grade</label>
        <CustomSelector
          handleInputKeyPress={handleInputKeyPress}
          data={schoolListParse}
          placeHolder={"Choose grade"}
          defaultSelectedID={0}
          errors={errors}
          dispatchValue={"gradeCode"}
          register={register}
          disabled={isDisable}
        />
        <div className="col-12 mb-12">
          <label className="tab-lable">School ID</label>
          <input
            type="text"
            style={{ fontFamily: "Source Code Pro" }}
            className={`member--inputbox ${errors.invitationCode ? "error--strike" : "input-highlight"}`}
            placeholder="Enter school ID"
            {...register("invitationCode")}
            required
            disabled={isDisable}
          />
          {errors.invitationCode && <span className="error--strike--text">{errors.invitationCode.message}</span>}
        </div>
        <div className="position-relative">
          <label className="tab-lable">Phone Number</label>
          <PhoneInput
            form={form}
            disabled={isEditEnabled}
            handleChange={handlePhoneNumberChange}
            onBlur={() => onBlur("phoneNumber")}
            handleEditNumberClick={handleEditNumberClick}
            isEditEnabled={isEditEnabled}
          />

          {errors.phoneNumber && <span className="error--strike--text">{errors.phoneNumber.message}</span>}
          {disabled.verifyButton &&
            otpCodes.map((_, index) => {
              return (
                errors[`otpCode[${index}]`] && (
                  <span className="error--strike--text" key={index}>
                    {errors[`otpCode[${index}]`].message}
                  </span>
                )
              );
            })}
        </div>
        {showOtpInput && (
          <div>
            <label className="tab-lable">Enter OTP</label>
            <div className="col-12 position-relative mb-12">
              <div className="d-flex align-items-center">
                {/* Replaced the individual input fields with OtpInput */}
                <OtpInput
                  inputType="number"
                  skipDefaultStyles={false}
                  shouldAutoFocus="false"
                  value={otpValue.join("")}
                  onChange={(e) => handleOtpCodeChange(e)}
                  numInputs={4}
                  separator={<span> </span>}
                  isDisabled={disabled.otpForm}
                  inputStyle="otp-input-box"
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      key={index}
                      // type="text"
                      required
                      className={`otp-input-box ${errors.otpCode?.message ? "otp-error-strick" : "highlight"}`}
                    />
                  )}
                />
                <div className="resend_otp">
                  {!isTimerCompleted && (
                    <span>
                      Resend OTP <br />({seconds} Sec.)
                    </span>
                  )}
                  {isTimerCompleted && (
                    <button className="resend-btn" onClick={clickSendOTP}>
                      Resend OTP
                    </button>
                  )}
                </div>
              </div>
            </div>
            {errors.otpCode && <span className="error--strike--text">{errors.otpCode.message}</span>}
            {/* {otpCodes.map((_, index) => {
                  return (
                    errors[`otpCode[${index}]`] && (
                      <button className="error--strike--text" key={index}>
                        {errors[`otpCode[${index}]`].message}
                      </button>
                    )
                  );
                })} */}
          </div>
        )}
      </div>
      <div className="col-12 mb-3">
        <div className="form-check check-opacity">
          <label className="form-check-label text-muted">
            <input
              type="checkbox"
              className="form-check-input"
              name="remember"
              {...register("remember")}
              defaultChecked={defaultChecked}
            />
            <i className="input-helper"></i> Notify me on WhatsApp <br />
          </label>
          <br />
          <span className="t-c_class">
            By Signing up, you agree with our{" "}
            <span onClick={toggleTAndC} className="gard--textflow mx-1">
              T&C
            </span>{" "}
            and{" "}
            <span className="gard--textflow mx-1" onClick={togglePAndP}>
              PP
            </span>
          </span>
          <br />
          {errors.remember && <span className="error--strike--text">This field is required</span>}
        </div>
      </div>
      <button className="button--membersubmit w-100" onClick={handleButtonAction} disabled={isButtonDisabled}>
        {isOtpSent ? (
          loader ? (
            <div className="loader-container">
              <img className="loader-gif" src={loaderGif} alt="Loading.." />
              <p className="loader-text">Loading</p>
            </div>
          ) : (
            "Create Account"
          )
        ) : loader ? (
          <div className="loader-container">
            <img className="loader-gif" src={loaderGif} alt="Loading.." />
            <p className="loader-text">Loading</p>
          </div>
        ) : (
          "Continue"
        )}
      </button>
      <div className="hr-box">
        <hr className="hr-line" />
        <span>Or</span>
        <hr className="hr-line" />
      </div>
      <div className="col-12 mt-3">
        <p className="login--supporttext">
          Already having account?
          <Link onClick={setAllFieldsStatesToInitial} to={ROUTES.STUDENT} className="gard--textflow mx-1">
            Login
          </Link>
        </p>
      </div>
    </form>
  );
};
