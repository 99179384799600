import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Redirect, Route, Router, Switch } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { ModalComponent } from "../pages/components/modal";
import { NoAuthPages } from "../pages/NoAuthPages";
import { Navbar } from "../pages/components/Navbar";
import { AuthPages } from "../pages/AuthPages";
import { SchoolAuthPages } from "../pages/SchoolAuthPages";
import { PageNotFound } from "../pages/PageNotFound";
import { userLogout } from "../store/auth/auth.slice";
import { getIsAuthenticated, getUserInfo, isSchoolUserType, isStudentUserType } from "../store/auth/selectors";
import { history } from "./history";
import { SessionTimeout } from "../pages/components/SessionTimeout";
import { StudentLoginPage } from "../pages/StudentLoginPage";
import { StudentSignUpWidget } from "../pages/StudentSignUpWidget";
import { SchoolSignUpWidget } from "../pages/components/SchoolSignUpWidget";
import { RequestCodeWidget } from "../pages/auth-widget/RequestCodeWidget";
import { StudentOTPLoginPage } from "../pages/StudentOTPLoginPage";
import { TAndC } from "../pages/T&C";
import { PAndP } from "../pages/PP";
import { ExamPageNav } from "../pages/components/examPageNav";
const MainRouter = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isStudent = useSelector(isStudentUserType);
  const isSchool = useSelector(isSchoolUserType);
  const userInfo = useSelector(getUserInfo);
  const [isExamPage, setIsExamPage] = useState(false);
  const currentTheme = useMemo(() => {
    if (isAuthenticated && isStudent && isExamPage) {
      return "exam";
    } else if ((isAuthenticated && isStudent) || !isAuthenticated) {
      return "dark";
    } else {
      return "light"; // Default theme
    }
  }, [isAuthenticated, isStudent, isExamPage]);
  useEffect(() => {
    if (!isAuthenticated) {
      setIsExamPage(false);
    }
  }, [isExamPage, isAuthenticated]);
  useEffect(() => {
    const currentPath = history.location.pathname;

    const redirectToPath = () => {
      switch (true) {
        case !isAuthenticated:
          if (currentPath === ROUTES.STUDENT) {
            return ROUTES.STUDENT;
          }
          break;
        case isStudent:
          if (userInfo?.is_active) {
            //next contion is to login to the dasbort if its registrtion revort any redirection errors
            if (currentPath === ROUTES.STUDENT_CREATE_ACCOUNT || currentPath === ROUTES.OTPLOGIN) {
              return ROUTES.DASHBOARD;
            } else {
              return currentPath;
            }
          } else {
            return ROUTES.STUDENT_ONBOARDING;
          }
        case isSchool:
          if (userInfo?.is_active) {
            // Uncomment when school dashboard page will be done
            // return ROUTES.DASHBOARD;
            dispatch(userLogout()); // Remove when school dashboard page will be done
            return ROUTES.HOME;
          } else {
            return ROUTES.SCHOOL_ONBOARDING;
          }
        default:
          return null;
      }
    };

    const pathToRedirect = redirectToPath();
    if (pathToRedirect) {
      history.push(pathToRedirect);
    }
  }, [isAuthenticated]);

  return (
    <div className={`theme-${currentTheme} body-wrapbox`}>
      <Router history={history}>
        {isExamPage ? <ExamPageNav /> : <Navbar isAuthenticated={isAuthenticated} />}
        <SessionTimeout />
        <Switch>
          <Route path={ROUTES.HOME} component={NoAuthPages} /> {/* Add new route for "/login" */}
          <Route path={ROUTES.STUDENT_CREATE_ACCOUNT} component={StudentSignUpWidget} />
          <Route path={ROUTES.TANDC} component={TAndC} />
          <Route path={ROUTES.PANDP} component={PAndP} />
          <Route path={ROUTES.SCHOOL_CREATE_ACCOUNT} component={SchoolSignUpWidget} />
          <Route path={ROUTES.OTPLOGIN} component={StudentOTPLoginPage} />
          <Route path={ROUTES.REQUEST_CODE} component={RequestCodeWidget} />
          <Route path={ROUTES.PAGE_NOT_FOUND} component={PageNotFound} />
          {isAuthenticated ? (
            <Route
              path={"/"}
              render={() =>
                isSchool ? <SchoolAuthPages /> : <AuthPages isExamPage={isExamPage} setIsExamPage={setIsExamPage} />
              }
            />
          ) : (
            <Route path={"/"} component={StudentLoginPage} />
          )}
          <Redirect to={ROUTES.PAGE_NOT_FOUND} />
        </Switch>
      </Router>
      <ModalComponent />
    </div>
  );
};

export { MainRouter };
