import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrossIcon } from "../../../assets/svg";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { closeModal } from "../../../store/app/app.slice";
import { getModalState } from "../../../store/app/selectors";

export const ModalComponent = () => {
  const dispatch = useDispatch();
  const state = useSelector(getModalState);

  const close = () => dispatch(closeModal());

  const containerRef = useOutsideClick(close);

  return state.open ? (
    <div className="modal-wrapper">
      <div className="modal-container1" ref={containerRef}>
        <button className="modal-close" onClick={close}>
          <CrossIcon width={20} height={20} />
        </button>
        <p className="modal-title">{state.title}</p>
        <div>{state.content}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};
