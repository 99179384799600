import React, { useEffect } from "react";
import BackArrow from "../assets/svg/LeftArrow.svg";
import { useHistory } from "react-router-dom";

export const TAndC = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleback = () => {
    history.push("/home");
  };
  useEffect(() => {
    // Hide scrollbar on mount
    document.body.style.overflow = "hidden";

    // Re-enable scrollbar when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div>
        <div className="container_t mod">
          <img onClick={handleback} src={BackArrow} className="arrow" />
          <h1 className="tc-title">Terms and Conditions</h1>
        </div>
        <div className="TCcontainer full-screen-modal ">
          <h1 className="tc-heading">Terms of Use.</h1>
          <p>
            <p className="tc-para">
              The website, mobile application(s), SD cards, tablets or other storage/transmitting device associated with
              the URL{" "}
              <a className="a-link" href="link">
                {" "}
                https://inmobiusinfinitylearn.com{" "}
              </a>
              (&quot;<span className="para-bold">Website</span>”) is a Platform operated by Rankguru Technology
              Solutions Private Limited (“<span className="para-bold">Company</span>”). Company makes available the
              Platform and Services to you on the condition that you abide by these Terms of Use. The Services (as
              defined below) give you the ability to access and usage of online educational courses and other content on
              the Platform. Company may, in its sole discretion, change any aspect of the Services or discontinue any of
              the Services without notice.
              <br />
              <br />
              These Terms of Use ( “<span className="para-bold">Terms of Use</span>”) constitute a binding legal
              agreement between you (either as an individual or entity as applicable) and Company for accessing and
              using the SD cards, Platform and Services made available through the Platform, as also the licensed mobile
              application for accessing the Website and may include associated SD cards, tablets or other
              storage/transmitting device, media, and online or electronic documentation (the “
              <span className="para-bold">Application</span>”). The Website and Application are hereinafter collectively
              referred to as “<span className="para-bold">Platform</span>”.
              <br />
              <br />
              By accessing, installing, copying or otherwise using the Platform or any of its components, you agree to
              be bound by these Terms of Use. If you do not agree to these Terms of Use, please do not access or install
              the Platform. The Application is intended to enable access to the Platform through a device solely in
              accordance with these Terms of Use.
              <br />
              <br />
              Access to any part of the Platform or any Content downloaded from the Platform is purely contingent on
              your acceptance of and compliance with these Terms of Use. Additionally, on the basis of the relevant
              Services availed by you, certain product specific terms and conditions may apply. <br />
              <br />
              The Company may change these Terms of Use from time to time at its sole discretion without providing any
              notice to you. Your continued access or usage of the Platform following any modification of the Terms of
              Use shall indicate your acceptance of any modification to the Terms of Use. You may object to the
              modification to the Terms of Use by terminating your account or no longer accessing or using the Platform.
              By accessing or using the Platform, you agree to be bound by the Terms of Use. Company reserves the right,
              at its sole discretion, to terminate or suspend your access or use of the Platform if you violate any of
              the Terms of Use.
              <br />
              <br /> The Company shall have the right to monitor the download and usage of the SD cards, Platform,
              products, or Services, and the contents thereof by you to analyse your usage of the SD cards, Platform,
              products, or Services, revise or update the content on the SD cards, Platform, products, or Services, or
              discuss the same with the you to enable effective and efficient usage of the SD cards, Platform, products,
              or Services.
            </p>
          </p>
          <p className="tc-para">
            <span className="para-bold">Consent to receive Commercial Communications.</span> You hereby consent to be
            contacted by the Company and its employees, officers, suppliers, agents, subcontractors, and representatives
            for commercial communications with respect to advertising, promoting, marketing, or selling its products and
            Services, including without limitation, receiving commercial communications and being contacted by way of
            phone call, SMS, internet text messages, IVR, internet telephony or any other mode of communication. I
            acknowledge and agree that this consent will override any registration for Do Not Call (DNC) or National Do
            Not Call (NDNC) registry.
            <br />
            <br />
            By accepting these Terms of Use, you hereby consent to receiving communications from the Company, including
            without limitation, alerts, promotional offers, newsletter, tips and advice via phone call, internet
            telephony, SMS, internet text messaging, interactive voice response (IVR) calls, robotic calls etc.
          </p>
          <p className="tc-para">
            <span className="para-bold">THIRD PARTY CONSENT.</span> IN THE EVENT YOU SHARE PERSONAL INFORMATION OF ANY
            THIRD PARTY WITH THE COMPANY, YOU SHALL BE SOLELY LIABLE OBTAIN PRIOR CONSENT FROM SUCH THIRD PARTY FOR
            SHARING OF THEIR PERSONAL INFORMATION WITH THE COMPANY, AND COLLECTION, ACCESSING, PROCESSING, USE,
            TRANSFER, AND STORAGE OF SUCH PERSONAL INFORMATION BY THE COMPANY. YOU SHALL OBTAIN SUCH CONSENT PRIOR TO
            SHARING THEIR PERSONAL INFORMATION WITH THE COMPANY.
            <br />
            <br />
            This document is an electronic record published in accordance with the provisions of The Information
            Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the Rules and Regulations,
            Privacy Policy and Terms of Service for access or usage of the website and being generated by a computer
            system, it does not require any physical or digital signatures
          </p>
          <br />
          <ol className="tc-para" type="1">
            <li>
              <span className="para-bold">Eligibility.</span> You hereby represent and warrant that (a) you are
              competent to enter into a valid binding contract in accordance with the applicable laws; (b) you have the
              requisite rights to enter into this agreement. If you are entering into this agreement on behalf of any
              other person, you have the requisite legal authority and consent of the person on whose behalf you are
              entering into this agreement. However, if you are a minor, i.e. under the age of 18 years, you are
              required to access the Platform strictly under the supervision of a parent or legal guardian who agrees to
              be bound by these Terms of Use. Company reserves the right, at its sole discretion, to terminate your
              access to the Platform at any time without any notice if it determines that you are a minor; and (c) you
              shall access the Platform strictly in accordance with these Terms of Use.
            </li>
            <br />
            <li>
              <span className="para-bold">Purpose.</span> The Company makes various content available on the Platform
              only for users with a bona fide intent to avail the online educational courses and certain allied services
              offered on the Platform (“<span className="para-bold">Services</span>”). Services on the Platform shall
              not be used for any other purpose.
              <br />
              <br />
              Wherever applicable, Company also makes available educational materials to users, including without
              limitation, books, hardware, and any other tangible items or materials (collectively, “
              <span className="para-bold">Educational Materials</span>”). The Company makes available Educational
              Materials only for users with a bona fide intent to avail the Services in online mode and/or offline mode,
              as may be applicable. Services (including the Educational Materials) shall not be used for any other
              purpose.
              <br />
              <br />
              The Platform, Content and/or Educational Materials provided by the Company are intended solely for
              educational purposes and should be used only in the context of academic or educational activities
              consistent with these Terms of Use.
              <br />
              <br /> The Platform (including related products and Services made available on the Platform or in an
              offline medium) or Services that you subscribe to (whether paid or free of cost) is meant solely for the
              Purpose and your exclusive access and usage. Copying, downloading, recreating, sharing passwords,
              sublicensing or sharing the license granted to you herein in any manner which is not in accordance with
              these Terms of Use, is a violation of these Terms of Use, and Company reserves its rights to act in such
              manner as to protect its rights, including loss of revenue or reputation or claim damages including
              terminating access to the Platform and Services, and reporting to relevant authorities. In the event you
              are found to be copying or misusing or transmitting or crawling any data or photographs or graphics or any
              information available on the Platform or Services, Company reserves the right to take such action that it
              deems fit, including terminating access to the Platform and claiming damages.
            </li>
            <br />
            <li>
              <span className="para-bold">Content.</span> Content from Company, users, educational institutions,
              advertisers, and various third parties is made available to you on the Platform or in an offline medium. “
              <span className="para-bold">Content</span>” means any work of authorship or information, which includes
              reviews, ratings, questions and answers, tips and advice, comments, test and preparatory materials or any
              other education related materials or information in online of offline medium, including without
              limitation, educational text books, notebooks, teacher responses, comments, opinions, messages, text,
              files, images, photos, e-mail, data or other materials present on the Platform submitted by teachers,
              individual users, Company etc.
              <br />
              <br />
              You agree not to (a) reproduce, modify, publish, transmit, distribute, publicly perform or display, sell
              the Content, or adapt or create derivative works of the Content; or (b) rent, lease, loan, or sell access
              to Platform or any Content under any circumstances.
            </li>
            <br />

            <li>
              <span className="para-bold">Educational Materials.</span> The Educational Materials are provided on an
              &quot;AS IS&quot; and “AS AVAILABLE” basis without any warranties, whether expressed or implied, including
              but not limited to the implied warranties of merchantability or fitness for a particular purpose. <br />
              <br />
              You hereby agree not to:
              <ol className="tc-para" type="a">
                <li>Use the Educational Materials for any commercial, non-educational, or non-academic purposes;</li>
                <li>Modify, reverse engineer, disassemble, or tamper with the Educational Materials;</li>
                <li>
                  Transfer or assign the Educational Materials to any third party without prior written consent from the
                  Company; and
                </li>
                <li>Use the Educational Materials in any manner that violates applicable laws or regulations.</li>
                <br />
              </ol>
            </li>
            <li>
              <span className="para-bold">Use of Platform, Website, or Services.</span> The Platform, products, or
              Services, including the SD card, Website, Educational Materials, and content, are compatible only with
              certain devices, tablets, instruments, or hardware. The Company shall not be obligated to provide products
              or services for any instruments that are not recognised by the Company, or devices or instruments that may
              be purchased from any third party which are not compatible with the Company&apos;s products, Website,
              Platform, or Services. The Company reserves the right to modify, upgrade, suspend, or discontinue the SD
              card, tablet, specifications or compatibility of any devices or content from time to time at its sole
              discretion without any notice to you. Additionally, Company shall have no responsibility for any loss or
              damage caused to any device, tablet, instrument, or any other hardware or software, including loss of data
              or any adverse effect on the device, tablet, instrument, or any other hardware or software, resulting from
              your access or usage of the Platform, Website, products, or Services. You acknowledge that Company is not
              the manufacturer of the device, tablet, instrument, or any other hardware, and any defect arising out of
              or in connection with the same shall be directly reported to the manufacturer of the relevant device,
              tablet, instrument, or any other hardware, and Company shall not be in any manner responsible for the
              same. The Company does not provide any guarantee or warranty relating to the device, tablet, instrument,
              or any other hardware.
            </li>
            <br />
            <li>
              <span className="para-bold">Use of Content.</span> Content may be made available to you from time to time
              in online or offline medium, at the sole discretion of the Company. The Company reserves the right to
              modify, upgrade, suspend, or discontinue the Content or any portion thereof from time to time at its sole
              discretion without any notice to you. Additionally, Company shall have no responsibility for any loss or
              damage caused to you or any third party arising out of or in connection with the Content made available to
              you. You acknowledge that Company. The Company does not provide any guarantee or warranty relating to the
              Content in any form or manner, whether such Content is on an online or offline medium.
            </li>
            <br />
            <li>
              <span className="para-bold">Third Party Links.</span> The Platform may contain links to third party
              websites, these links are provided solely as convenience to you and the presence of these links should not
              under any circumstances be considered as an endorsement of the contents of the same. If you chose to
              access these websites, you do so at your own risk.
              <br />
              <br />
              You understand and agree that (a) Company is not responsible for and does not endorse any information
              about third party products and services, or information provided by other users on the Platform; (b)
              Company makes no guarantees about the accuracy, currency, suitability, reliability or quality of the
              information in such Content; and (c) Company assumes no responsibility for unintended, objectionable,
              inaccurate, misleading, or unlawful Content made available by users, advertisers, and third parties. The
              Company shall not be held liable for claims arising out of or in connection with the Content provided by
              third parties.
            </li>
            <br />
            <li>
              <span className="para-bold">Your Information.</span> You are solely responsible to provide true and
              correct information about yourself. When you create an account on the Platform, you undertake to keep the
              account information up to date at all times.
              <br /> <br /> We reserve the right to remove or suspend any Content or account providing false, incorrect
              or incomplete information.
              <br />
              <br />
              You are solely responsible for keeping your contact and account details up to date on the Platform.
              Providing wrong and false details shall be a cause for termination of Services or access to the Platform.
              Additionally, as a consequence of the same, the Company may not be able to contact you.
              <br />
              <br />
              The Company, its subsidiaries, and affiliates may, based on any form of access to the Platform, SD cards,
              including any free download or trials, Services, Website, or registrations through any source, contact you
              by way of SMS, internet text message, email, telephone call, internet telephony, IVRS or any other mode of
              communication to give information about their offerings and products as well as notifications on various
              important updates, or to seek permission for demonstration of its products. You expressly grant such
              permission to contact you by way of SMS, internet text message, email, telephone call, internet telephony,
              IVRS or any other mode of communication and hold the Company, its subsidiaries, and affiliates indemnified
              against any liabilities including losses, damages, claims, penalties, or expenses in the event your
              contact number is registered on the Do Not Call (DNC) database. By registering yourself on the Platform,
              you hereby agree to make your contact details available to the Company, its subsidiaries, affiliates,
              suppliers, subcontractors, and partners and consent to be contacted for commercial communications by and
              on behalf of the Company for advertising, marketing, promoting, and selling products and Services by way
              of SMS, internet text message, email, telephone call, internet telephony, IVRS or any other mode of
              communication.
            </li>
            <br />
            <li>
              <span className="para-bold">Login Details.</span> If you have registered on the Platform, the safety and
              security of your login credentials is solely your responsibility, and you should not share the same with
              anyone.
              <br />
              <br />
              You shall be solely responsible for carrying out any online or offline transaction involving credit
              cards/debit cards or such other forms of instruments or documents for making such transactions, and
              Company assumes no responsibility or liability for any misuse of information relating to such usage of
              credit cards/debit cards.
              <br />
              <br />
              You are fully responsible for all activities that occur under your account on the Platform and any other
              actions taken in connection with the Platform from your account. It is presumed that all activity taking
              place from your account on the Platform are occurring with your knowledge and consent.
            </li>
            <br />
            <li>
              <span className="para-bold">Payment and Refunds.</span> In consideration of the Services provided by the
              Company, you shall pay to Company such amounts (“<span className="para-bold">Service Fees</span>”) as set
              out on the Platform for such Services. If the Services are made available to you without payment of any
              consideration, you acknowledge and agree that with you are not a ‘consumer’ under the applicable laws,
              including the Consumer Protection Act, 2019 and rules framed thereunder, and shall not be entitled to
              bring any claim or action against Company with respect to the Services.
              <br />
              <br />
              If you make payment of Service Fees to the Company in consideration of the Services, the following payment
              terms shall apply: Company shall raise invoices for payment of Service Fees as set out on the Platform
              with respect to the relevant Services. Invoices raised by Company shall contain such particulars as
              prescribed under the GST Act and Rules thereunder, as amended from time to time for the Service Fees
              payable by you.
              <br />
              <br />
              You shall pay the invoices within such period of time as applicable to the payment terms and applicable
              plan selected by you at the time of purchasing the Services, if any.
              <br />
              <br />
              In event of failure on your part make payments in a timely manner, Company reserves its right to take
              appropriate legal action against you that may be available to it under these Terms of Use or as per
              applicable law. Any late payments to Company shall attract payment of interest, fines and penalties
              depending on the payment mode and terms selected by you at the time of purchasing the Services.
              <br />
              <br />
              If you have selected third party payment modes or platforms, including deferred payment mode or facility,
              you shall be solely liable to comply with the terms and conditions of such third parties applicable to the
              payment facility selected by you. Please note that Company shall not be liable for your transactions with
              such third parties, and you hereby indemnify Company, its parent entity, subsidiaries, affiliates, and
              each of their directors, officers, licensors, suppliers, partners, agents and representatives to the
              fullest extent permitted by applicable laws against any losses, damages, claims, fines, cost, penalty,
              suit or proceedings incurred, sustained or brought against the Company arising out of or in connection
              with any dispute between you and such third party.
              <br />
              <br />
              If as per the applicable tax laws, you are required to deduct taxes at source (“
              <span className="para-bold">TDS</span>”) on Service Fees paid to Company, you shall make payment of
              Service Fees net of such TDS and shall provide a proof of such TDS deduction, not later than 60 days
              following the quarter in which such invoice was raised by Company. In case of non-receipt of TDS credit
              and TDS certificate by the Company within the said timelines, Company has a right to claim such TDS amount
              as recoverable from you against the invoice issued.
              <br />
              <br />
              Upon signing of the Agreement, the Customer shall provide GST Identification Number and such other
              documents as may be required by Company for purpose of generating appropriate invoices and for settlement
              of payments.
              <br />
              <br />
              The Platform offers paid Services also, and the payments made by you for the paid Services shall be in
              accordance with the plans selected by you on the Platform. You expressly agree to pay the Service Fees for
              the Services availed by you, and you authorize the Company to charge you as per the applicable payment
              mode opted by you. All payments shall be through the payment mechanism opted by you, and you shall be
              responsible for paying all fees and applicable taxes in a timely manner as per the mechanism associated
              with the payment plan availed by you. You have been given various options to choose and proceed with the
              payment for the Services via payment mechanisms including but not limited to Card Payment facilities, EMI
              Payment, Online Bank Transfer and Wallet Payment. The payment mechanisms are governed by the terms and
              conditions of the respective third-party providers as listed on the Platform and you agree to be bound by
              those terms.
              <br />
              <br />
              Valid Credit / Debit / Cash Card / online bank transfers and other payment instruments are processed using
              a credit card payment gateway or appropriate payment system infrastructure and the same will also be
              governed by the terms agreed between you and the respective issuing bank and payment instrument issuing
              entity. The Company shall not be liable for any unauthorized use, fraud, payment refunds, lost amount etc.
              in the transaction.
              <br />
              <br />
              Any charges related to Platform publishing, e.g., charges levied by app store, play store will be incurred
              by you.
              <br />
              <br />
              Any request for refund of Service Fees shall be governed by the refund policy set forth on the Platform.
            </li>
            <br />
            <li>
              DISCLAIMER OF WARRANTIES. YOU HEREBY ACKNOWLEDGE AND AGREE THAT THE PLATFORM, CONTENT, SERVICES, OR
              EDUCATIONAL MATERIALS PROVIDED BY COMPANY MAY INCLUDE THIRD-PARTY PRODUCTS OR COMPONENTS. COMPANY HEREBY
              DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, RELATED TO THESE EDUCATIONAL MATERIALS. THIS
              DISCLAIMER OF WARRANTIES INCLUDES, BUT IS NOT LIMITED TO, THE FOLLOWING: <br />
              <br />
              NO WARRANTY OF MERCHANTABILITY: COMPANY MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR
              IMPLIED, AS TO THE MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE PLATFORM, CONTENT, SERVICES,
              OR ANY EDUCATIONAL MATERIALS PROVIDED. YOU HEREBY ACKNOWLEDGE THAT YOU HAVE SELECTED THE PLATFORM,
              CONTENT, SERVICES, OR EDUCATIONAL MATERIALS BASED ON YOUR OWN ASSESSMENT OF SUITABILITY FOR THEIR INTENDED
              USE.
              <br />
              <br />
              NO WARRANTY OF NON-INFRINGEMENT: COMPANY DOES NOT WARRANT THAT THE USE OF THE PLATFORM, CONTENT, SERVICES,
              OR ANY EDUCATIONAL MATERIALS PROVIDED WILL NOT INFRINGE ANY THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS. IT
              IS YOUR RESPONSIBILITY TO ENSURE THAT YOUR USE OF THE PLATFORM, CONTENT, SERVICES, OR EDUCATIONAL
              MATERIALS DOES NOT VIOLATE ANY THIRD-PARTY RIGHTS.
              <br />
              <br />
              NO WARRANTY OF COMPATIBILITY: COMPANY DOES NOT GUARANTEE THE COMPATIBILITY OF THE PLATFORM, CONTENT,
              SERVICES, OR EDUCATIONAL MATERIALS WITH YOUR EXISTING SYSTEMS, SOFTWARE, OR HARDWARE. YOU ASSUME ALL RISK
              ASSOCIATED WITH COMPATIBILITY ISSUES.
              <br />
              <br />
              NO WARRANTY FOR THIRD-PARTY SUPPORT: COMPANY IS NOT RESPONSIBLE FOR PROVIDING SUPPORT OR WARRANTY SERVICES
              FOR ANY THIRD PARTY CONTENT, SERVICES, OR EDUCATIONAL MATERIALS. ANY WARRANTY CLAIMS OR SUPPORT REQUESTS
              RELATED TO SUCH THIRD PARTY CONTENT, SERVICES, OR EDUCATIONAL MATERIALS SHOULD BE DIRECTED TO THE
              RESPECTIVE MANUFACTURER OR PROVIDER.
              <br />
              <br />
              NO WARRANTY FOR ACCURACY OR RELIABILITY: COMPANY DOES NOT WARRANT THE ACCURACY, RELIABILITY, OR
              COMPLETENESS OF ANY INFORMATION, DOCUMENTATION, OR SPECIFICATIONS PROVIDED BY MANUFACTURERS OR PROVIDERS
              OF THIRD PARTY CONTENT, SERVICES, OR EDUCATIONAL MATERIALS. YOU SHOULD VERIFY SUCH INFORMATION
              INDEPENDENTLY.
              <br />
              <br />
              LIMITATION OF LIABILITY: IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
              INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIRD PARTY CONTENT,
              SERVICES, OR EDUCATIONAL MATERIALS, INCLUDING BUT NOT LIMITED TO, LOSS OF DATA, LOSS OF PROFITS, OR DAMAGE
              TO PROPERTY, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              <br />
              <br />
              EXCLUSIVE REMEDY: YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY ISSUES OR DISPUTES RELATED TO THIRD PARTY
              CONTENT, SERVICES, OR EDUCATIONAL MATERIALS SHALL BE TO SEEK RECOURSE DIRECTLY FROM THE MANUFACTURER OR
              PROVIDER, IN ACCORDANCE WITH THEIR RESPECTIVE TERMS AND CONDITIONS, WARRANTIES, OR POLICIES.
              <br />
              <br />
              BY ACCEPTING AND USING THE PLATFORM, CONTENT, SERVICES, OR EDUCATIONAL MATERIALS PROVIDED BY THE COMPANY,
              YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THIS DISCLAIMER OF WARRANTIES. IF YOU HAVE
              ANY QUESTIONS OR CONCERNS REGARDING THIS DISCLAIMER, YOU SHOULD CONTACT THE COMPANY BEFORE USING THE
              PLATFORM, CONTENT, SERVICES, OR EDUCATIONAL MATERIALS.
            </li>
            <br />

            <li>
              <span className="para-bold">Taxes.</span> Any reference to duties and taxes etc. in the fees payable by
              you for availing Services shall include Goods and Services Tax (herein referred as GST) from the date GST
              law is implemented in India. Any additional tax liability arising on account of introduction of GST
              (whether on account of increase in rate or any change brought in by a new tax regime) would be recovered
              over and above the fees paid by you.
              <br />
              <br />
              <span className="para-bold">Credits.</span> The Company may, at its sole discretion, provide free credits
              to you for availing the Services (hereinafter referred to as “<b>Credits</b>”). In such cases, you may
              avail Services by redeeming the Credits made available to you. The usage or redemption of Credits shall be
              governed by the Terms of Use read with the below mentioned Terms Governing Credits.
              <br />
              <br />
              <span className="para-bold">Terms Governing Credits.</span>
              <ul>
                <li>
                  Access to the Services using redemption of Credits should not be perceived as any commitment on the
                  part of Company to continue to provide free Credits. Nothing herein amounts to a commitment by Company
                  to provide further, similar or other Credits in future.
                </li>
                <br />
                <li>
                  The Credits may be in such form, manner, and medium as determined by Company from time to time at its
                  sole discretion. Additionally, in order to use the Credits, you shall be required to comply with such
                  requirements as determined by Company at its sole discretion from time to time
                </li>
                <br />
                <li>
                  Company shall have the sole discretion in determining the total Credits to be made available to you.
                </li>
                <br />
                <li>
                  You may be required to submit such details as required by Company in order to use the Credits, which
                  could include name, postal address with pin code and govt. issued address proof, govt. issued birth
                  certificate with govt. issued age proof, Picture, video, contact number, photograph with govt. issued
                  ID proof, valid proof of admission to the relevant course offered by Company, current and previous
                  years’ mark sheets, any other document or information requested by Company, and a no-objection
                  certificate to the satisfaction of Company.
                </li>
                <br />
                <li>
                  Company shall have the absolute and sole discretion to determine whether your access to or use of
                  Credits should be suspended, revoked, or terminated by reason of failure to abide by these terms and
                  conditions, terms and conditions of Platform, bad faith, fraud or any other reason at the sole
                  discretion of Company.
                </li>
                <br />
                <li>
                  Incomplete information provided by a you, failure to provide true and accurate information as required
                  by Company, or failure to submit proof of identification or any other information required by Company
                  will automatically disqualify you from using the Credits, and access to your account and Services
                  shall stand revoked without the requirement of any notice, or any liability to Company.
                </li>
                <br />
                <li>
                  You hereby consent to use of your image, photograph, video, name and likeness in publicity material by
                  Company and in advertising, marketing or promotional material in any media by any means throughout the
                  world for any purpose connected with the products/services of Company or any company within the
                  Company’s group of companies without additional compensation or prior notice.
                </li>
                <br />
                <li>
                  Credits may be valid for a limited period only, subject to the sole discretion of Company. Company
                  reserves the right at any time and from time to time to modify, suspend or discontinue, temporarily or
                  permanently, the Credits and/or these terms and conditions without any prior notice. The decision of
                  Company is final and binding and no correspondence shall be entered into in this regard
                </li>
                <br />
                <li>
                  You represent and warrant that he/she shall be solely liable to comply with the applicable laws and
                  regulations with respect to your use of the Credits.
                </li>
                <br />
                <li>
                  Company disclaims all responsibility associated with the Credits, including without limitation, any
                  warranties associated with the Credits, any additional costs or expenses associated with the Credits,
                  its issuance, distribution, or any other liability, cost, or expenses associated with usage or
                  redemption of the Credits. Company shall not be liable for any losses, damages, claims, taxes,
                  liabilities or inconvenience suffered by you as a result of these terms and conditions, accessing,
                  using, or redeeming the Credits or any portion thereof.
                </li>
                <li>
                  Company shall not be responsible for any deficiency in the Credits and does not make any
                  representation about the quality, make, performance, features, endurance, finishing, benefits, or
                  fitness of the Credits. Company and its parent entity, subsidiaries, affiliates, and each of their
                  directors, officers, licensors, suppliers, partners, agents and representatives make no
                  representations or warranties about the suitability, reliability, availability, timeliness, security
                  of the Credits, and expressly disclaim any warranties or conditions (express or implied), including
                  implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.
                </li>
                <li>
                  The Credits are offered on a &apos;no exchange-no refund&apos; basis. Company shall not entertain any
                  requests for exchange or substitution in lieu of the Credits. Multiple Credits cannot be combined or
                  clubbed together under any circumstances. The Credits are is non-exchangeable, non-transferable, and
                  is not redeemable by any third person, or for other items or money.
                </li>
                <li>
                  Notwithstanding the above, Company, at its sole discretion, retains the right to substitute the
                  Credits for any reason at its sole discretion.
                </li>
                <li>
                  Company shall not be liable to the participants or any third party for any indirect, special, remote,
                  punitive, exemplary, incidental or consequential cost, expense, loss or damage whether arising from
                  breach of contract, tort, negligence, breach of statutory duty or otherwise, even if such cost,
                  expense, loss or damage was reasonably foreseeable or might reasonably have been contemplated by you
                  and/or Company. Company accepts no responsibility for difficulties experienced in using the Credits.
                  Company does not accept responsibility for (a) any technical or access issue, failure, malfunction or
                  difficulty that might hinder your ability to use the Credits, or (c) any event which may cause the
                  usage of Credits to be disrupted in any manner.
                </li>
                <li>
                  The participant undertakes to indemnify and keep Company, its parent entity, subsidiaries, affiliates
                  and each of their directors, officers, employees, agents, consultants, and representatives harmless
                  and indemnified against any loss, damage, claims, costs and expenses which may be brought against
                  Company, incurred or suffered by Company due to your breach of any of the terms and conditions herein
                  contained.
                </li>
                <li>
                  You acknowledge and agree that with respect to the Credits, you are not a ‘consumer’ under the
                  applicable laws, including the Consumer Protection Act, 2019 and rules framed thereunder, and shall
                  not be entitled to bring any claim or action against Company with respect to the Credits.
                </li>
                <li>
                  Company reserves the right to modify, add, substitute, or delete these terms and conditions at any
                  time and at its sole discretion without any prior notice to you.
                </li>
                <li>
                  Any disputes with respect to the Credits shall be governed by and construed in accordance with the
                  laws of India, without reference to principles of conflict of laws. The courts in Hyderabad shall have
                  exclusive jurisdiction to determine any disputes arising out of, under, or in relation to, the
                  Credits.
                </li>
                <li>
                  The rights and remedies available to Company as set out in these terms and conditions are without
                  prejudice and in addition to any other right or remedy that Company may have under applicable laws.
                </li>
                <li>
                  You shall be solely liable to comply with any third party rules, regulations, terms and conditions
                  that may be required to redeem and/or use Credits. Company hereby disclaims all obligations and
                  liability arising out of or in connection with the same and shall not be liable in any manner in this
                  regard.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <span className="para-bold">Disclaimer of Liability.</span> COMPANY RESERVES THE RIGHT TO MAKE CHANGES TO
              THE PLATFORM ANYTIME WITHOUT ANY PRIOR NOTICE. EDUCATIONAL MATERIALS AND/OR ANY MENTION OF THIRD PARTY
              PRODUCTS OR SERVICES IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND CONSTITUTES NEITHER AN ENDORSEMENT
              NOR A RECOMMENDATION BY COMPANY. ALL COMPANY AND THIRD-PARTY INFORMATION PROVIDED ON THE PLATFORM IS
              PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. VIEWS EXPRESSED BY THE USERS OF THE PLATFORM ARE THEIR
              OWN AND PERSONAL IN NATURE, COMPANY DOES NOT ENDORSE THE SAME. NO CLAIM AS TO THE ACCURACY AND CORRECTNESS
              OF THE INFORMATION ON THE PLATFORM IS MADE.
              <br />
              <br /> In case any inaccuracy or otherwise inappropriate, offensive or illegal content is sighted on the
              Platform, please report it to{" "}
              <a className="a-link" href="link">
                takedown@infinitylearn.com
              </a>
              . If in the sole discretion of the Company such content is found to be not in accordance with these Terms
              of Use, the Company reserves the right to suspend or remove any such content from the Platform without any
              prior notice.
              <br />
              <br /> COMPANY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, WITH REGARD TO EDUCATIONAL MATERIALS (OR
              ANY PORTION THEREOF), OR ANY INFORMATION (INCLUDING ANY SOFTWARE, PRODUCTS, OR SERVICES) PROVIDED ON THE
              PLATFORM, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.
              <br />
              <br />
              THE COMPANY MAKES NO GUARANTEES AS TO (A) THE AVAILABILITY OF ANY SPECIFIC CONTENT IN ANY OF THE SERVICES
              OFFERED TO YOU ON THE PLATFORM, OR (B) ANY MINIMUM AMOUNT OF CONTENT IN ANY OF THE SERVICES, WHETHER FREE
              OF COST OR PAID BY YOU. AT ANY TIME IN THE FUTURE, THE COMPANY RESERVES THE RIGHT TO OFFER OR CEASE TO
              OFFER ADDITIONAL FEATURES TO ANY SERVICES, OR TO OTHERWISE MODIFY OR TERMINATE THE SERVICES OR ANY
              COMPONENT THEREOF AT OUR SOLE DISCRETION WITHOUT ANY PRIOR NOTICE.
              <br />
              <br /> It may happen that the Platform is inaccessible or non-functional, either for planned maintenance
              or any other reason. You accept that you will not have any recourse against us in any of these types of
              cases where the Platform may not be available to you for a period of time. The Company and its parent
              entity, subsidiaries, affiliates, and each of their directors, officers, licensors, suppliers, partners,
              agents and representatives make no representations or warranties about the suitability, reliability,
              availability, timeliness, security, lack of errors, or accuracy of the Services or Content, and expressly
              disclaim any warranties or conditions (express or implied), including implied warranties of
              merchantability, fitness for a particular purpose, title, and non-infringement. The Company and its parent
              entity, subsidiaries, affiliates, and each of their directors, officers, licensors, suppliers, partners,
              agents and representatives make no warranty that you will obtain specific results from accessing or usage
              of the Services. Your accessing and usage of the Services (including any Content) is entirely at your own
              risk.
              <br />
              <br /> The Company may decide to cease making available certain features of the Services at any time and
              for any reason without any prior notice. Under no circumstances shall the Company or its parent entity,
              subsidiaries, affiliates, and each of their directors, officers, licensors, suppliers, partners, agents
              and representatives be held liable for any damages due to such interruptions or lack of availability of
              such features.
              <br />
              <br /> The Company is not responsible for any delay or failure of performance of any of the Services
              caused by events beyond the reasonable control of the Company, like an act of war, hostility, or sabotage,
              epidemic, pandemic, natural disaster, electrical, internet, or telecommunication outage, or government
              restrictions.
              <br />
              <br />
              <li>
                <span className="para-bold">Feedback and Submissions.</span> If you share or send any ideas,
                suggestions, changes or documents regarding Company’s existing business or Services (&quot;
                <span className="para-bold">Feedback</span>&quot;), you agree that (i) your Feedback does not contain
                any confidential, secretive or proprietary information, including that of third parties, (ii) Company is
                under no obligation of confidentiality with respect to such Feedback, and shall be free to use the
                Feedback on an unrestricted basis without any compensation and liability to you; (iii) Company may have
                already received similar Feedback from some other source or it may be under consideration or in
                development within the Company, and (iv) by providing the Feedback, you grant Company an irrevocable,
                binding, non-exclusive, royalty-free, perpetual, worldwide license to use, modify, develop, publish,
                distribute and sublicense the Feedback in any form or manner, and you irrevocably waive, against Company
                or its parent entity, subsidiaries, affiliates, and each of their directors, officers, licensors,
                suppliers, partners, agents and representatives any claims or assertions of any nature, with regard to
                such Feedback.
                <br />
                <br /> You may provide only specific Feedback on Company’s existing products or marketing strategies.
                Please do not include any ideas that that in violation of the Company’s policy or practices.
                <br />
                <br /> Notwithstanding the abovementioned clause, Company or any of its employees or representatives do
                not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions,
                new or improved products or technologies, product enhancements, processes, materials, marketing plans or
                new product names. Please do not submit any unsolicited ideas, original creative artwork, suggestions or
                other works (&quot;<span className="para-bold">Submissions</span>&quot;) in any form to Company or any
                of its employees. <br />
                <br />
                The purpose of this clause is to avoid potential misunderstandings or disputes when Company’s Services,
                products or marketing strategies might seem similar to ideas and Feedback submitted to Company. If you
                submit any Submissions to Company, regardless of what the communication of such Submissions states, the
                following terms shall apply to your Submissions: <br />
                <br />
                <span style={{ textDecoration: "underline" }}>Terms governing Submissions.</span> You agree that: (1)
                the Submissions and their Contents will automatically become the property of Company, without any
                compensation or liability to you; (2) Company may use or redistribute the Submissions and their Content
                for any purpose and in any manner it deems fit; (3) there is no obligation for Company review the
                Submission; and (4) there is no obligation to keep any of the Submissions confidential.
              </li>
              <br />
              <li>
                {" "}
                <span className="para-bold">Privacy Policy.</span> The Privacy Policy of the Platform explains how the
                Company may use your personal information. The Company shall, at all times, respect and ensure adherence
                to the Privacy Policy of the Platform. Additionally, various settings are provided to help you to be
                able to control the manner in which others may be able to view your personal information, and the manner
                in which you may be contacted by the Company or its representatives.
                <br />
                <br />
                When you share or upload or store information on the Platform, others may be able to view, copy or
                download such information.
              </li>
              <br />
              <li>
                <span className="para-bold">Intellectual Property Rights.</span> The brand name ‘Inmobius’, ‘Infinity
                Learn’ and brand names, logos, trademarks, service marks of the Company or any of its subsidiaries or
                affiliates (whether registered or not), and Content made available to you, whether on the Platform or in
                an offline medium, to offer Services to you are works of authorship, and are the exclusive property of
                the Company and its licensors, and are protected by applicable laws. You are not permitted to use such
                intellectual property and Content in any form, medium, or manner without the prior written consent of
                the Company.
              </li>
              <br />
              <li>
                <span className="para-bold">License.</span> You are granted a personal, limited, revocable,
                non-assignable, non-transferable and non-sublicensable license to access the Platform and Services in
                the relevant territory where you have registered on the Platform for the Purpose, provided that you are
                at all times in compliance with these Terms of Use. Save as otherwise expressly provided herein, no
                rights are granted to you other than a limited permission to access the Platform upon the terms
                expressly set forth in these Terms of Use. The structure, sequence, organization and source code of the
                Platform are proprietary to the Company, its parent entity, subsidiaries, affiliates, and constitute
                valuable trade secrets of the Company. <br />
                <br />
                You hereby grant Company, its parent entity, subsidiaries, affiliates, and each of their licensors,
                suppliers and subcontractors a non-exclusive, unrestricted, irrevocable, perpetual, royalty-free, fully
                paid-up, worldwide, assignable, transferrable, sublicensable, right and license in all formats or media,
                whether now known or hereafter devised or discovered, to use, reproduce, display, transmit, translate,
                rent, sell, modify, disclose, publish, create derivative works from and distribute the material and
                information provided by you to the Company, including personal information. You further agree to
                indemnify, defend and hold the Company harmless from any liability arising due to the use or
                distribution of any such material or information by the Company, its parent entity, subsidiaries or
                affiliates to the fullest extent permitted by applicable laws.
                <br />
                <br /> You hereby consent to and grant Company, its parent entity, subsidiaries, affiliates, and each of
                their licensors, suppliers and subcontractors a perpetual, irrevocable, exclusive, worldwide, royalty
                free, fully paid-up, unlimited, assignable, transferable and sublicensable right and license to use in
                any form, manner and medium whatsoever, at the sole and absolute discretion of Infinity Learn, Your
                Content, your name, photo, image, visual representation, comment, or statement, in any media for
                promotional, marketing, and advertising purposes or any other objective by Infinity Learn, as deemed
                appropriate by Infinity Learn at its sole discretion. You further agree to indemnify, defend and hold
                the Company harmless from any liability arising due to the use or distribution of Your Content by the
                Company, its parent entity, subsidiaries or affiliates to the fullest extent permitted by applicable
                laws. <br />
                <br />
                The “<span className="para-bold">use</span>” referred to herein means use, copy, display, distribute,
                modify, translate, reformat, incorporate into advertisements and other works, analyse, promote,
                commercialize, create derivative works, and in the case of third-party services, allow their users and
                others to do the same.
                <br />
                <br /> “<span className="para-bold">Your Content</span>” means and includes (but is not limited to)
                images, photos, audio, video, and all other forms of information or data in any form of media that you
                submit, upload, share or transmit to Infinity Learn, in any form or manner, such as images, photos,
                messages, videos, and any other materials.
              </li>
              <br />
              <li>
                <span className="para-bold">Marketing Materials.</span> You hereby consent to and authorize Company to
                create Marketing Materials by making use of any of the elements of your Recordings in combination with
                such Third Party Materials as determined by the Company at its sole discretion.
                <br />
                <br /> “<span className="para-bold">Recordings</span>” collectively means and includes your photographs,
                images, audio, visual, digital or other recordings (including but not limited to, photographs, digital
                images, digital video, digital audio, videotape, audiotape, or transcript).
                <br />
                <br />“<span className="para-bold">Marketing Materials</span>” means and includes the simulated videos,
                audio, and images created by the Company using artificial intelligence technology and machine learning
                techniques, including without limitation, third party software licenses.
                <br />
                <br /> “<span className="para-bold">Third Party Materials</span>” means and includes any voice, script,
                speech, content, image, persona, likeness, interview, personality attributes, and any materials created
                using digital means and/or licensed by Company from a third party, including without limitation,
                artificial intelligence, third party technology, and/or machine learning techniques, software, and
                licences.
                <br />
                <br /> You hereby consent to use of the Marketing Materials by the Company for the marketing,
                advertising and promoting you and products and services offered by Company, its parent entity,
                subsidiaries, and affiliates on various platforms, including without limitation, Platform, Company’s
                apps, and social media pages. <br />
                <br />
                You hereby grant to Company, its parent entity, subsidiaries, and affiliates an unrestricted,
                sublicensable, assignable, transferable, irrevocable, perpetual, worldwide, non-exclusive and
                royalty-free license to use your name, voice, image, persona, likeness, interview, testimonial,
                performance, presentation and any materials supplied by you, or created by Company using digital means,
                including without limitation, artificial intelligence technology and/or machine learning techniques of
                the Student (“<span className="para-bold">Attributes</span>”) for the Purpose. Recordings and Attributes
                are collectively hereinafter referred to as “<span className="para-bold">Content</span>”. This license
                includes without limitation the right to (i) reproduce, create derivative works of, and otherwise use
                the Content, in whole or in part, in any manner or in combination with Marketing Materials and Third
                Party Materials or any other material (the “<span className="para-bold">Works</span>”); and (ii)
                publicly display, publicly perform, distribute (directly or indirectly), transmit, or broadcast the
                Content and Works (including but not limited to use in videos, printed brochures, websites, emails, and
                blog posts). Company owns all intellectual property in the Works. Except for the rights and licenses
                granted herein, each party retains all rights it would have independent hereof. <br />
                <br />
                You acknowledge and agree that you are not entitled to receive any fee from Company. You hereby
                irrevocably waive all rights and release Company, its parent entity, subsidiaries, and affiliates from
                any claim and liability, whether now known or unknown, relating to the use and exploitation of the
                Content or Works.
              </li>
              <br />
              <li>
                <span className="para-bold">Free Trial Access or No-Fee Access.</span> Company may provide Services for
                evaluation, beta, trial, or free of cost for any reason at the sole discretion of Company (“
                <b>Trial Services</b>”). The Trail Services shall be for such duration as determined by the Company at
                its sole discretion. The Trial Services shall automatically terminate on the end date of the free trial
                access or no-fee access, or immediately at the sole discretion of Company without the requirement of any
                notice. YOU ACKNOWLEDGE AND AGREE THAT THE TRIAL SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE”
                BASIS AND WITHOUT ANY INDEMNIFICATION, SUPPORT, OR WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS OR
                IMPLIED. COMPANY SHALL HAVE NO LIABILITY TOWARS YOU OR ANY THIRD PARTY FOR ANY MATTERS ARISING OUT OF OR
                IN CONNECTION WITH TRIAL SERVICES.
              </li>
              <br />
              <li>
                <span className="para-bold">Indemnification.</span> To the extent permitted by applicable laws, you
                hereby indemnify and hold harmless Company, its parent entity, subsidiaries, affiliates, and each of
                their directors, officers, licensors, suppliers, partners, agents and representatives from any losses,
                damages, claims, fines, cost, penalty, suit or proceedings incurred, sustained or brought against the
                Company arising out of or in connection with (i) any information or content provided by you, including
                personal information, (ii) your unauthorized access or usage of the Services, or products or services
                included or advertised in the Services; (iii) your access to and usage of the Platform; (iv) violation
                of any rights of a third party, including without limitation, any infringement by you of the copyright
                or intellectual property rights of any third party; (v) breach of any of these Terms of Use or any terms
                and conditions or policy incorporated herein by reference; or (vi) violation of applicable laws.
              </li>
              <br />
              <li>
                <b>Use Restrictions.</b> You represent, warrant and covenant that your access to and usage of the
                Platform shall not be done in a manner so as to:
                <ol type="a">
                  <li>
                    Violate any applicable local, provincial, state, national or international law, statute, ordinance,
                    rule or regulation;
                  </li>
                  <li>Interfere with or disrupt computer networks connected to the Platform;</li>
                  <li>
                    Impersonate any other person or entity, or make any misrepresentation as to your association or
                    affiliation with any other person or entity;
                  </li>
                  <li>
                    Forge headers or in any manner manipulate identifiers in order to disguise the origin of any user
                    information;
                  </li>
                  <li>
                    Interfere with or disrupt the access or usage of the Platform by any other user, nor “stalk”,
                    threaten, or in any manner harass another user;
                  </li>
                  <li>
                    Make usage of the Platform in such a manner as to gain unauthorized entry or access to the computer
                    systems of others;
                  </li>
                  <li>
                    Reproduce, copy, modify, sell, store, distribute or otherwise exploit for any commercial purposes
                    the Platform, or any component thereof (including, but not limited to any materials or information
                    accessible through the Platform);
                  </li>
                  <li>
                    Make usage of content from the Platform for derivative works with a commercial motive without prior
                    written consent of the Company.
                  </li>
                  <li>
                    Use any device, software or routine to interfere or attempt to interfere with the proper working of
                    the Platform; or
                  </li>
                  <li> Impose an unreasonable or disproportionately large load on the Platform infrastructure.</li>
                  <li>
                    Spam the Platform/Company by indiscriminately and repeatedly posting content or forwarding mail that
                    may be considered spam.
                  </li>
                  <li>
                    Access data not intended for you or log into server or account that you are not authorized to
                    access;
                  </li>
                  <li>
                    Constitute an act of reverse engineering, decompiling, disassembling, deciphering or otherwise
                    attempting to derive the source code for the Platform or any related technology or any part thereof
                  </li>
                  <li>
                    Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the
                    Platform.
                  </li>
                  <li> Attempt to probe, scan or test the vulnerability of a system or network;</li>
                  <li>
                    Use automated means to crawl the Platform and to scrape content from the Platform. The Company uses
                    technological means to exclude robots etc. from crawling and scraping content from the Platform. You
                    undertake not to circumvent any of these methods;
                  </li>
                  <li> Access the Platform except through the interfaces expressly provided by Company;</li>
                  <li> Attempt or breach security or authentication measures without proper authorization;</li>
                  <li> Providing deep links into the Platform without prior permission of the Company. </li>
                  <li>
                    Extracting data from the Platform using any automated process such as spiders, crawlers etc. or
                    through any manual process for a purpose which has not been authorized in writing.
                  </li>
                  <li>
                    Upload, post, email, transmit or otherwise make available either directly or indirectly, any
                    unsolicited bulk e-mail or unsolicited commercial e-mail.
                  </li>
                  <li>
                    Constitute hosting, modifying, uploading, posting, transmitting, publishing, or distributing any
                    material or information:
                  </li>
                  <ol type="i">
                    <li> For which you do not have all necessary rights and licenses;</li>
                    <li>
                      Which infringes, violates, breaches or otherwise contravenes the rights of any third party,
                      including any copyright, trademark, patent, rights of privacy or publicity or any other
                      proprietary right;
                    </li>
                    <li>
                      Which contains a computer virus, or other code, files or programs intending in any manner to
                      disrupt or interfere with the functioning of the Platform, or that of other computer systems;
                    </li>
                    <li>
                      That is grossly harmful, harassing, invasive of another’s privacy, hateful, disparaging, relating
                      to money laundering or unlawful, or which may potentially be perceived as being harmful,
                      threatening, abusive, harassing, defamatory, libelous/blasphemous, vulgar, obscene, or racially,
                      ethnically, or otherwise unlawful in any manner whatsoever;
                    </li>
                    <li>
                      Which constitutes or encourages conduct that would constitute a criminal offence, give rise to
                      other liability, or otherwise violate applicable law;
                    </li>
                    <li>
                      That deceives or misleads the addressee about the origin of such messages or communicates any
                      information which is grossly offensive or menacing in nature;
                    </li>
                    <li> That belongs to another person and to which the user does not have any right to;</li>
                    <li> That harm minors in any way;</li>
                    <li>
                      That threatens the unity, integrity, defense, security or sovereignty of India, friendly relations
                      with foreign states, or public order or causes incitement to the commission of any cognizable
                      offence or prevents investigation of any offence or is insulting any other nation.
                    </li>
                  </ol>
                </ol>
                <br />
                <li>
                  <b>Limitation of Liability.</b> You expressly understand and agree that Company shall not be liable
                  for any indirect, remote, incidental, special, consequential, punitive or exemplary damages, including
                  but not limited to, damages for loss of profits, goodwill, usage, data or other intangible losses
                  (even if Company has been advised of the possibility of such damages). The Company provides no
                  guarantees of server uptime or uninterrupted functioning of the Platform at all times. In no event
                  shall the maximum aggregate liability of the Company for losses, damages and claims arising out of or
                  in connection with these Terms of Use exceed the amount of fees paid by you to Company.
                </li>
                <br />
                <li>
                  {" "}
                  <b>Termination.</b> Company may at any time, without any prior notice and at its sole discretion,
                  suspend, terminate or restrict your usage of or access to the Platform or Services (or any part
                  thereof) for any reason, including without limitation, that the Company at determines that you have
                  violated or acted inconsistently with these Terms of Use.
                </li>
                <br />
                <li>
                  {" "}
                  <b>Settlement of Disputes.</b> If any dispute arises between you and Company arising out of or in
                  connection with the access or usage of the Platform or Services, or the validity, interpretation,
                  implementation or alleged breach of any provision of these Terms of Use, the dispute shall be referred
                  to a sole arbitrator who shall be appointed by the Company. The decision of the arbitrator shall be
                  final and binding on both the parties to the dispute. The venue and seat of arbitration shall be
                  Hyderabad, India. The Arbitration & Conciliation Act, 1996 as amended, shall govern the arbitration
                  proceedings.
                </li>
                <br />
                <li>
                  {" "}
                  <b>Governing Laws and Jurisdiction.</b> These Terms shall be governed by the laws of India. The Courts
                  of Hyderabad shall have exclusive jurisdiction over any dispute arising under these Terms of Use.
                </li>
                <br />
                <li>
                  {" "}
                  <b>Severability.</b> If any provision of these Terms of Use are held to be invalid or unenforceable by
                  a court of competent jurisdiction, such provision will be changed and interpreted so as to best
                  accomplish the objectives of the original provision to the fullest extent allowed by law and the
                  remaining provisions of these Terms of Use shall remain in full force and effect
                </li>
                <br />
                <li>
                  {" "}
                  <b>Assignment, Subcontracting, and Transfer.</b> Company may assign, subcontract, or transfer any of
                  its rights or obligations under these Terms of Use to its parent entity, any of its subsidiaries,
                  affiliates, subcontractors or any third party at any time without your prior consent. You shall not be
                  entitled to assign, transfer, novate or delegate your rights under these Terms of Use or the limited
                  license that has been extended to you, and any assignment, transfer, novation or delegation of these
                  Terms of Use shall be null and void. The Company reserves its rights to claim damages and seek an
                  injunction against you
                </li>
                <br />
                <li>
                  {" "}
                  <b>Entire Agreement.</b> These Terms Of Use, as amended from time to time, constitute the complete
                  understanding and entire agreement between you and Company regarding the subject matter envisaged
                  herein, and supersedes all prior and contemporaneous agreements, discussions, negotiation and
                  correspondence between you and the Company, whether oral or written.
                </li>
                <br />
                <li>
                  {" "}
                  <b>Contact Us.</b> For any questions or concerns, you may contact us via email at
                  <a className="a-link" href="link">
                    support.inmobius@infinitylearn.com.
                  </a>
                  <br />
                  <br />
                  You may also contact our customer support team via email at
                  <a className="a-link" href="link">
                    grievances@infinitylearn.com
                  </a>{" "}
                  or by phone at{" "}
                  <a className="a-link" href="link">
                    +918792740498
                  </a>{" "}
                  and{" "}
                  <a className="a-link" href="link">
                    +918792740465
                  </a>
                  <br />
                  <br />
                  For any grievances that you may have, you may also contact our Grievance Redressal Officer at the
                  below mentioned details:
                  <br />
                  <br /> Mr. Manoj Singh <br />
                  Grievance Redressal Officer
                  <br /> RANKGURU TECHNOLOGY SOLUTIONS PRIVATE LIMITED <br />
                  8, Koramangala 1A Block, Koramangala 3 Block, Koramangala, Bengaluru, Karnataka 560034 <br />
                  Email ID:{" "}
                  <a className="a-link" href="link">
                    manoj.singh@infinitylearn.com
                  </a>
                  <br /> Phone Number: 87502 62054
                </li>
                <br />
                <li>
                  {" "}
                  If the Company conducts a contest in which the Company provides you an opportunity to win a prize, the
                  following Prize Terms shall apply in addition to the Terms Of Use.
                  <br />
                  <br />
                  <b>Prize Terms.</b>
                  <ul>
                    <li>
                      {" "}
                      The contest displayed on the Platform (&quot;Contest&quot;) to win the award or prize displayed on
                      the Platform (“Prize”) is conducted by Rankguru Technology Solutions Private Limited (“Company”).
                      You hereby consent that participation in the Contest shall be subject to your acceptance of these
                      terms and conditions.
                    </li>
                    <li>
                      {" "}
                      Participation in the Contest is purely voluntary in nature and does not guarantee winning of the
                      Prize. Mere participation should not be perceived as any commitment on the part of Company to
                      select a participant as the winner. Nothing herein amounts to a commitment by Company to conduct
                      further, similar or other contests in future. The winner(s) of the Contest shall be given the
                      Prize(s) subject to these terms and conditions.
                    </li>
                    <li>
                      {" "}
                      The Prize can be in the form of vouchers, promo codes, discounts, or any other tangible or
                      intangible item or object as may be determined by the Company at its sole discretion from time to
                      time. The winner(s) of the Contest shall be eligible to claim the Prize after fulfilment or
                      completion of the criteria mentioned by Company. Additionally, the winner(s) of the Contest shall
                      be required to comply with such requirements as determined by Company at its sole discretion from
                      time to time.
                    </li>
                    <li>
                      Company shall have the sole discretion in determining the number of Prizes and winners of the
                      Contest. A participant who has received a Prize once shall not be eligible for any other prize in
                      the same contest under any circumstances.
                    </li>
                    <li>
                      Company shall contact the winner(s) of the Contest within ten (10) days after determining the
                      winners of the Contest. If the winner(s) of the Contest fails to provide the required details
                      within seven (7) days from the date of receipt of the communication sent by Company, the Prize
                      shall stand forfeited without the requirement of any notice, or any liability to Company, and the
                      winner shall not be entitled to bring any claims in this regard.
                    </li>
                    <li>
                      The winner(s) of the Contest needs to submit such details as required by Company, which could
                      include name, postal address with pin code and govt. issued address proof, govt. issued birth
                      certificate with govt. issued age proof, Picture, video, contact number, photograph with govt.
                      issued ID proof, valid proof of admission to the relevant course offered by Company, current and
                      previous years’ mark sheets, any other document or information requested by Company, and a
                      no-objection certificate to the satisfaction of Company within seven (7) days from the date of
                      receipt of the communication sent by Company in order to claim the Prize. Company shall
                      issue/dispatch the Prize within thirty (30) days of successful verification of the details
                      provided by the winner of the Contest.
                    </li>
                    <li>
                      Company shall have the absolute and sole discretion to determine whether any winner of the Contest
                      should be disqualified by reason of failure to abide by these terms and conditions, terms and
                      conditions of Platform, bad faith, fraud or any other reason at the sole discretion of Company.
                    </li>
                    <li>
                      Incomplete information provided by a participant, failure to provide true and accurate information
                      as required by Company, or failure to submit proof of identification or any other information
                      required by Company will automatically disqualify the winner(s) of the Contest, and the Prize
                      shall stand forfeited without the requirement of any notice, or any liability to Company.
                    </li>
                    <li>
                      Issuance of the Prize to the winner by Company shall discharge Company of any and all obligations
                      towards the winner(s) of the Contest, and such winner(s) shall not be entitled to bring any claims
                      against Company in this regard.
                    </li>
                    <li>
                      If the winner(s) of the Contest is not determined for any reason, including without limitation,
                      any event beyond the reasonable control of Company, Company shall have no liability towards the
                      participants.
                    </li>
                    <li>
                      The winner(s) of the Contest hereby consents to use of his/her image, photograph, video, name and
                      likeness in publicity material by Company and in advertising, marketing or promotional material in
                      any media by any means throughout the world for any purpose connected with the products/services
                      of Company or any company within the Company’s group of companies without additional compensation
                      or prior notice.
                    </li>
                    <li>
                      Each Contest may be valid for a limited period only, subject to the sole discretion of Company.
                      Company reserves the right at any time and from time to time to modify, suspend or discontinue,
                      temporarily or permanently, the Contest and/or these terms and conditions without any prior
                      notice. The decision of Company is final and binding and no correspondence shall be entered into
                      in this regard.
                    </li>
                    <li>
                      The participant represents and warrants that he/she shall be solely liable to comply with the
                      applicable laws and regulations with respect to his/her participation in the Contest.
                    </li>
                    <li>
                      The winner(s) of the Contest shall be solely liable to comply with the applicable laws and
                      regulations with respect to the Prize, including without limitation, obligations to pay applicable
                      taxes and duties on the Prize.
                    </li>
                    <li>
                      Company disclaims all responsibility associated with the Prize, including without limitation, any
                      warranties associated with the Prize, any additional costs or expenses associated with the Prize,
                      its issuance, distribution, transportation or any other liability, cost, or expenses associated
                      with usage or redemption of the Prize. Company shall not be liable for any losses, damages,
                      claims, taxes, liabilities or inconvenience suffered by any participant as a result of these terms
                      and conditions, entering into this Contest or accepting any part of the Prize.
                    </li>
                    <li>
                      Company shall not be responsible for any deficiency in the Prize and does not make any
                      representation about the quality, make, performance, features, endurance, finishing, benefits, or
                      fitness of the Prize. Company and its parent entity, subsidiaries, affiliates, and each of their
                      directors, officers, licensors, suppliers, partners, agents and representatives make no
                      representations or warranties about the suitability, reliability, availability, timeliness,
                      security of the Prize, and expressly disclaim any warranties or conditions (express or implied),
                      including implied warranties of merchantability, fitness for a particular purpose, title, and
                      non-infringement.
                    </li>
                    <li>
                      The Prize is offered on a &apos;no exchange-no refund&apos; basis. Company shall not entertain any
                      requests for exchange or substitution in lieu of the Prize offered by Company. Multiple Prizes
                      cannot be combined or clubbed together under any circumstances. The Prize is non-exchangeable,
                      non-transferable, and is not redeemable by any person other than the participating winner of the
                      Contest, or for other items or prizes.
                    </li>
                    <li>
                      Notwithstanding the above, Company, at its sole discretion, retains the right to substitute the
                      Prize with another reward of similar value in the event the Prize is not available for any reason.
                    </li>
                    <li>
                      Company shall not be liable to the participants or any third party for any indirect, special,
                      remote, punitive, exemplary, incidental or consequential cost, expense, loss or damage whether
                      arising from breach of contract, tort, negligence, breach of statutory duty or otherwise, even if
                      such cost, expense, loss or damage was reasonably foreseeable or might reasonably have been
                      contemplated by the participant and/or Company. Company accepts no responsibility for difficulties
                      experienced in participating or fulfilling the requirements of the Contest. Company does not
                      accept responsibility for (a) lost, late or answers not submitted for any reason, (b) any
                      technical or access issue, failure, malfunction or difficulty that might hinder the ability of a
                      participant to participate in the Contest, or (c) any event which may cause the participation in,
                      or fulfilment of the requirements of, the Contest to be disrupted in any manner.
                    </li>{" "}
                    <li>
                      The participant undertakes to indemnify and keep Company, its parent entity, subsidiaries,
                      affiliates and each of their directors, officers, employees, agents, consultants, and
                      representatives harmless and indemnified against any loss, damage, claims, costs and expenses
                      which may be brought against Company, incurred or suffered by Company due to participant’s breach
                      of any of the terms and conditions herein contained.
                    </li>
                    <li>
                      The winner(s) of the Contest acknowledges and agrees that with respect to the Prize, he/she is not
                      a ‘consumer’ under the applicable laws, including the Consumer Protection Act, 2019 and rules
                      framed thereunder, and shall not be entitled to bring any claim or action against Company with
                      respect to the Prize.
                    </li>
                    <li>
                      Company reserves the right to modify, add, substitute, or delete these terms and conditions at any
                      time and at its sole discretion without any prior notice to you.
                    </li>
                    <li>
                      Any disputes with respect to the Contest shall be governed by and construed in accordance with the
                      laws of India, without reference to principles of conflict of laws. The courts in Hyderabad shall
                      have exclusive jurisdiction to determine any disputes arising out of, under, or in relation to,
                      the Contest.
                    </li>
                    <li>
                      The rights and remedies available to Company as set out in these terms and conditions are without
                      prejudice and in addition to any other right or remedy that Company may have under applicable
                      laws.
                    </li>{" "}
                    <li>
                      {" "}
                      The winner of the Contest shall be solely liable to comply with any third party rules,
                      regulations, terms and conditions that may be required to redeem and/or utilize the Award. Company
                      hereby disclaims all obligations and liability arising out of or in connection with the same and
                      shall not be liable in any manner in this regard.
                    </li>{" "}
                    <li>
                      {" "}
                      Wherever Company expressly undertakes shipping of the Prize to the winner of the Contest, the
                      following terms shall apply: The contest displayed on the Platform &quot;Contest&quot; to win the
                      award or prize displayed on the Platform (“Prize”) is conducted by Rankguru Technology Solutions
                      Private Limited (“Company”). You hereby consent that participation in the Contest shall be subject
                      to your acceptance of these terms and conditions.
                    </li>{" "}
                    <li>
                      Participation in the Contest is purely voluntary in nature and does not guarantee winning of the
                      Prize. Mere participation should not be perceived as any commitment on the part of Company to
                      select a participant as the winner. Nothing herein amounts to a commitment by Company to conduct
                      further, similar or other contests in future. The winner(s) of the Contest shall be given the
                      Prize(s) subject to these terms and conditions.
                    </li>{" "}
                    <li>
                      The Prize can be in the form of vouchers, promo codes, discounts, or any other tangible or
                      intangible item or object as may be determined by the Company at its sole discretion from time to
                      time. The winner(s) of the Contest shall be eligible to claim the Prize after fulfilment or
                      completion of the criteria mentioned by Company. Additionally, the winner(s) of the Contest shall
                      be required to comply with such requirements as determined by Company at its sole discretion from
                      time to time.
                    </li>
                    <li>
                      Company shall have the sole discretion in determining the number of Prizes and winners of the
                      Contest. A participant who has received a Prize once shall not be eligible for any other prize in
                      the same contest under any circumstances.
                    </li>
                    <li>
                      Company shall contact the winner(s) of the Contest within ten (10) days after determining the
                      winners of the Contest. If the winner(s) of the Contest fails to provide the required details
                      within seven (7) days from the date of receipt of the communication sent by Company, the Prize
                      shall stand forfeited without the requirement of any notice, or any liability to Company, and the
                      winner shall not be entitled to bring any claims in this regard.
                    </li>
                    <li>
                      The winner(s) of the Contest needs to submit such details as required by Company, which could
                      include name, postal address with pin code and govt. issued address proof, govt. issued birth
                      certificate with govt. issued age proof, Picture, video, contact number, photograph with govt.
                      issued ID proof, valid proof of admission to the relevant course offered by Company, current and
                      previous years’ mark sheets, any other document or information requested by Company, and a
                      no-objection certificate to the satisfaction of Company within seven (7) days from the date of
                      receipt of the communication sent by Company in order to claim the Prize. Company shall
                      issue/dispatch the Prize within thirty (30) days of successful verification of the details
                      provided by the winner of the Contest.
                    </li>{" "}
                    <li>
                      Company shall have the absolute and sole discretion to determine whether any winner of the Contest
                      should be disqualified by reason of failure to abide by these terms and conditions, terms and
                      conditions of Platform, bad faith, fraud or any other reason at the sole discretion of Company.
                    </li>
                    <li>
                      Incomplete information provided by a participant, failure to provide true and accurate information
                      as required by Company, or failure to submit proof of identification or any other information
                      required by Company will automatically disqualify the winner(s) of the Contest, and the Prize
                      shall stand forfeited without the requirement of any notice, or any liability to Company.
                    </li>
                    <li>
                      Issuance of the Prize to the winner by Company shall discharge Company of any and all obligations
                      towards the winner(s) of the Contest, and such winner(s) shall not be entitled to bring any claims
                      against Company in this regard.
                    </li>
                    <li>
                      If the winner(s) of the Contest is not determined for any reason, including without limitation,
                      any event beyond the reasonable control of Company, Company shall have no liability towards the
                      participants.
                    </li>
                    <li>
                      The winner(s) of the Contest hereby consents to use of his/her image, photograph, video, name and
                      likeness in publicity material by Company and in advertising, marketing or promotional material in
                      any media by any means throughout the world for any purpose connected with the products/services
                      of Company or any company within the Company’s group of companies without additional compensation
                      or prior notice.
                    </li>
                    <li>
                      Each Contest may be valid for a limited period only, subject to the sole discretion of Company.
                      Company reserves the right at any time and from time to time to modify, suspend or discontinue,
                      temporarily or permanently, the Contest and/or these terms and conditions without any prior
                      notice. The decision of Company is final and binding and no correspondence shall be entered into
                      in this regard.
                    </li>{" "}
                    <li>
                      The participant represents and warrants that he/she shall be solely liable to comply with the
                      applicable laws and regulations with respect to his/her participation in the Contest.
                    </li>{" "}
                    <li>
                      The winner(s) of the Contest shall be solely liable to comply with the applicable laws and
                      regulations with respect to the Prize, including without limitation, obligations to pay applicable
                      taxes and duties on the Prize.
                    </li>{" "}
                    <li>
                      Company disclaims all responsibility associated with the Prize, including without limitation, any
                      warranties associated with the Prize, any additional costs or expenses associated with the Prize,
                      its issuance, distribution, transportation or any other liability, cost, or expenses associated
                      with usage or redemption of the Prize. Company shall not be liable for any losses, damages,
                      claims, taxes, liabilities or inconvenience suffered by any participant as a result of these terms
                      and conditions, entering into this Contest or accepting any part of the Prize.
                    </li>{" "}
                    <li>
                      Company shall not be responsible for any deficiency in the Prize and does not make any
                      representation about the quality, make, performance, features, endurance, finishing, benefits, or
                      fitness of the Prize. Company and its parent entity, subsidiaries, affiliates, and each of their
                      directors, officers, licensors, suppliers, partners, agents and representatives make no
                      representations or warranties about the suitability, reliability, availability, timeliness,
                      security of the Prize, and expressly disclaim any warranties or conditions (express or implied),
                      including implied warranties of merchantability, fitness for a particular purpose, title, and
                      non-infringement.
                    </li>{" "}
                    <li>
                      The Prize is offered on a &apos;no exchange-no refund&apos; basis. Company shall not entertain any
                      requests for exchange or substitution in lieu of the Prize offered by Company. Multiple Prizes
                      cannot be combined or clubbed together under any circumstances. The Prize is non-exchangeable,
                      non-transferable, and is not redeemable by any person other than the participating winner of the
                      Contest, or for other items or prizes.
                    </li>{" "}
                    <li>
                      Notwithstanding the above, Company, at its sole discretion, retains the right to substitute the
                      Prize with another reward of similar value in the event the Prize is not available for any reason.
                    </li>{" "}
                    <li>
                      Company shall not be liable to the participants or any third party for any indirect, special,
                      remote, punitive, exemplary, incidental or consequential cost, expense, loss or damage whether
                      arising from breach of contract, tort, negligence, breach of statutory duty or otherwise, even if
                      such cost, expense, loss or damage was reasonably foreseeable or might reasonably have been
                      contemplated by the participant and/or Company. Company accepts no responsibility for difficulties
                      experienced in participating or fulfilling the requirements of the Contest. Company does not
                      accept responsibility for (a) lost, late or answers not submitted for any reason, (b) any
                      technical or access issue, failure, malfunction or difficulty that might hinder the ability of a
                      participant to participate in the Contest, or (c) any event which may cause the participation in,
                      or fulfilment of the requirements of, the Contest to be disrupted in any manner.
                    </li>{" "}
                    <li>
                      The participant undertakes to indemnify and keep Company, its parent entity, subsidiaries,
                      affiliates and each of their directors, officers, employees, agents, consultants, and
                      representatives harmless and indemnified against any loss, damage, claims, costs and expenses
                      which may be brought against Company, incurred or suffered by Company due to participant’s breach
                      of any of the terms and conditions herein contained.
                    </li>{" "}
                    <li>
                      The winner(s) of the Contest acknowledges and agrees that with respect to the Prize, he/she is not
                      a ‘consumer’ under the applicable laws, including the Consumer Protection Act, 2019 and rules
                      framed thereunder, and shall not be entitled to bring any claim or action against Company with
                      respect to the Prize.
                    </li>{" "}
                    <li>
                      Company reserves the right to modify, add, substitute, or delete these terms and conditions at any
                      time and at its sole discretion without any prior notice to you.
                    </li>{" "}
                    <li>
                      Any disputes with respect to the Contest shall be governed by and construed in accordance with the
                      laws of India, without reference to principles of conflict of laws. The courts in Hyderabad shall
                      have exclusive jurisdiction to determine any disputes arising out of, under, or in relation to,
                      the Contest.
                    </li>{" "}
                    <li>
                      The rights and remedies available to Company as set out in these terms and conditions are without
                      prejudice and in addition to any other right or remedy that Company may have under applicable
                      laws.
                    </li>{" "}
                    <li>
                      {" "}
                      The winner of the Contest shall be solely liable to comply with any third party rules,
                      regulations, terms and conditions that may be required to redeem and/or utilize the Award. Company
                      hereby disclaims all obligations and liability arising out of or in connection with the same and
                      shall not be liable in any manner in this regard.
                    </li>{" "}
                    <li>
                      {" "}
                      Wherever Company expressly undertakes shipping of the Prize to the winner of the Contest, the
                      following terms shall apply:
                      <ul>
                        <li>
                          Company shall ship the products within the territorial limits of India only. Company shall not
                          ship the product outside the territorial limits of India under any circumstances;
                        </li>

                        <li>
                          {" "}
                          Shipping of the Award shall be govern&gt;ed by and subject to the applicable laws, rules,
                          regulations of the relevant shipping service provider; and
                        </li>

                        <li>
                          {" "}
                          Company shall not be liable for any damage to the Award that may take place during the
                          shipping process.
                        </li>
                      </ul>
                    </li>
                    <br />
                  </ul>
                </li>
                <li>
                  {" "}
                  If you are accessing the Platform as an educational institute (hereinafter referred to as “
                  <b>Institute</b>”), the following terms shall apply to your usage of the Platform in addition to the
                  above mentioned terms:
                  <br />
                  <br /> <b>Definitions and Interpretation</b>
                  <br />
                  <br /> <b>DEFINITIONS</b>
                  <br />
                  <br /> “<b>Affiliate</b>” means, in respect of any specified Person, any other Person directly or
                  indirectly Controlling or Controlled by or under direct or indirect common Control with such specified
                  Person.
                  <br />
                  <br /> “<b>Controlling</b>”, “<b>Controlled by</b>” or “<b>Control</b>” means, in relation to a
                  Person: (a) holding or controlling, directly or indirectly, a majority of the voting rights
                  exercisable at board or shareholder meetings (or the equivalent) of that Person; or (b) having,
                  directly or indirectly, the right to appoint or remove directors (or the equivalent) of that Person;
                  or (c) having directly or indirectly the ability to direct or procure the direction of the management
                  policies and / or actions of that Person, whether through the ownership of shares, by contract or
                  otherwise; and the term “<b>Common Control</b>” shall be construed accordingly.
                  <br />
                  <br /> “<b>Governmental Authority</b>” means any nation or government or any province, state or any
                  other political sub-division thereof; any entity, authority or body exercising executive, legislative,
                  judicial, quasi-judicial, regulatory or administrative functions of or pertaining to government,
                  including any government authority, agency, department, board, commission or instrumentality of such
                  government, as applicable, or any political subdivision thereof or any other applicable jurisdiction;
                  any court, tribunal or arbitrator and any central bank and any securities exchange or body or
                  authority regulating such securities exchange.
                  <br />
                  <br /> “<b>Intellectual Property</b>” means in relation to Company and its Affiliates and related
                  parties, patents, utility models, trademarks, service marks, logos, trade and business names,
                  registered designs, design rights, copyright and neighbouring rights, database rights, domain names,
                  semi-conductor topography rights and rights in Confidential Information, inventions, software, Company
                  Platform, websites and website content including the website or platform of its affiliates, trade
                  secrets, confidential information of all kinds and other similar proprietary rights which may subsist
                  in any part of the world and whether registered or not, including, where such rights are obtained or
                  enhanced by registration, any registration of such rights and rights to apply for such registrations
                  anywhere in the world, legally and beneficially owned by Company and / or its Affiliates, and includes
                  the any materials provided by Company, to which Company and / or its Affiliates have absolute and
                  undisputed legal and beneficial ownership. <br />
                  <br />“<b>Law</b>” means any national, state, provincial, local, municipal, foreign, international,
                  multinational or other statute, law, regulation, administrative order, constitution, circular,
                  ordinance, rule, bye-law, government approval, notification, principle of common law, treaty,
                  directive, guideline, requirement or other governmental restriction, judgment, order, decree, writ or
                  any similar form of decision of or determination by or any interpretation, policy or administration
                  having the force of law by any Governmental Authority, and includes the rules, regulations and
                  licences issued by any relevant regulator and the relevant board of education, whether in effect as of
                  the date of this Agreement or thereafter, as may be amended from time to time.
                  <br />
                  <br /> “<b>Person</b>” means any individual, sole proprietorship, association (including
                  unincorporated association), unincorporated organization or joint venture, body corporate, corporation
                  (including any non-profit corporation), company (including any limited liability company or joint
                  stock company), general partnership, limited partnership, limited liability partnership, estate,
                  trust, firm, Governmental Authority or any other enterprise or other entity (whether or not having
                  separate legal personality).
                  <br />
                  <br /> “<b>Personal Information</b>” shall have the meaning ascribed to it in the Information
                  Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information)
                  Rules, 2011.
                  <br />
                  <br /> “<b>Sensitive Personal Data or Information</b>” shall have the meaning ascribed to it in the
                  Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
                  Information) Rules, 2011.
                  <br />
                  <br /> “<b>Personnel</b>” means the employees, agents, suppliers, subcontractors, consultants, and
                  other representatives engaged by Company or its Affiliates, who shall, inter alia, be involved in
                  providing the Services, whether directly or indirectly.
                  <br />
                  <br /> “<b>Students</b>” means, with respect to a Institute, the Students enrolled at the Institute.
                  <br />
                  <br /> “<b>Staff</b>” means the teaching staff engaged by the Institute at the Institute.
                  <br />
                  <br />“<b>Warranties</b>” means the representations and warranties provided by the Institute to
                  Company under this Agreement.
                  <br />
                  <br /> <b>OBLIGATIONS OF THE INSTITUTE.</b> The Institute undertakes: <br />
                  <br />
                  <ol type="I">
                    <li>
                      {" "}
                      that it shall obtain the requisite rights, title, and licenses without any encumbrances to enter
                      into this Agreement, appoint Company as the service provider to provide Services at the Institutes
                      in various locations and provide access to the assets, premises, and resources of Institutes as
                      agreed between the Parties under the Agreement and from time to time;
                    </li>
                    <br />
                    <li> comply with the applicable Laws in operating Institutes;</li>
                    <br />
                    <li>
                      {" "}
                      to be solely responsible to comply with the directions issued by the relevant educational
                      authorities, including: (a) obtaining and maintaining relevant consents and governmental
                      approvals; and (b) conducting programs and/or activities in accordance with applicable Law;
                    </li>
                    <br />
                    <li>
                      {" "}
                      that all relevant committees and governing bodies as required under applicable Law for the
                      effective operation and management of Institute are validly constituted, regular meetings are
                      conducted, and minutes of such meetings are maintained;
                    </li>
                    <br />
                    <li>
                      {" "}
                      to promptly inform Company of any change in applicable Law, rules, regulations, or any other
                      requirements (“<b>Statutory Requirements</b>”) that may affect the Services in any manner, whether
                      directly or indirectly;
                    </li>
                    <br />
                    <li>
                      {" "}
                      to promptly inform Company of any additional Statutory Requirements introduced by the Governmental
                      Authority;
                    </li>
                    <br />
                    <li>
                      to maintain accurate records and books of accounts in respect of Institutes as per applicable
                      Laws;
                    </li>
                    <br />
                    <li>
                      {" "}
                      that it shall be solely responsible (at its own cost) for operating and maintaining the Institutes
                      in order to enable Company to provide the Services;
                    </li>
                    <br />
                    <li>
                      {" "}
                      to inform Company of all health and safety rules and regulations and any other reasonable security
                      requirements that are applicable to any of the locations in which the Institute is located;
                    </li>
                    <br />
                    <li>
                      to provide any co-operation or assistance as may be requested by Company for carrying out its
                      obligations under this Agreement;
                    </li>
                    <br />
                    <li>
                      {" "}
                      to obtain requisite consents from the relevant data subject prior to disclosing or sharing any
                      Personal Information, or Sensitive Personal Data or Information for processing by Company;
                    </li>
                    <br />
                    <li>
                      {" "}
                      to use the Company Platform, Company Materials, Personal Information, Sensitive Personal Data or
                      Information accessed, collected, or processed pursuant to this Agreement solely for the purpose of
                      availing Services in accordance with the terms of the Agreement;
                    </li>
                    <br />
                    <li>
                      to use the Company Platform, or any websites and platform offered by Company and cause Students to
                      use such website and platforms in accordance with the online terms and conditions available on the
                      Company Platform or such websites and platforms (“Terms of Use”). Institute hereby acknowledges
                      and agrees that (a) it shall comply with the Terms of Use, (b) it shall be solely liable to cause
                      the Students to comply with the Terms of Use, and (c) it shall be solely liable for any violation
                      of the Terms of Use by itself or any Student; and
                    </li>
                    <br />
                    <li>
                      that it shall provide to Company at its sole expense such facilities, resources and infrastructure
                      as required by Company to provide Services.
                    </li>
                    <br />
                  </ol>
                </li>
              </li>
            </li>

            <p className="tc-para">
              <b>BRANDING AND ATTRIBUTION</b> Institute acknowledges and agrees that Company is an affiliated entity of
              Sri Chaitanya Educational Institutions (“<b>Sri Chaitanya</b>”). Institute further acknowledges and agrees
              that it shall not use (a) any of the names, logos, brand names, trademarks, service marks, or any other
              intellectual property of Sri Chaitanya in any medium, form, or manner and under any circumstances without
              the prior written approval of an authorised signatory Sri Chaitanya for each instance of use, and (b) take
              any credit for the rank or academic results of, or, publish the rank or academic results of, the students
              of Sri Chaitanya in any medium, form, or manner and under any circumstances without the prior written
              approval of Sri Chaitanya in each instance. Institute shall not take any credit for the rank or academic
              results of the Students of the Institute who have availed arising out of or in connection with the
              Services in any medium, form, or manner and under any circumstances without the prior written approval of
              Company in each instance. Pursuant to the foregoing, the Institute may carry out branding, advertisement,
              and promotion activities only in such medium, form, and manner and solely on such medium or platforms as
              expressly approved by Company in writing for each such instance of use. In addition, the Institute shall
              not publish the rank or academic results of Students in any medium, form, or manner and under any
              circumstances without the prior written approval of Company in each instance.
            </p>
            <p className="tc-para">
              In the event of a breach of this clause, Institute shall, immediately upon demand from Company or Sri
              Chaitanya, be liable to pay liquidated damages equivalent to <b>INR 10,00,000/-</b> (Indian Rupees Ten
              Lacs only) or such other amount as agreed between the Parties in writing for each instance of breach,
              whichever amount is higher. The rights and remedies available to Company and Sri Chaitanya under this
              clause and/or Agreement are without prejudice and in addition to any other right or remedy available to
              Company and Sri Chaitanya under Law, contract, or equity.
            </p>
            <p className="tc-para">
              The Institute covenants, acknowledges and agrees that it shall be solely liable to obtain requisite
              consents from the Students prior to using their names, images, results or any other personally
              identifiable information of the Students, and hereby indemnifies Company to the fullest extent permitted
              by applicable laws against any losses, damages, costs, fines, penalties, expenses (including legal
              expenses) imposed on Company or any claims, actions, suit or proceedings brought against Company in this
              regard.
            </p>
            <p className="tc-para">
              Notwithstanding the above, Company shall have the right to take credit for academic results of the
              Students of the Institute arising out of or in connection with the Services in any form or manner, whether
              directly or indirectly. Pursuant to the foregoing, Company shall have the right to carry out branding,
              advertisement and promotion activities in such form and manner and on such medium or platforms at its sole
              discretion. Company shall, at no cost or consideration payable to the Institute or any third party, be
              entitled to place posters / banners in Labs / reception area / entrances and any mutually agreed places in
              the Institutes as part of the program. In addition, Company shall, at no cost or consideration payable to
              the Institute or any third party, have the right to publish the academic results of Students and use the
              same for its commercial gain, including without limitation, admission support activities and services.
            </p>
            <p className="tc-para">
              Institute hereby grants Company a worldwide, royalty-free, irrevocable, assignable, perpetual, and
              transferable licence to display Institute’s brand name and logo as a partner school on the websites,
              mobile apps, and social media pages of Company (“<b>Licence</b>”). Institute hereby represents and
              warrants that it has the requisite consents and authority to grant the Licence to Company.
            </p>
            <br />
            <br />
            <br />
            <br />
            <h1 className="tc-heading">CONFLICT OF INTEREST, NON-COMPETITION AND NON-SOLICITATION</h1>
            <p className="tc-para">
              <b>Conflict Of Interest.</b> The Institute shall not engage, either directly or indirectly, in any
              activity (a “Conflict of Interest”) which might adversely affect Company or any of its Affiliates.
              Institute shall avoid circumstances or potential circumstances that may involve an actual or potential
              Conflict of Interest, directly or indirectly. The Institute shall immediately and fully disclose such
              relevant circumstances to Company in order to determine whether an actual or potential Conflict of
              Interest exists. If an actual or potential Conflict of Interest is determined by Company, Company may take
              necessary steps to remove the Conflict of Interest at its sole discretion. In the event the Institute
              fails to disclose the circumstances or potential circumstances that may involve an actual or potential
              Conflict of Interest as set forth above, Company reserves the right to suspend the Services for such time
              as it deems fit, or terminate the Agreement forthwith upon written notice, at its sole discretion. Upon
              such termination, Institute shall pay the entire portion of outstanding Services within seven (7) days of
              the effective date of termination.
            </p>
            <p className="tc-para">
              <b>Non-Competition.</b> Institute agrees that it shall not, whether directly or indirectly, or through its
              beneficiaries, trustees, members, settlors, promoters, shareholders, directors, nominees, Affiliates,
              employees, consultants, agents, representatives or related parties (collectively, the “Restricted
              Persons”), whether as shareholder, service provider, lender, director, consultant, proprietor, joint
              venture partner, licensor, independent contractor, principal, trustee, member, partner, employee or agent
              of any Person or otherwise in any capacity (i) be engaged, concerned or interested in any business,
              activity, or other endeavour (whether directly or indirectly) which is engaged in a business of a similar
              nature as or competes (or is likely to compete) with the business of Company or provides services similar
              to or competes (or is likely to compete) with any business in which Company is now engaged or in which
              Company becomes engaged during the Term, as may be determined by Company at its sole discretion; or (ii)
              set up, solicit business on behalf of, render any services to, engage in, guarantee any obligations of,
              extend credit to, or have any ownership interests or other affiliation (or attempt to undertake any such
              actions) in, any business, activity, or other endeavour (whether directly or indirectly) which is engaged
              in a business of a similar nature as or competes (or is likely to compete) with the business of Company or
              provides services similar to or competes (or is likely to compete) with any business in which Company is
              now engaged or in which Company becomes engaged during the Term, as may be determined by Company at its
              sole discretion.
            </p>
            <p className="tc-para">
              <b>Non-Solicitation.</b> Institute hereby agrees not to, directly or indirectly or in any manner
              whatsoever (including through its Affiliates or related parties) solicit, canvass, entice away, hire or
              procure or attempt in any manner to solicit, canvass, entice away, hire or procure from Company (or any of
              its Affiliates or related parties) any Person who is in the employment or service of Company (or any of
              its Affiliates or related parties) (whether as an employee, consultant, vendor, subcontractor or
              otherwise), including any Personnel; or employ as an employee or retain as a consultant, vendor,
              subcontractor or otherwise any Person (including Personnel, individual, firm, corporation or other form of
              entity) who is an employee of or consultant to Company or its Affiliates or related parties, or persuade
              or attempt to persuade any employee consultant, vendor, subcontractor of, or consultant to, Company or its
              Affiliates or related parties, to leave such services, employment or engagement or to become employed as
              an employee or retained as a consultant, vendor, subcontractor by the Institute or any other Person.
            </p>
            <p className="tc-para">
              The Institute acknowledges and agrees that any breach of or non-compliance of these above mentioned
              sub-clauses (Conflict Of Interest, Non-Competition, Non-Solicitation) shall be considered a material
              breach of this Agreement. The Parties acknowledge that the type and periods of restriction imposed in the
              provisions of these sub-clauses are fair and reasonable and are reasonably required in order to protect
              and maintain the legitimate business interests and the goodwill associated with the Services provided by
              Company, and the provision of Services is sufficient consideration for the restrictions contained under
              these sub-clauses. The Institute expressly waives any right to assert inadequacy of consideration as a
              defense to enforcement of the covenants set forth in these sub-clauses. The Parties further agree that any
              failure to comply with any of these sub-clauses will adversely affect the business and goodwill of
              Company, and acknowledge that monetary damages alone would not be an adequate compensation for the breach
              of any of these sub-clauses and further acknowledge and agree that in the event of any breach of the said
              obligations by the Institute, Company shall be entitled to injunctive relief and to such other relief as
              available under applicable law and as is proper under the circumstances.
            </p>
            <p className="tc-para">
              The Institute shall ensure that each of the Staff members are bound by terms of non-disclosure,
              confidentiality, use of Intellectual Property and non-compete obligations no less restrictive than those
              set forth herein. The Institute shall be responsible and liable for any breach of the terms of this
              Agreement by any the Staff, or the Institute’s managers, directors, members, trustees, employees, agents,
              representatives, or any other Person engaged by or otherwise associated with the Institute.
            </p>
            <p className="tc-para">
              <b>INTELLECTUAL PROPERTY.</b> Wherever applicable, Company hereby grants to the Institute a limited,
              revocable, royalty free, non-assignable, non-transferable, and non-sublicensable license solely to access
              and use the Company Platform, any websites and platform offered by Company and any materials related
              thereto, including without limitation any hardware equipment provided to the Institute (collectively,
              “Company Materials”) for delivering services solely within the premises of the Institute and for the sole
              purpose of distribution to Students of the Institute for the exclusive personal educational use of such
              Students enrolled in the Institute as set forth in these Terms of Use, provided that Institute is in
              compliance with the terms of the Agreement. The Parties agree that all rights, title, and interest in and
              to the Company Platform and Company Materials vest solely in Company at all times, including all
              Intellectual Property in and to Company Platform and Company Materials. Institute irrevocably acknowledges
              and agrees that the Company Platform and Company Materials (including the materials related thereto) are
              owned exclusively by Company and shall remain the property of Company at all times.
            </p>
            <p className="tc-para">
              The Institute shall acquire no right, title, or interest in and to the Company Platform, Company Materials
              or any Intellectual Property belonging to Company as a result of the transaction and activities undertaken
              or contemplated hereunder. Save for the limited license granted hereinabove, (a) nothing in this Agreement
              shall be construed in any manner to grant any right to the Institute (by way of license, assignment or
              otherwise) to use in any manner the Intellectual Property of Company or any materials related thereto; and
              (b) the Institute shall not use the name, trademark, logo or any Intellectual Property of Company without
              the prior written approval of Company in each instance.
            </p>
            <p className="tc-para">
              Institute acknowledges and agrees that Company is an affiliated entity of Sri Chaitanya Educational
              Institutions (“Sri Chaitanya”). Institute further acknowledges and agrees that it shall not use (a) any of
              the names, logos, brand names, trademarks, service marks, or any other intellectual property of Sri
              Chaitanya in any medium, form, or manner and under any circumstances without the prior written approval of
              an authorised signatory Sri Chaitanya for each instance of use, and (b) take any credit for the rank or
              academic results of, or, publish the rank or academic results of, the students of Sri Chaitanya in any
              medium, form, or manner and under any circumstances without the prior written approval of Sri Chaitanya in
              each instance.
            </p>
            <p className="tc-para">
              In the event of any breach of this clause, Institute shall, immediately upon demand from Company or Sri
              Chaitanya, be liable to pay liquidated damages equivalent to INR 10,00,000/- (Indian Rupees Ten Lacs only)
              or such other amount as agreed between the Parties in writing for each instance of breach, whichever
              amount is higher. Institute agrees that the liquidated damages constitute a reasonable pre-estimate of the
              losses suffered by Company and/or Sri Chaitanya on account of such breach. The rights and remedies
              available to Company and Sri Chaitanya under this clause and/or Agreement are without prejudice and in
              addition to any other right or remedy available to Company and Sri Chaitanya under Law, contract, or
              equity.
            </p>
            <p className="tc-para">
              Notwithstanding anything to the contrary in the Agreement, Company shall retain all rights, title and
              interest in the patents, copyrights, trademarks, proprietary marks or any licensed software, service
              marks, trademarks, trade secrets and any other form of Intellectual Property in any part of the world,
              whether registered or not.
            </p>
            <p className="tc-para">
              Institute agrees that any ideas, concepts, discoveries, techniques, patents, copyrights, trademarks,
              Intellectual Property, or computer programs relating to the business or operations of the Company or its
              Affiliates which are conceived, created, developed, generated, or discovered by Institute, solely or
              jointly with others, including without limitation, any materials related thereto, shall automatically upon
              their creation become the exclusive property of Company. Institute agrees to promptly notify and fully
              disclose the existence of such works to the Company. Notwithstanding the foregoing, Institute hereby
              assigns such item and any and all Intellectual Property Rights and intangible proprietary rights relating
              thereto to Company in their entirety and agrees to execute any and all documents necessary or desired by
              Company to reflect Company’s ownership thereof.
            </p>
            <p className="tc-para">
              The Institute shall not make any announcements or publications regarding the arrangement envisaged between
              the Parties pursuant to this Agreement in any form or manner without the prior written approval of
              Company.
            </p>
            <p className="tc-para">
              Notwithstanding anything to the contrary on the Agreement, the Institute shall not use the name, logo,
              branding, or any Intellectual Property of Company or any of its Affiliates in any form or manner without
              the prior written approval of Company in each instance.
            </p>
            <p className="tc-para">
              The Institute shall follow the below mentioned process each time it intends to use any Intellectual
              Property of Company or any of its Affiliates:
              <ol type="a">
                <li>
                  aThe Institute shall send a request to Company in writing specifying in detail the purpose and nature
                  of Intellectual Property that it intends to use along with the duration for such use, and the form,
                  manner and medium in which it intends to use such Intellectual Property (each, a “Brand Use Request”);
                </li>
                <br />
                <li>
                  Upon receipt of a Brand Use Request, Company shall review the same and either approve or reject the
                  Brand Use Request at its sole discretion. For the avoidance of doubt, it is hereby clarified that if
                  Company does not expressly approve or reject the Brand Use Request expressly in writing, it shall be
                  deemed as rejected by Company. The Institute shall not use any Intellectual Property of Company or any
                  of its Affiliates in the absence of such express approval; and
                </li>
                <br />
                <li>
                  If Company expressly approves the Brand Use Request in writing, the Institute shall use only such
                  Intellectual Property and only in such form, manner and medium as approved in the Brand Use Request,
                  and only for such duration as expressly set forth therein.
                </li>
                <br />{" "}
              </ol>
            </p>
            <p className="tc-para">
              Upon completion of the duration of the Brand Use Request approved by Company in writing, all rights
              granted to the Institute under the Brand Use Request shall automatically terminate without the requirement
              of any notice, and Institute shall forthwith cease using the Intellectual Property in any form, manner or
              medium.
            </p>
            <p className="tc-para">
              Any violation of this clause will constitute a material breach of the Agreement and Company shall be
              entitled to liquidated damages as set forth in the Agreement, without prejudice and in addition to any
              other rights and remedies available to Company under this Agreement, under Law or in equity. Company shall
              be entitled to the remedies of injunction, specific performance, or other equitable relief for any
              threatened or actual breach of Clause 8 of this Agreement.
            </p>
            <p className="tc-para">
              <b>PERSONAL INFORMATION.</b> Institute hereby consents to collection, processing, transmission, storage,
              and archival of its information by the Company.
            </p>
            <p className="tc-para">
              <b>THIRD PARTY CONSENT.</b> Institute hereby irrevocably acknowledges and agrees that it shall obtain the
              required consents from any third party, including without limitation, Students, prior to disclosing or
              sharing any Personal Information, or Sensitive Personal Data or Information for collection, processing,
              transmission, storage, and archival by Company.
            </p>
            <p className="tc-para">
              <b>EXCLUSIVITY.</b> The Institute agrees that, during the Term, neither the Institute nor any Affiliate,
              director, officer, employee, shareholder, agent, representative or related party of the Institute will,
              directly or indirectly, enter into any arrangements in relation to, or solicit, initiate, entertain or
              encourage any proposals or offers from any third party relating to, the provision of any services similar
              to the Services or participate in any discussions regarding, or furnish to any person any information with
              respect to any such transaction. Any breach of or non-compliance with this Clause 9 shall be considered a
              material breach of this Agreement.
            </p>
            <p className="tc-para">
              <b>SURVIVAL.</b> Notwithstanding anything to the contrary in this Agreement, any provision which by its
              nature is intended to survive termination, including Clauses shall survive termination of this Agreement.
            </p>
            <p className="tc-para">
              <b>MATERIAL BREACH.</b> In the event of a material breach of this Agreement, in addition to such
              consequences as are specified in the Agreement and such other rights and remedies that may be available to
              Company under contract, Law, or equity, the Institute shall, immediately upon Company becoming aware of
              such breach, be liable to pay to Company liquidated damages equivalent to losses suffered by Company at
              actuals, without any limitation. The Parties agree that such liquidated damages constitute a reasonable
              pre-estimate of the losses suffered by Company on account of such breach and are not in the nature of a
              penalty. Notwithstanding anything to the contrary contained herein, Company shall be entitled to offset
              and / or deduct an amount equivalent to all or part of the liquidated damages from the Advance Payment.
            </p>
            <p className="tc-para">
              <b>REPRESENTATIONS AND WARRANTIES.</b> Institute hereby represents and warrants to Company that (a) there
              are no judicial or administrative actions, proceedings, litigations or investigations (civil and/or
              criminal) pending or threatened against such Party, under Prevention of Corruption Act, Prevention of
              Money Laundering Act or any other anti-corruption legislation; (b) it has all rights, licenses, permits
              and is compliant with all requisite standards (including, but not limited to, intellectual property
              rights, and educational standards) as required under the industry practice and/or applicable laws.
              Further, it shall, throughout the duration of this Agreement, obtain and maintain necessary physical and
              technology infrastructure, skill, knowledge and resources to perform the obligations under this Agreement;
              (c) when executed and delivered by such Party either digitally or physically, this Agreement shall
              constitute a legal, valid and binding obligation of such Party and enforceable against such Party; (d) its
              undersigned representative has been expressly authorized to execute this Agreement for and on behalf of
              such Party; (e) it shall use the Company Platform, Company Materials, Personal Information, Sensitive
              Personal Data or Information accessed, collected, or processed pursuant to this Agreement solely for the
              purpose of availing Services in accordance with the terms of this Agreement; (f) it shall not violate
              applicable laws or third party rights, including without limitation, intellectual property rights, in the
              exercise of its rights or performance of its obligations under the Agreement; (g) it shall obtain
              requisite consents from the relevant data subject prior to disclosing or sharing any Personal Information,
              or Sensitive Personal Data or Information for processing by Company; (h) entering into and performance of
              the obligations contemplated under this Agreement does not and shall not conflict with any applicable law
              or regulation; (i) it shall not engage in any act or omission that may result in violation of applicable
              laws by Company; (j) it shall remain in compliance with the applicable laws, regulations, notifications,
              directions and guidelines at all times, including without limitation, intellectual property laws, and The
              Information Technology Act, 2000 and the rules framed thereunder; (k) it shall comply with all applicable
              employment laws and regulations at all times and shall be solely liable to make all statutory payments and
              provide the requisite benefits to its employees, contractors, and representatives in accordance with the
              applicable laws and regulations, including without limitation, salary, wages, social security and
              retirement benefits, insurance, leaves, holidays etc.; (l) it shall not employ children, forced, bonded or
              compulsory labor and/or slavery or trafficking of people in its business or operations, and all employment
              with Service Provider shall be voluntary and employees shall be free to leave their employment in
              compliance with applicable laws; and (m) neither Institute nor nor any of its employees or representatives
              shall (i) make any statement, or (ii) publish, upload, or post any content or information on any platform
              or medium, including without limitation, media, electronic media, or social media, with respect to Company
              without the prior written approval of Company in each instance.
            </p>
            <p className="tc-para">
              <b>INDEMNIFICATION.</b> Institute hereby indemnifies, defends and holds harmless Company, its Affiliates,
              and each of their officers, directors, employees, agents, representatives, successors and assigns, to the
              fullest extent permitted by applicable laws, from and against any and all losses, damages, claims, costs
              (including legal expenses), fines, suit or any action incurred or sustained by Company arising out of or
              in connection with (i) breach of any of the representations and warranties provided by the Institute; (ii)
              violation of intellectual property rights of Company or any third party; (iii) violation of applicable
              laws, including without limitation, applicable data protection laws; (iv) an act of fraud; (v)
              non-performance or breach of any of its obligations under the Agreement; or (v) breach of any of the terms
              of this Agreement.
            </p>
            <p className="tc-para">
              Any compensation or indemnity as referred to hereinabove shall be such as to place Company in the same
              position as it would have been in, had there not been any such inaccuracy or breach that gave rise to the
              indemnity claim.
            </p>
            <p className="tc-para">
              In the event of any third party claim brought against Institute alleging that the Services, Company
              Platform, or Company Materials as delivered to Institute, and excluding any third party software or open
              source software therein, violates applicable laws or infringes the intellectual property rights of a third
              party (each, a “Third Party Claim”), Company shall do any of the following: (i) obtain the right for
              Institute to continue using the Services; (ii) provide a non-infringing functionally equivalent
              replacement; (iii) modify the Services so that they no longer infringe. if Company does not believe the
              foregoing options are commercially reasonable, then Company may suspend or terminate Institute’s use of
              the impacted Services and refund the unused portion of any prepaid fees that were paid for the Services
              for the remainder of the then-current Term, Renewal Term, or Subsequent Renewal Term. This Clause provides
              Company’s sole and entire obligation and liability, and Institute’s and its Affiliates sole, exclusive and
              entire right and remedy for any infringement claim or action relating to the Services, Company Platform,
              or Company Materials, or otherwise arising out of or in connection with this Agreement.
            </p>

            <h1 className="tc-heading"></h1>
            <p className="tc-para">
              <b>DISCLAIMER OF LIABILITY, SOLE AND EXCLUSIVE REMEDY. </b>
              COMPANY SHALL HAVE NO LIABILITY OR OBLIGATION TO INSTITUTE OR ANY THIRD PARTY WITH RESPECT TO ANY THIRD
              PARTY CLAIM ARISING OUT OF, IN CONNECTION WITH, OR RELATED TO ANY (A) MODIFICATION OF THE SERVICES,
              COMPANY PLATFORM, OR COMPANY MATERIALS, OR ANY PORTION THEREOF OTHER THAN BY COMPANY, (B) COMBINATION OF
              THE SERVICES, COMPANY PLATFORM, OR COMPANY MATERIALS WITH ANY MATERIALS NOT PROVIDED BY COMPANY, (C)
              COMPLIANCE WITH INSTITUTE’S SPECIFICATIONS OR REQUIREMENTS, OR ANY MODIFICATION OR CUSTOMIZATION OF THE
              SERVICES, COMPANY PLATFORM, OR COMPANY MATERIALS MADE FOR OR ON BEHALF OF INSTITUTE, (D) USE OR
              EXPLOITATION OF THE SERVICES , COMPANY PLATFORM, OR COMPANY MATERIALS OTHER THAN AS EXPRESSLY SET FORTH IN
              THE AGREEMENT OR TERMS OF USE, OR (E) FAILURE TO IMPLEMENT ANY UPDATE, MODIFICATION, OR REPLACEMENT TO THE
              SERVICES, COMPANY PLATFORM, OR COMPANY MATERIALS PROVIDED BY COMPANY.
            </p>
            <br />

            <p className="tc-para">
              <b>CONFIDENTIALITY.</b>
              Institute shall not disclose in any form any information pertaining to the business or operations of
              Company or any of its subsidiaries, Affiliates, or group companies, including without limitation, trade
              secrets, business plans, marketing plans, or any other information shared by Company or accessed,
              collected, or received by Institute pursuant to this Agreement that may or may not be marked or designated
              as confidential (“Confidential Information”). Institute may disclose Confidential Information only to its
              employees strictly on a need-to-know basis and only to such employees, consultants, advisors,
              subcontractors, or other representatives (collectively, “Representatives”) who have a need to access such
              Confidential Information to perform its obligations under the Agreement, provided that the Representatives
              are bound by confidentiality obligations which are at least as restrictive as the terms of this Agreement.
              Save as otherwise provided herein, Institute shall not disclose any Confidential Information to any third
              party without the prior written consent of Company. Institute shall not use the Confidential Information
              for any purpose other than to perform its obligations in accordance with the terms of the Agreement.
              Institute shall take care and use all reasonable efforts to maintain the confidentiality, integrity, and
              availability of the Confidential Information that it ordinarily uses with respect to its own Confidential
              Information, which in no event shall be less than a commercially reasonable degree of care.
              <br />
              <br /> The restrictions contained in Clause 15.1 shall not apply to Confidential Information that is (a)
              lawfully in the public domain other than by breach of this Agreement; (b) is already in the lawful
              possession of the receiving Party prior to disclosure by the disclosing Party; (c) received by the
              receiving Party from a third party not bound by confidentiality obligations; (d) independently developed
              by the receiving Party without reference to the Confidential Information; or (e) required to be disclosed
              by any applicable Law or any applicable judicial, administrative, or regulatory requirements or a
              regulatory body, provided that Institute shall give Company a prompt written notice of such disclosure in
              order to enable Company to obtain a protective order. In the event Company is unable to obtain to obtain a
              protective order, Institute shall disclose only such portion of Confidential Information that is expressly
              requisitioned by applicable Law or any applicable judicial, administrative, or regulatory requirements or
              a regulatory body. <br />
              <br />
              The Institute’s obligations set forth in this clause shall survive perpetually beyond termination of the
              Agreement.
            </p>
            <h1 className="tc-heading"></h1>
            <p className="tc-para">
              <b>DISCLAIMER OF WARRANTY. </b>
              THE SERVICES AND LICENSE PROVIDED UNDER THIS AGREEMENT ARE PROVIDED ON AN &apos;AS IS&apos; AND &apos;AS
              AVAILABLE&apos; BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
              LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-
              INFRINGMENT. NO WARRANTY IS PROVIDED THAT THE SERVICES, EQUIPMENT, OR MATERIALS PROVIDED HEREUNDER WILL BE
              FREE FROM DEFECTS OR VIRUSES OR THAT OPERATION OF THE SERVICES, EQUIPMENT, OR MATERIALS PROVIDED HEREUNDER
              WILL BE UNINTERRUPTED AT ALL TIMES. USE OF THE SERVICES, EQUIPMENT, OR MATERIALS PROVIDED HEREUNDER BY
              INSTITUTE OR STUDENTS AND ANY MATERIAL OR SERVICES OBTAINED OR ACCESSED VIA THE SERVICES, EQUIPMENT, OR
              MATERIALS PROVIDED HEREUNDER IS AT THE SOLE DISCRETION AND RISK OF INSTITUTE OR STUDENTS. COMPANY AND ITS
              AFFILIATES, AND EACH OF THEIR DIRECTORS, OFFICERS, LICENSORS, SUPPLIERS, PARTNERS, AGENTS, AND
              REPRESENTATIVES MAKE NO WARRANTY THAT INSTITUTE OR STUDENTS WILL OBTAIN SPECIFIC RESULTS FROM USE OF THE
              SERVICES, EQUIPMENT, OR MATERIALS PROVIDED HEREUNDER.
            </p>
            <p className="tc-para">
              <b>Limitation of Liability.</b> IN NO EVENT SHALL COMPANY OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY
              INDIRECT, SPECIAL, REMOTE, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSS OF BUSINESS OR
              PROFITS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p className="tc-para">
              NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE AGREEMENT, IN NO EVENT SHALL THE MAXIMUM AGGREGATE
              LIABILITY OF COMPANY FOR ANY LOSSES, DAMAGES AND CLAIMS ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT
              EXCEED THE AMOUNT OF FEES PAID BY INSTITUTE IN THE 1-MONTH PERIOD IMMEDIATELY PRECEDING THE DATE OF CLAIM
              TOWARDS THE RELEVANT SERVICES GIVING RISE TO THE CLAIM.
            </p>

            <p className="tc-para">
              {" "}
              <b> RELATIONSHIP OF THE PARTIES</b> This Agreement is on a principal-to-principal basis and the Parties
              are independent of each other. Nothing contained herein is intended to or shall be deemed to create any
              partnership, joint venture, employment or relationship of principal and agent between the Parties hereto.
              The Institute shall not have any right to create any obligations on behalf of Company.
            </p>
            <br />
            <p>
              <b>FORCE MAJEURE.</b> Save for Institute’s obligations to make timely payment of Service Fees, neither
              Party will be liable for any default or delay in the performance of its obligations under this Agreement,
              if and to the extent such default or delay is caused by fire, flood, earthquake, acts of God, wars,
              terrorist attacks or any other reason beyond the reasonable control of such Party (each, a “Force Majeure
              Event”).
            </p>
            <br />
            <p className="tc-para">
              <b>GOVERNING LAW, AND JURISDICTION. </b>
              The Parties shall endeavour to resolve any disputes arising out of or in connection with this Agreement in
              an amicable manner by entering into discussions and negotiations in good faith. If the Parties are unable
              to settle the dispute within thirty (30) days from the date the dispute was brought to the notice of the
              defaulting Party, the Parties shall settle the dispute by way of arbitration.
            </p>
            <p className="tc-para">
              The arbitration proceedings shall be presided over by an arbitrator jointly appointed by the Parties. The
              arbitration proceedings shall be held in accordance with the Arbitration and Conciliation Act, 1996 and
              the rules framed thereunder till date, as amended from time to time. The venue and seat of arbitration
              shall be Bangalore, India and the arbitration proceedings shall be held in the English language.
            </p>
            <p className="tc-para">
              This Agreement shall be governed by and construed in accordance with the laws of the Republic of India.
              The Parties hereby submit to the exclusive jurisdiction of the courts in Bangalore for any matters or
              disputes arising out of or in connection with this Agreement.
            </p>
            <br />
            <br />
            <h1 className="tc-heading">GENERAL CLAUSES.</h1>
            <p className="tc-para">
              <b>Waiver:</b> No waiver of any breach of any provision of this Agreement shall constitute a waiver of any
              prior, concurrent, or subsequent breach of the same or any other provisions hereof. A waiver shall not be
              effective unless made in writing and signed by an authorised representative of the Parties hereto.
            </p>
            <p className="tc-para">
              <b>Cumulative Rights:</b> All remedies of either Company under this Agreement, whether provided herein or
              conferred by statute, civil law, common law, custom, trade, or usage are cumulative and not alternative
              and may be enforced successively or concurrently.
            </p>
            <p className="tc-para">
              <b>Injunctive Relief and Specific Performance:</b> The Parties agree that damages may not be an adequate
              remedy for Institute’s breach of this Agreement and Company shall be entitled to an injunction,
              restraining order, right for recovery, specific performance, or such other equitable relief to restrain
              the Institute from committing any violation or enforce the performance of the covenants, warranties or
              obligations contained in this Agreement.
            </p>
            <p className="tc-para">
              <b>Severability:</b> In the event any provision of this Agreement shall be void or unenforceable for any
              reason, such provision shall be considered void and not affect the remaining provisions of the Agreement.
              To the extent commercially possible, such void provision shall be replaced by a similar provision which is
              not void.
            </p>
            <p className="tc-para">
              <b>Amendment:</b> No amendment or variation of this Agreement shall be binding unless such variation is in
              writing and duly signed by all the Parties.
            </p>
            <p className="tc-para">
              <b>Assignment:</b> Institute shall not assign any of its rights or obligations under this Agreement
              without the prior written consent of Company.
            </p>
            <p className="tc-para">
              <b>Entire Agreement:</b> This Agreement constitutes the complete understanding and forms the entire
              agreement between the Parties and supersedes all prior and contemporaneous agreements between the Parties,
              whether oral or written, including without limitation any pre-printed terms and conditions of any
              proposal, quotation, document or proforma provided by the Institute. For the avoidance of doubt, it is
              hereby clarified that Company shall not be under any obligation to comply with any terms and conditions
              not expressly agreed in writing.
            </p>
            <p className="tc-para">
              <b>Anti-Bribery:</b> Institute agrees that in connection with the provision of the Services, Institute
              shall abstain from giving or receiving gifts, including without limitation, travel, or entertainment.
              Institute acknowledges that it has not violated and undertakes that it will not violate any applicable
              anti-corruption and anti-bribery laws and regulations in force in the jurisdiction where either Party is
              domiciled or operates, as well as any anti-corruption or anti-bribery laws and regulations of other
              jurisdictions that may be applicable to the transactions contemplated under this Agreement. Company shall
              have the right to suspend or terminate this Agreement forthwith upon written notice to Institute in the
              event of a breach by the Institute of this clause.
            </p>
            <p className="tc-para">
              <b>Non-Exclusive Remedies:</b> The rights and remedies available to Company under this Agreement are
              without prejudice and in addition to any other right or remedy available to Company under the contract,
              Law, or equity.
            </p>
            <p className="tc-para">
              <b>Compliance:</b> Institute shall not be bound to comply with any provisions of this Agreement if such
              compliance would be in contravention or contradictory to the law of the land. In such an event, the
              Parties will inform each other immediately and take necessary steps to comply with the applicable laws.
            </p>
            <li>
              <p className="tc-para">
                {" "}
                <b>Contact Us. Contact Us.</b> For any questions or concerns, you may contact us via email at
                <a className="a-link" href="link">
                  support.inmobius@infinitylearn.com
                </a>
                .
              </p>
              <p className="tc-para">
                You may also contact our customer support team via email at{" "}
                <a className="a-link" href="link">
                  grievances@infinitylearn.com
                </a>{" "}
                or by phone at{" "}
                <a className="a-link" href="link">
                  +918792740498
                </a>{" "}
                and{" "}
                <a className="a-link" href="link">
                  +918792740465
                </a>
                .
              </p>
              <p className="tc-para">
                For any grievances that you may have, you may also contact our Grievance Redressal Officer at the below
                mentioned details:
                <br /> Mr. Manoj Singh <br />
                Grievance Redressal Officer <br />
                RANKGURU TECHNOLOGY SOLUTIONS PRIVATE LIMITED <br />
                8, Koramangala 1A Block, Koramangala 3 Block, Koramangala, Bengaluru, Karnataka 560034 <br />
                Email ID:{" "}
                <a className="a-link" href="link">
                  manoj.singh@infinitylearn.com{" "}
                </a>
                <br />
                Phone Number: 87502 62054
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};
