import React from "react";
import { Controller } from "react-hook-form";
import { phoneNumberParserUtils } from "../../../utils/phoneNumber-parser-utils";
import CellPhoneIcon from "../../../assets/svg/LoginwithPhone";

export const PhoneInput = ({
  form,
  disabled,
  name = "phoneNumber",
  handleChange = () => {},
  isEditEnabled,
  handleEditNumberClick
}) => {
  const {
    control,
    formState: { errors }
  } = form;
  const onChangeHandler = (onChange) => (event) => {
    const { phone } = phoneNumberParserUtils(event.target.value);
    const result = phone.replace(/[^0-9]/gi, "");
    onChange({ ...event, target: { ...event.target } });
    handleChange(result);
  };
  function restrictNonNumericalCharacters(event) {
    const input = event.target;
    const regex = /^[0-9]*$/; // Regular expression to allow only numerical characters

    if (!regex.test(input.value)) {
      // If input doesn't match the regex, remove the last entered character
      input.value = input.value.replace(/\D/g, ""); // Remove any non-numeric characters
    }
  }
  const handleInputPaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const sanitizedText = pastedText.replace(/\s/g, "");
    event.preventDefault();
    document.execCommand("insertText", false, sanitizedText);
  };
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <CellPhoneIcon />
      <Controller
        control={control}
        mask="919999999999"
        name={name}
        render={({ field: { ref, onChange, ...field } }) => (
          <input
            {...field}
            ref={ref}
            type="tel"
            className={`member--inputbox phoneinput  ${errors[name] ? "error--strike" : "input-highlight"}`}
            onChange={onChangeHandler(onChange)}
            style={{ fontFamily: "montserrat" }}
            disabled={disabled}
            maxLength={10}
            onInput={restrictNonNumericalCharacters}
            required
            onPaste={handleInputPaste}
          />
        )}
      />
      {isEditEnabled && (
        <span className="edit_number" onClick={handleEditNumberClick}>
          Edit Number
        </span>
      )}
    </div>
  );
};
