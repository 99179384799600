import React from "react";
import { SoftwareEngineerSVG, SurgeonSVG } from "./icons";

export const QUESTIONS = [
  {
    question: {
      preText: "",
      text: "What are you Interested In ?",
      code: "Q1",
      idle: 1,
      question_id: "1"
    },
    answers: [
      {
        text: "NEET",
        icon: <SurgeonSVG />,
        code: "NEET"
      },
      {
        text: "JEE",
        icon: <SoftwareEngineerSVG />,
        code: "JEE Main"
      }
    ]
  }
];
