import React from "react";
import { useDispatch } from "react-redux";
import OnboardingPic from "../../../assets/images/frontend/onboardingpic.svg";
import { useSelector } from "react-redux";
import { getPlanInfo } from "../../../store/auth/selectors";
import { setUserActive } from "../../../store/auth/auth.slice";
import { CONFIG_VARS } from "../../../constants/configuration";
import { Credits } from "../../../assets/svg/schoolonboarding";

export const StepFinish = () => {
  const planInfo = useSelector(getPlanInfo);
  const dispatch = useDispatch();

  return (
    <section className="main__wrapper">
      <div className="maxw--500 text-center mx-auto">
        <img src={OnboardingPic} alt="onboardingpic" />
        <h1 className="onboarding--name">
          <strong>Congratulations!</strong>
        </h1>
        <p className="onboarding--text mt-3">You have been Onboarded to {planInfo.plan} plan.</p>
        <p className="onboarding--text mt-1">
          <span className="fade--text">Total credits:</span>
          <Credits />
          <strong>{planInfo.credits}</strong>
        </p>
        <a
          href={CONFIG_VARS.schoolLoginUrl ? CONFIG_VARS.schoolLoginUrl : "#"}
          onClick={() => {
            dispatch(setUserActive());
          }}
          className="startBtn w-100 mt-4"
        >
          Click here to login
        </a>
      </div>
    </section>
  );
};
