import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  emailSchema,
  invitationCodeSchema,
  nameSchema,
  otpCodeSchema,
  //  otpVerifiedSchema,
  passwordSchema,
  phoneNumberSchema,
  schoolNameSchema,
  schoolTypesSchema,
  gradeNameSchema,
  designationSchema,
  admissionIDSchema
} from "./schemas";

export const schoolSignUpFormResolver = yupResolver(
  yup
    .object({
      firstName: nameSchema,
      //lastName: nameSchema,
      email: emailSchema.required(),
      schoolName: schoolNameSchema.required(),
      phoneNumber: phoneNumberSchema.required(),
      otpCode: otpCodeSchema,
      invitationCode: yup.string().when("[formLoginFlag]", {
        is: (val) => val == true,
        then: (schema) => schema.notRequired(),
        otherwise: () => invitationCodeSchema.required()
      }),
      isValidInvitationCode: yup.boolean(),
      whatsappConsent: yup.bool().oneOf([true])
    })
    .required()
);

export const schoolRequestCodeResolver = yupResolver(
  yup
    .object({
      firstName: nameSchema,
      //lastName: nameSchema,
      email: emailSchema.required(),
      schoolName: schoolNameSchema.required(),
      phoneNumber: phoneNumberSchema.required(),
      otpCode: otpCodeSchema,
      whatsappConsent: yup.bool().oneOf([true])
    })
    .required()
);
export const loginFormResolver = yupResolver(
  yup
    .object({
      type: yup.number().min(1).max(2),

      admission_id: yup.string().when(["type"], {
        is: (val) => val === 1,
        then: () => admissionIDSchema.required(), //yup.string().matches(/[0-9]{7}[a-zA-Z0-9]{4}$/g, { message: "Invalid Admission ID" }),
        otherwise: (schema) => schema.notRequired()
      }),

      phoneNumber: phoneNumberSchema.when(["type"], {
        is: (val) => val === 2,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired()
      }),
      password: yup.string().when(["type"], {
        is: (val) => val === 1,
        then: (schema) => schema.min(6).required(),
        otherwise: (schema) => schema.notRequired()
      }),
      otpCode: otpCodeSchema.when(["type"], {
        is: (val) => val === 2,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired()
      })
    })
    .required()
);

export const setPhoneNumberFormResolver = yupResolver(
  yup
    .object({
      phoneNumber: phoneNumberSchema.required()
    })
    .required()
);

export const verifyOTPFormResolver = yupResolver(
  yup
    .object({
      otpCode: otpCodeSchema
    })
    .required()
);

export const resetPasswordFormResolver = yupResolver(
  yup
    .object({
      //password: passwordSchema.required(),
      newPassword: passwordSchema.required(),
      confirmPassword: yup
        .string()
        .min(6, "Password should be more than 6 letters.")
        .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    })
    .required()
);

export const studentSignUpFormResolver = yupResolver(
  yup
    .object({
      firstName: nameSchema,
      //lastName: lastNameSchema.notRequired(),
      //email: emailSchema.required(),
      gradeCode: gradeNameSchema.required(),
      // schoolName: schoolNameSchema.required(),
      //schoolCode: yup.string().min(20).required(),
      phoneNumber: phoneNumberSchema.required(),
      otpCode: otpCodeSchema,
      invitationCode: yup.string().min(1, "School code must be at least 1 character").required(),
      remember: yup.bool().oneOf([true])
      // password: passwordSchema
    })
    .required()
);

export const studentDashBoardResolver = yupResolver(
  yup.object().shape({
    country: yup
      .object({
        isoCode: yup.string().required(),
        value: yup.string().required()
      })
      .required()
  })
);

export const schoolOnBoardingResolver = yupResolver(
  yup
    .object()
    .shape({
      school_id: yup.string().min(1).required(),
      school_type: yup
        .object({
          value: schoolTypesSchema
        })
        .required(),
      board: yup.string().required(),
      branch_name: yup.string().required(),
      address: yup.string().required(),
      country: yup
        .object({
          isoCode: yup.string().required(),
          value: yup.string().required()
        })
        .required(),
      state: yup
        .object({
          isoCode: yup.string().required(),
          value: yup.string().required()
        })
        .required(),
      city: yup
        .object({
          value: yup.string().required()
        })
        .required(),
      pincode: yup.number().required(),
      primary_contact_info: yup.object({
        name: yup.string(),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
        designation: yup
          .object({
            value: designationSchema
          })
          .required()
      }),
      secondary_contact_info: yup.object().shape({
        email: yup.string().email(),
        name: yup.string()
        // phoneNumber: phoneNumberSchema
        // designation: yup.object({
        //   value: secDesignationSchema
        // })
      }),
      grade_wise_count: yup.object({
        nursery: yup.number().min(0).max(1000),
        lkg: yup.number().min(0).max(1000),
        ukg: yup.number().min(0).max(1000),
        grade_1: yup.number().min(0).max(1000),
        grade_2: yup.number().min(0).max(1000),
        grade_3: yup.number().min(0).max(1000),
        grade_4: yup.number().min(0).max(1000),
        grade_5: yup.number().min(0).max(1000),
        grade_6: yup.number().min(0).max(1000),
        grade_7: yup.number().min(0).max(1000),
        grade_8: yup.number().min(0).max(1000),
        grade_9: yup.number().min(0).max(1000),
        grade_10: yup.number().min(0).max(1000),
        grade_11: yup.number().min(0).max(1000),
        grade_12: yup.number().min(0).max(1000)
      }),
      total_student_count: yup
        .number()
        .min(0)
        .when(["grade_wise"], {
          is: (val) => !val,
          then: (schema) => schema.min(1).required(),
          otherwise: (schema) => schema.notRequired()
        }),
      total_teacher_count: yup
        .number()
        .min(0)
        .when(["grade_wise"], {
          is: (val) => !val,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired()
        }),
      other_staff_count: yup
        .number()
        .min(0)
        .when(["grade_wise"], {
          is: (val) => !val,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired()
        }),
      grade_wise: yup.boolean()
    })
    .required()
);
