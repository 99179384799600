import React, { useState } from "react";
import { StudentOnboardingWelcome } from "./steps/Welcome";
import { StudentOnboardingQuestionnaire } from "./steps/Questionnaire";
import { StudentOnboardingFinish } from "./steps/Finish";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { setUserActive } from "../../../store/auth/auth.slice";
import { useDispatch } from "react-redux";

const OnboardingSteps = Object.freeze({
  WELCOME: "welcome",
  QUESTIONNAIRE: "questionnaire",
  FINISH: "finish"
});
export const StudentOnboarding = () => {
  const history = useHistory();
  const [step, setStep] = useState(OnboardingSteps.WELCOME);
  const dispatch = useDispatch();
  return (
    <>
      {step === OnboardingSteps.WELCOME && (
        <StudentOnboardingWelcome
          onFinish={() => {
            setStep(OnboardingSteps.QUESTIONNAIRE);
          }}
        />
      )}
      {step === OnboardingSteps.QUESTIONNAIRE && (
        <StudentOnboardingQuestionnaire
          onFinish={() => {
            setStep(OnboardingSteps.FINISH);
          }}
        />
      )}
      {step === OnboardingSteps.FINISH && (
        <StudentOnboardingFinish
          onFinish={() => {
            dispatch(setUserActive());
            history.replace(ROUTES.DASHBOARD);
          }}
        />
      )}
    </>
  );
};
