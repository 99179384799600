import React from "react";

export const StudentOnboardingProgressBar = ({ percentage, prevStep }) => {
  const parsePercentage = (percentage) => {
    if ((percentage || 0) > 100) {
      return 100;
    }
    return Math.ceil(percentage || 0);
  };
  return (
    <div className="onboarding__progress__bar__combo py-2 py-md-0 px-4 px-md-0">
      <span onClick={prevStep} style={{ cursor: "pointer" }}>
        <svg width="12" height="24" viewBox="0 0 12 24" fill="none">
          <path
            d="M11.25 22.5L0.75 12L11.25 1.5"
            stroke="#EDCD6A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <div className="onboarding__progress__bar__line">
        <div className="bar__line__bar" style={{ width: `${parsePercentage(percentage)}%` }}></div>
      </div>
    </div>
  );
};
