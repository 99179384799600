import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { schoolOnBoardingResolver } from "../../../constants/resolvers";
import { ROUTES } from "../../../constants/routes";
import { useSignupSchoolFinishMutation } from "../../../store/auth/auth.api";
import { StepStart } from "./StepStart";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepFinish } from "./StepFinish";
import { getIsActive, getUserInfo } from "../../../store/auth/selectors";
import { designationOptions } from "../../../constants/designationValues";
import { phoneNumberParserUtils } from "../../../utils/phoneNumber-parser-utils";

const onboardingSteps = Object.freeze({
  START: "start",
  STEP_1: "step_1",
  STEP_2: "step_2",
  FINISH: "finish"
});

const defaultValues = (user) => ({
  school_id: user?.school_id || "",
  school_name: user?.school_name || "",
  board: "",
  branch_name: "",
  primary_contact_info: {
    name: user?.first_name,
    designation: designationOptions,
    phoneNumber: `91${user?.phone}`,
    email: user?.email
  },
  // secondary_contact_info: { designation: designationOptions, email: "", name: "" },
  grade_wise: true,
  grade_wise_count: {
    nursery: 0,
    lkg: 0,
    ukg: 0,
    grade_1: 0,
    grade_2: 0,
    grade_3: 0,
    grade_4: 0,
    grade_5: 0,
    grade_6: 0,
    grade_7: 0,
    grade_8: 0,
    grade_9: 0,
    grade_10: 0,
    grade_11: 0,
    grade_12: 0
  },
  total_student_count: 0,
  total_teacher_count: 0,
  other_staff_count: 0,
  country: {
    value: "",
    isoCode: ""
  },
  state: {
    value: "",
    isoCode: ""
  },
  city: {
    value: ""
  },
  school_type: {
    value: ""
  }
});

export const SchoolOnboarding = () => {
  const isActive = useSelector(getIsActive);
  const history = useHistory();

  const user = useSelector(getUserInfo);

  const [finish, { isSuccess: onboardingIsSuccess }] = useSignupSchoolFinishMutation();

  const form = useForm({ defaultValues: defaultValues(user), resolver: schoolOnBoardingResolver });
  const { handleSubmit, trigger, getValues } = form;

  const [step, setStep] = useState(onboardingSteps.START);
  const [category, setCategory] = useState();
  const [validPin, setValidPin] = useState(true);
  const [count, setCount] = useState([]);

  useEffect(() => {
    if (isActive) {
      history.replace(ROUTES.DASHBOARD);
    }
  }, []);

  const checkStep = (check) => {
    if (Array.isArray(check)) {
      return check.some((checkingStep) => checkStep(checkingStep));
    } else {
      return check === step;
    }
  };

  const formData = getValues();
  const onSubmit = async () => {
    const body = Object.assign({
      school_type: formData.school_type.value,
      address: formData.address,
      country: formData.country.value,
      state: formData.state.value,
      city: formData.city.value,
      tier: formData.Tier.value,
      school_id: user.school_id,
      pincode: formData.pincode,
      fee: formData.Fee.value,
      board: formData.board,
      branch_name: formData.branch_name,
      category: category,
      grade_wise: formData.grade_wise,
      total_student_count: count[0],
      total_teacher_count: formData.grade_wise ? formData.total_teacher_count : count[1],
      other_staff_count: formData.grade_wise ? formData.other_staff_count : count[2],
      grade_wise_count: formData.grade_wise_count
    });

    body.primary_contact_info = {
      name: user?.first_name,
      designation: formData.primary_contact_info.designation.value,
      phoneNumber: `91${user?.phone}`,
      email: user?.email
    };
    const isSecondaryContactFilled =
      formData.secondary_contact_info.name ||
      formData.secondary_contact_info.email ||
      formData.secondary_contact_info.phone;
    if (isSecondaryContactFilled) {
      body.secondary_contact_info = {
        ...(formData.secondary_contact_info.name != "" ? { name: formData.secondary_contact_info.name } : {}),
        ...(formData.secondary_contact_info.email != "" ? { email: formData.secondary_contact_info.email } : {}),
        ...(formData.secondary_contact_info.phone != ""
          ? { ...phoneNumberParserUtils(formData.secondary_contact_info.phone) }
          : {}),
        designation: formData.secondary_contact_info?.designation?.value || "Other POC"
      };
    } else {
      body.secondary_contact_info = null;
    }

    if (body.secondary_contact_info && !body.secondary_contact_info.phone) {
      delete body.secondary_contact_info.phone;
    }
    await finish(body);
    if (onboardingIsSuccess) {
      setStep(onboardingSteps.FINISH);
    }
  };

  useEffect(() => {
    if (onboardingIsSuccess) {
      setStep(onboardingSteps.FINISH);
    }
  }, [onboardingIsSuccess]);

  const goBack = () => {
    setStep(onboardingSteps.STEP_1);
  };

  const goToStep2 = async () => {
    const requiredFieldsStep1 = [
      "school_name",
      "school_type",
      "board",
      "branch_name",
      "address",
      "country",
      "state",
      "city",
      "pincode",
      "primary_contact_info.designation"
    ];
    const result = await trigger(requiredFieldsStep1);
    if (result && validPin) {
      setStep(onboardingSteps.STEP_2);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="main__wrapper justify-content-center position-relative">
          {checkStep(onboardingSteps.STEP_1) && (
            <div className="nextbtn__box">
              <button onClick={goToStep2} className="nextbtn__bordered">
                Continue
                <svg className="ms-3" width="8" height="14" viewBox="0 0 8 14" fill="none">
                  <path
                    d="M0.358394 12.9346C0.0367854 12.613 0.0367854 12.0915 0.358394 11.7699L5.54078 6.58755L0.358394 1.40516C0.0367854 1.08356 0.0367854 0.562125 0.358394 0.240517C0.680002 -0.0810919 1.20143 -0.0810919 1.52304 0.240517L7.28775 6.00522C7.60935 6.32683 7.60935 6.84826 7.28775 7.16987L1.52304 12.9346C1.20143 13.2562 0.680002 13.2562 0.358394 12.9346Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          )}
          {checkStep(onboardingSteps.STEP_2) && (
            <>
              <div className="nextbtn__box">
                <button type="submit" onClick={onSubmit} className="nextbtn__bordered">
                  Finish
                  <svg className="ms-3" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path
                      d="M0.358394 12.9346C0.0367854 12.613 0.0367854 12.0915 0.358394 11.7699L5.54078 6.58755L0.358394 1.40516C0.0367854 1.08356 0.0367854 0.562125 0.358394 0.240517C0.680002 -0.0810919 1.20143 -0.0810919 1.52304 0.240517L7.28775 6.00522C7.60935 6.32683 7.60935 6.84826 7.28775 7.16987L1.52304 12.9346C1.20143 13.2562 0.680002 13.2562 0.358394 12.9346Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <div className="backbtn__box">
                <button onClick={goBack} className="backbtn__bordered">
                  <svg className="me-2" width="5" height="9" viewBox="0 0 5 9" fill="none">
                    <path d="M4.5 9V0L0 4.5L4.5 9Z" fill="#263643"></path>
                  </svg>
                  Back
                </button>
              </div>
            </>
          )}
          <main className="main__content__wrapper">
            {checkStep([onboardingSteps.STEP_1, onboardingSteps.STEP_2]) && (
              <div>
                <div className="text-center">
                  <label htmlFor="" className="form__select--label font--20 font--700">
                    School Onboarding
                  </label>
                  <ul className="stepper__box mt-2 mb-4">
                    <li className="stepper-step current">
                      <div className="stepper-head">
                        <span className="stepper-head-icon">STEP 1</span>
                      </div>
                    </li>
                    <li className="stepper-step">
                      <div className="stepper-head">
                        <span className="stepper-head-icon">STEP 2</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div className="add__event importFormModal ">
              {checkStep(onboardingSteps.START) && (
                <StepStart goForward={() => setStep(onboardingSteps.STEP_1)} form={form} />
              )}
              {checkStep(onboardingSteps.STEP_1) && (
                <StepOne
                  form={form}
                  category={category}
                  setValidPin={setValidPin}
                  updateCategory={setCategory}
                  user={user}
                />
              )}
              {checkStep(onboardingSteps.STEP_2) && <StepTwo setCount={setCount} form={form} />}
              {checkStep(onboardingSteps.FINISH) && <StepFinish form={form} />}
            </div>
          </main>
        </section>
      </form>
    </>
  );
};
