export const designationOptions = [
  {
    value: "Principal",
    label: "Principal"
  },
  { value: "Management", label: "Management" },
  { value: "Administration", label: "Administration" },
  { value: "Teacher", label: "Teacher" },
  { value: "Other POC", label: "Other POC" }
];
export const regExDesignation = new RegExp(designationOptions.map((item) => item.value).join("|"));
