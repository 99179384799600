import React from "react";

export const CodingSVG = () => {
  return (
    <svg width="72" height="65" viewBox="0 0 72 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_407_112676)">
        <path
          d="M4.02636 50.6096V5.90445C4.07908 5.69358 4.15816 5.45634 4.21088 5.24547C5.05437 2.16145 7.84843 0 11.0379 0C27.6969 0 44.3295 0 60.9885 0C64.8106 0 68 3.18946 68 7.01153C68 21.1928 68 35.374 68 49.5288C68 53.3246 64.8369 56.514 61.0412 56.514C44.3559 56.514 27.6442 56.514 10.9588 56.514C9.29819 56.514 7.82207 55.9341 6.53048 54.8797C5.1598 53.7727 4.42175 52.2965 4 50.6096H4.02636ZM36.0264 17.0544C26.3789 17.0544 16.7051 17.0544 7.05767 17.0544C6.24054 17.0544 6.21416 17.0544 6.21416 17.8979C6.21416 28.2306 6.21416 38.5898 6.21416 48.9226C6.21416 49.4761 6.26688 50.056 6.42504 50.5832C7.00494 52.8764 8.95553 54.2998 11.5651 54.2998C27.8814 54.2998 44.1977 54.2998 60.514 54.2998C61.3839 54.2998 62.2537 54.168 63.0445 53.7727C65.0214 52.7447 65.8122 51.0577 65.8122 48.8962C65.8122 38.6161 65.8122 28.3361 65.8122 18.056C65.8122 17.3883 65.4783 17.0544 64.8105 17.0544C55.2158 17.0544 45.6211 17.0544 36.0264 17.0544ZM36.0264 14.7611C37.1862 14.7611 38.346 14.7611 39.5321 14.7611C47.9934 14.7611 56.4811 14.7611 64.9424 14.7611C65.8122 14.7611 65.8386 14.7611 65.8386 13.8913C65.8386 11.7562 65.8386 9.59473 65.8386 7.45964C65.8386 5.79901 65.2586 4.37562 63.9407 3.29489C62.8072 2.39868 61.4893 2.21417 60.1186 2.21417C44.3559 2.21417 28.6194 2.21417 12.8567 2.21417C12.1186 2.21417 11.3542 2.21417 10.6162 2.29325C8.48106 2.50412 6.5832 4.27018 6.37233 6.40527C6.10874 9.01483 6.31961 11.6507 6.26689 14.2603C6.26689 14.5502 6.42504 14.7348 6.71499 14.7348C6.95222 14.7348 7.1631 14.7348 7.40033 14.7348C16.9423 14.7348 26.5107 14.7348 36.0527 14.7348L36.0264 14.7611Z"
          fill="url(#paint0_linear_407_112676)"
        />
        <path
          d="M38.8956 24.5662C38.6057 25.9106 38.3157 27.4394 37.9731 28.9682C36.9187 34.0028 35.838 39.0638 34.7836 44.0984C34.7836 44.2302 34.7309 44.3356 34.7045 44.4674C34.4937 45.2582 33.9401 45.6536 33.3339 45.4954C32.6749 45.3372 32.3586 44.731 32.5167 43.9666C32.754 42.754 33.0176 41.5679 33.2812 40.3554C34.3882 35.1363 35.4953 29.8908 36.6024 24.6717C36.6551 24.4608 36.6815 24.2236 36.7606 24.0127C36.9714 23.3537 37.4723 23.011 38.0785 23.1165C38.6584 23.2219 38.9747 23.6964 38.9484 24.5662H38.8956Z"
          fill="url(#paint1_linear_407_112676)"
        />
        <path
          d="M29.1965 27.334C29.6709 27.334 29.9872 27.5185 30.1981 27.8875C30.409 28.2566 30.409 28.6519 30.1454 28.9946C29.9872 29.2318 29.75 29.4427 29.5391 29.6272C27.9049 31.0243 26.297 32.4213 24.6363 33.792C24.1882 34.161 24.241 34.3455 24.6363 34.6882C26.1652 35.9534 27.694 37.2714 29.1965 38.563C29.4864 38.8002 29.7764 39.0374 30.0136 39.3274C30.409 39.7755 30.409 40.329 30.04 40.7508C29.6709 41.1989 29.091 41.3043 28.5902 40.9617C28.2212 40.6981 27.8785 40.4081 27.5358 40.0918C25.7434 38.563 23.951 37.0342 22.1586 35.5317C20.9724 34.53 20.9724 33.9765 22.1322 32.9748C24.1355 31.2615 26.1652 29.5482 28.1685 27.8348C28.4848 27.5712 28.7747 27.334 29.1701 27.334H29.1965Z"
          fill="url(#paint2_linear_407_112676)"
        />
        <path
          d="M50.6578 34.2679C50.6578 34.6369 50.4733 34.9269 50.2097 35.1641C47.9955 37.062 45.7814 38.9598 43.5409 40.8313C42.961 41.3322 42.302 41.2531 41.8802 40.7259C41.4848 40.2251 41.5376 39.6188 42.1175 39.118C43.8044 37.6682 45.4914 36.2185 47.2048 34.7687C47.6265 34.3997 47.6353 34.0395 47.2311 33.688C45.6496 32.3437 44.068 30.9994 42.4865 29.6287C42.381 29.5233 42.2493 29.4442 42.1438 29.3387C41.5903 28.8116 41.4848 28.1789 41.9066 27.6781C42.3283 27.1773 43.0137 27.1509 43.6199 27.6518C43.8308 27.8099 44.0417 27.9944 44.2262 28.1789C46.1504 29.8132 48.101 31.4738 50.0252 33.1081C50.3679 33.3981 50.6578 33.7144 50.6315 34.2152L50.6578 34.2679Z"
          fill="url(#paint3_linear_407_112676)"
        />
        <path
          d="M15.176 8.53997C15.176 10.4378 13.6735 11.9403 11.7756 11.9403C9.90414 11.9403 8.34897 10.3588 8.37533 8.51361C8.37533 6.69483 9.9305 5.13964 11.7756 5.11328C13.6471 5.11328 15.176 6.61575 15.176 8.51361V8.53997ZM11.802 7.43289C11.1694 7.43289 10.6949 7.88099 10.6686 8.51361C10.6686 9.14623 11.0903 9.62069 11.7229 9.64705C12.3819 9.64705 12.9091 9.17259 12.9091 8.53997C12.9091 7.93371 12.4083 7.45924 11.802 7.43289Z"
          fill="url(#paint4_linear_407_112676)"
        />
        <path
          d="M25.4305 8.5927C25.4305 10.5169 23.9281 11.9667 22.0302 11.9667C20.1587 11.9667 18.6562 10.4642 18.6562 8.5927C18.6562 6.74756 20.2114 5.16602 22.0566 5.16602C23.9281 5.16602 25.4569 6.69485 25.4305 8.5927ZM23.19 8.56635C23.19 7.96008 22.6365 7.4329 22.0039 7.45926C21.3976 7.45926 20.9231 7.98644 20.9231 8.5927C20.9231 9.17261 21.4503 9.69979 22.0302 9.72615C22.6101 9.72615 23.19 9.14625 23.19 8.56635Z"
          fill="url(#paint5_linear_407_112676)"
        />
        <path
          d="M28.9378 8.56672C28.9378 6.6425 30.4403 5.19274 32.3645 5.2191C34.236 5.2191 35.7385 6.77429 35.7121 8.61943C35.6858 10.4646 34.1569 11.9934 32.3118 11.9934C30.4403 11.9934 28.9115 10.4646 28.9378 8.56672ZM32.3382 9.72652C32.9444 9.72652 33.4452 9.19933 33.4452 8.59307C33.4452 7.96045 32.8654 7.43327 32.2591 7.45963C31.6792 7.48599 31.152 8.06589 31.1784 8.64579C31.2047 9.25205 31.7319 9.75288 32.3382 9.72652Z"
          fill="url(#paint6_linear_407_112676)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_407_112676"
          x="0"
          y="0"
          width="72"
          height="64.5137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_407_112676" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_407_112676" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_407_112676"
          x1="4"
          y1="14.4908"
          x2="68.6227"
          y2="15.8762"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_407_112676"
          x1="32.4766"
          y1="28.8495"
          x2="39.0157"
          y2="28.8852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_407_112676"
          x1="21.2656"
          y1="30.8803"
          x2="30.4414"
          y2="30.9944"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_407_112676"
          x1="41.625"
          y1="30.8476"
          x2="50.7485"
          y2="30.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_407_112676"
          x1="8.375"
          y1="6.8638"
          x2="15.2429"
          y2="6.99331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_407_112676"
          x1="18.6562"
          y1="6.90978"
          x2="25.4975"
          y2="7.03879"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_407_112676"
          x1="28.9375"
          y1="6.95584"
          x2="35.7791"
          y2="7.08536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6A238" />
          <stop offset="0.442708" stopColor="#EDDC82" />
          <stop offset="1" stopColor="#A89637" />
        </linearGradient>
      </defs>
    </svg>
  );
};
