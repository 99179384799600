import React, { useState } from "react";
import { motion } from "framer-motion";
import NoEventPNG from "../../assets/images/onboarding/Noevent.png";
import { EventCardShort } from "./EventCardShort";
import loader from "../../assets/gif/newLoader.gif";
import circledown from "../../assets/svg/planerpage/ic_round-expand-circle-down.svg";
import { useSelector } from "react-redux";
import { getRegistrationPayload, getMdm } from "../../store/auth/selectors";

export const StudentPastDashBoard = ({
  pastUnAttemptedEvents,
  thisWeekEvents,
  isLoading,
  setIsLoading,
  fetchEvents,
  pastAttemptedEvents
}) => {
  const [toggleSections, setToggleSections] = useState({
    completed: true,
    missed: true
  });

  const registrationPayload = useSelector(getRegistrationPayload);
  const registerdExam = registrationPayload?.student_response[0]?.answer;
  const mdm = useSelector(getMdm);
  const grade = mdm.grade_id;

  const sortedEvents = thisWeekEvents?.slice().sort((a, b) => {
    const startTimeA = new Date(a.startRecur).getTime();
    const startTimeB = new Date(b.startRecur).getTime();
    return startTimeB - startTimeA;
  });

  const sortedPastEvents = pastUnAttemptedEvents?.slice().sort((a, b) => {
    const startTimeA = new Date(a.startRecur).getTime();
    const startTimeB = new Date(b.startRecur).getTime();
    return startTimeB - startTimeA;
  });

  const handleTryAgain = () => {
    setIsLoading(true);
    fetchEvents();
  };

  const handleToggleSection = (section) => {
    setToggleSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const sectionVariants = {
    open: { height: "auto", opacity: 1, transition: { duration: 0.3 } },
    closed: { height: 0, opacity: 0, overflow: "hidden", transition: { duration: 0.3 } }
  };

  return (
    <div className="row mt-4">
      {isLoading ? (
        <div className="loaderconstiner mt-5">
          <img className="loader" src={loader} />
          <p className="loader-para">Loading Please Wait...</p>
        </div>
      ) : sortedEvents?.length === 0 && sortedPastEvents?.length === 0 ? (
        <div className="no_testCard">
          <img className="normalIcon mb-3" src={NoEventPNG} alt="discovery" />
          <p>No Tests Scheduled for now</p>
          <p className="board__top--heading align-self-center" onClick={handleTryAgain}>
            Try Again
          </p>
        </div>
      ) : (
        <div className="Scheduleevent-cont">
          <div className="planer_exam-cards-cont">
            {pastAttemptedEvents?.length !== 0 && (
              <div className="pl_header-cont">
                <p className="pl_header-heading">Completed</p>
                <div className="planerLine"></div>
                <img
                  onClick={() => handleToggleSection("completed")}
                  className={`rotate ${toggleSections.completed ? "rotate-180" : ""}`}
                  src={circledown}
                  alt="Toggle Completed"
                />
              </div>
            )}
            <motion.div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap: "20px"
              }}
              initial={false}
              animate={toggleSections.completed ? "open" : "closed"}
              variants={sectionVariants}
            >
              {sortedEvents?.map((value, index) => (
                <EventCardShort key={index} data={value} />
              ))}
            </motion.div>
          </div>

          <div className="planer_exam-cards-cont">
            {pastUnAttemptedEvents !== undefined && pastUnAttemptedEvents?.length !== 0 && (
              <>
                <div className="pl_header-cont">
                  <p className="pl_header-heading">Missed</p>
                  <div className="planerLine"></div>
                  <img
                    onClick={() => handleToggleSection("missed")}
                    className={`rotate ${toggleSections.missed ? "rotate-180" : ""}`}
                    src={circledown}
                    alt="Toggle Missed"
                  />
                </div>
                <motion.div
                  style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "20px" }}
                  initial={false}
                  animate={toggleSections.missed ? "open" : "closed"}
                  variants={sectionVariants}
                >
                  {pastUnAttemptedEvents?.map((value, index) => {
                    const { examType, StudentExamType } = value;
                    const isStudentExamTypeMatched = examType?.includes(StudentExamType);
                    const isRegisteredExamMatched = examType?.includes(registerdExam);
                    const isGradeInRange = grade > 0 && grade < 9;
                    const isMathOlympiad = examType === "Math Olympiad";
                    const isHotsOlympiad = examType === "Hots Olympiad";

                    const shouldRenderCard =
                      isStudentExamTypeMatched ||
                      isRegisteredExamMatched ||
                      isGradeInRange ||
                      isMathOlympiad ||
                      isHotsOlympiad;

                    return shouldRenderCard ? <EventCardShort key={index} data={value} /> : null;
                  })}
                </motion.div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
