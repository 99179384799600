import React from "react";
import live from "../../assets/svg/planerpage/live.svg";
import page from "../../assets/svg/planerpage/gridicons_pages.svg";
import group from "../../assets/svg/planerpage/Group.svg";
import clock from "../../assets/svg/planerpage/mingcute_time-line.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedEventsDetails } from "../../store/auth/auth.slice";
import { ROUTES } from "../../constants/routes";

import configJson from "../../constants/question_paper_mapping_prod.json";

export const EventCardShort = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToEvent = () => {
    dispatch(setSelectedEventsDetails(data));
    history.push(ROUTES.EXAMPAGE);
  };

  const roundData = configJson?.[data.event_code]?.[data.level]?.[data.round];
  let eventConfig = null;
  if (roundData) {
    for (let key in roundData) {
      const testConfig = roundData[key][data.examType];
      if (
        testConfig &&
        Object.prototype.hasOwnProperty.call(testConfig, "question_paper_id") &&
        testConfig.question_paper_id === data.qu_id
      ) {
        eventConfig = testConfig;
        break;
      }
    }
  }

  const duration = eventConfig?.exam_duration_in_minutes;
  const noQuestions = eventConfig?.total_question;

  return (
    <div className="Planer-evnt-card">
      <div className="pl_card-header-cont">
        <div className="pl_exam-title">
          {data.title} {data.level}
        </div>
        {!data.exam_attempted && (
          <div className="pl_status-cont">
            <span>
              <img className="pl_live-img" src={live} alt="Live Icon" />
            </span>
            Live
          </div>
        )}
      </div>
      <div className="pl_group-info">
        <span>
          <img className="pl_live-img" src={group} alt="Group Icon" />
        </span>
        <span className="pl_group-text">+90.7k attempted from your city</span>
      </div>
      <div className="pl_card-header-cont">
        {duration && (
          <p className="pl_card-details">
            <span>
              <img src={clock} alt="Clock Icon" />
            </span>
            {duration} min
          </p>
        )}
        {noQuestions && (
          <p className="pl_card-details">
            <span>
              <img src={page} alt="Page Icon" />
            </span>
            {noQuestions} Questions
          </p>
        )}
      </div>
      <button onClick={redirectToEvent} className="pl_test-cta">
        {!data.exam_attempted ? "Take Test" : "View Report & Certificate"}
      </button>
    </div>
  );
};
