import React from "react";
import green from "../../../assets/svg/emsat/greendot.svg";
import red from "../../../assets/svg/emsat/reddot.svg";
import gray from "../../../assets/svg/emsat/graydot.svg";
const ColumnProgressBar = ({ totalQuestions, correctAnswers, incorrectAnswers, unanswered }) => {
  const correctPercentage = (correctAnswers / totalQuestions) * 100;
  const incorrectPercentage = (incorrectAnswers / totalQuestions) * 100;
  const unansweredPercentage = (unanswered / totalQuestions) * 100;
  const correctPercentageLine = correctPercentage - 10;
  const incorrectPercentageLine = incorrectPercentage + correctPercentage - 20;
  return (
    <>
      <div className=" EMRep_graph_card">
        <div className="EMRep-column-progress-textCont EMRep-column-progress-textCont2">
          <div className="EMRep-column-2Cont EMRep-column-2Cont1">
            <div>
              <h1>Questions Attempted</h1>
              <p>Total Qs: {totalQuestions}</p>
            </div>

            <div>
              <p className="EMRep-pointer_cont1">
                <span>
                  <img src={green} />
                </span>
                Correct Qs:<span className="EMRep-pointer_Qus">{correctAnswers}</span>
              </p>

              <p className="EMRep-pointer_cont1">
                <span>
                  <img src={red} />
                </span>
                Incorrect Qs:<span className="EMRep-pointer_Qus">{incorrectAnswers}</span>
              </p>

              <p className="EMRep-pointer_cont1">
                <span>
                  <img src={gray} />
                </span>
                Unattempted Qs:<span className="EMRep-pointer_Qus">{unanswered}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="EMRep-column-2Cont EMRep-column-progress-textCont2">
          <div className="EMRep-column-progress-barCont">
            {" "}
            <div className="EMRep-column-progress-bar">
              <div
                className="EMRep-unanswered-section"
                style={{ height: `${unansweredPercentage}%` }}
                title={`${unanswered} Unanswered`}
              ></div>
              <div
                className="EMRep-incorrect-section"
                style={{ height: `${incorrectPercentage}%` }}
                title={`${incorrectAnswers} Incorrect`}
              ></div>
              <div
                className="EMRep-correct-section"
                style={{ height: `${correctPercentage}%` }}
                title={`${correctAnswers} Correct`}
              ></div>
            </div>
            <div className="EMRep-100">100%</div>
            <div className=" EMRep-lineCont" style={{ bottom: `${Math.max(correctPercentageLine, 4)}%` }}>
              {" "}
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={green} />
                </span>
                <span className="EMRep-pointer_Qus">{Math.round(correctPercentage)}%</span>
              </p>
              <div className="EMRep-line1"></div>
            </div>
            <div
              className=" EMRep-lineCont EMRep-lineCont2"
              style={{ bottom: `${Math.max(incorrectPercentageLine, 6)}% ` }}
            >
              {" "}
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={red} />
                </span>
                <span className="EMRep-pointer_Qus">{Math.round(incorrectPercentage)}%</span>
              </p>
              <div className="EMRep-line2"></div>
            </div>
            <div className=" EMRep-lineCont EMRep-lineCont3" style={{ bottom: "75%" }}>
              {" "}
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={gray} />
                </span>
                <span className="EMRep-pointer_Qus">{Math.round(unansweredPercentage)}%</span>
              </p>
              <div className="EMRep-line3"></div>
            </div>
            <div className="EMRep-0">0%</div>
          </div>
        </div>{" "}
      </div>

      <div className=" EMRep_graph_cardMob">
        <div className=" EMRep_graph_HeadMob">
          <h6>Questions Attempted</h6>
        </div>
        <div className="EMRep-column-2Cont EMRep-column-progress-textCont2">
          <div className="EMRep-column-progress-barCont">
            {" "}
            <div className="EMRep-column-progress-bar">
              <div
                className="EMRep-unanswered-section"
                style={{ height: `${unansweredPercentage}%` }}
                title={`${unanswered} Unanswered`}
              ></div>
              <div
                className="EMRep-incorrect-section"
                style={{ height: `${incorrectPercentage}%` }}
                title={`${incorrectAnswers} Incorrect`}
              ></div>
              <div
                className="EMRep-correct-section"
                style={{ height: `${correctPercentage}%` }}
                title={`${correctAnswers} Correct`}
              ></div>
            </div>
            <div className="EMRep-100">100%</div>
            <div className=" EMRep-lineCont" style={{ bottom: `${Math.max(correctPercentageLine, 4)}%` }}>
              {" "}
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={green} />
                </span>
                <span className="EMRep-pointer_Qus">{correctAnswers}%</span>
              </p>
              <div className="EMRep-line1"></div>
            </div>
            <div
              className=" EMRep-lineCont EMRep-lineCont2"
              style={{ bottom: `${Math.max(incorrectPercentageLine, 4)}%` }}
            >
              {" "}
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={red} />
                </span>
                <span className="EMRep-pointer_Qus">{incorrectAnswers}%</span>
              </p>
              <div className="EMRep-line2"></div>
            </div>
            <div className=" EMRep-lineCont EMRep-lineCont3" style={{ bottom: "90%" }}>
              {" "}
              <p className="EMRep-pointer_cont">
                <span>
                  <img src={gray} />
                </span>
                <span className="EMRep-pointer_Qus">{unanswered}%</span>
              </p>
              <div className="EMRep-line3"></div>
            </div>
            <div className="EMRep-0">0%</div>
          </div>
        </div>
        <div className="EMRep_graph_PMobCont">
          <p>
            Total Qs:<span>{totalQuestions}</span>
          </p>
          <p>
            <span>
              <img src={green} />
            </span>{" "}
            Correct Qs : {correctAnswers}
            <span></span>
          </p>
          <p>
            <span>
              <img src={red} />
            </span>{" "}
            Inorrect Qs : {incorrectAnswers}
            <span></span>
          </p>
          <p>
            <span>
              <img src={gray} />
            </span>{" "}
            Unattempted Qs : {unanswered}
            <span></span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ColumnProgressBar;
