import React, { useEffect, useState } from "react";

import WelcomeTinyPic from "../../../../assets/images/onboarding/welcome--tinypic.png";

export const StudentOnboardingTalkingBox = ({ question, animateRef }) => {
  const [items, setItems] = useState([]);
  const add = (component) => setItems((prevState) => [...prevState, component]);
  const replace = (component) => setItems([component]);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      replace(<QLoader />);
    }, 100);

    return () => clearTimeout(loaderTimeout);
  }, []);

  useEffect(() => {
    const pretextTimeout = setTimeout(() => {
      replace(<QPreText />);
    }, 2000);

    return () => clearTimeout(pretextTimeout);
  }, []);

  useEffect(() => {
    const textTimeout = setTimeout(() => {
      add(<QText />);
    }, 3000);

    return () => clearTimeout(textTimeout);
  }, []);

  const QLoader = () => (
    <div className="aspirebox">
      <div className="snippet" data-title="dot-elastic">
        <div className="stage">
          <div className="dot-elastic"></div>
        </div>
      </div>
    </div>
  );
  const QPreText = () =>
    question.preText ? (
      <div className="aspirebox">
        <span className="text-white">{question.preText}</span>
      </div>
    ) : (
      <></>
    );
  const QText = () =>
    question.text ? (
      <div className="aspirebox">
        <span>{question.text}</span>
      </div>
    ) : (
      <div style={{ color: "#FFF" }}>text</div>
    );

  return (
    <div className="text-center bg--mobileset mt-md-5">
      <div className="bg--mobileset--item">
        <img src={WelcomeTinyPic} alt="welcome--tinypic" className="d-inline-block mx-auto img-fluid" />
        <div ref={animateRef}>
          {items.map((Value, index) => {
            return <div key={index}>{Value}</div>;
          })}
        </div>
      </div>
    </div>
  );
};
