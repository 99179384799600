import React, { useEffect, useMemo, useState } from "react";
import arrowIcon from "../../assets/images/arrow-down.png";
import useOutsideClick from "../../hooks/useOutsideClick";

export const CustomSelector = (props) => {
  const {
    data = [],
    placeHolder = null,
    defaultSelectedID = null,
    errors = {},
    dispatchValue = null,
    register,
    handleInputKeyPress
  } = props;
  const { onChange, onBlur, name } = register(dispatchValue);
  const [show, setShow] = useState(false);
  const [selectedID, setSelectedID] = useState(defaultSelectedID);
  const selectorRef = useOutsideClick(() => setShow(false));

  const parseList = useMemo(() => {
    if (placeHolder && !data.find(({ id }) => id === 0)) {
      if (!defaultSelectedID && defaultSelectedID < 0) {
        setSelectedID(0);
      }
      data.unshift({ id: 0, value: placeHolder });
    }

    return data.map((props) => ({
      ...props,
      selected: selectedID === props.id,
      onClick: () => {
        if (props.id === 0) {
          setSelectedID(props.id);
          onChange({ target: { name, value: "" } });
        } else {
          setSelectedID(props.id);
          onChange({ target: { name, value: props.id } });
        }
      }
    }));
  }, [placeHolder, selectedID, data]);

  const selectedItem = useMemo(
    () => parseList.find(({ id }) => id === selectedID) || parseList[0],
    [selectedID, parseList]
  );

  useEffect(() => {
    if (selectedItem && selectedItem.id !== 0) {
      onChange({ target: { name, value: selectedItem?.id } });
    }
    selectedItem.name = "gradeId";
    handleInputKeyPress(selectedItem);
  }, [selectedItem]);
  return (
    <div className="col-12 mb-12">
      <select className="nice-select--grade custom--select member--inputbox" required style={{ display: "none" }} />
      <button
        className={`
        nice-select nice-select--grade custom--select member--inputbox
        ${errors[name] || selectedID === 0 ? "error--strike" : ""}
        ${selectedID !== 0 ? "border-highlight" : ""}
      `}
        ref={selectorRef}
        onClick={() => setShow((prev) => !prev)}
        style={{
          position: "relative",
          textAlign: "left",
          justifyContent: "space-between",
          display: "flex",
          fontFamily: "Source Code Pro",
          fontWeight: 400
        }}
        type={"button"}
        onBlur={onBlur}
        disabled={props.disabled}
      >
        <span className="current" style={{ color: selectedID !== 0 ? "#FFF" : "#FFFFFFB2" }}>
          {selectedItem?.value}
        </span>
        {show && (
          <ul
            className="list simplebar-scrollable-y"
            data-simplebar="init"
            style={{ position: "absolute", left: 0, top: 50, zIndex: 10, padding: 0 }}
          >
            {parseList.map(({ id, value, selected, onClick }) => (
              <li
                key={id + value}
                data-value={id}
                className={`option ${selected ? "selected focus" : ""}`}
                onClick={onClick}
              >
                {value}
              </li>
            ))}
          </ul>
        )}
        <img className="select-arrow" src={arrowIcon} alt="arrow" width="16" />
      </button>
      {errors[name] && <span className="error--strike--text">{errors[name].message}</span>}
    </div>
  );
};
