import React, { useEffect, useState } from "react";
import { StudentSignUpForm } from "./auth-widget/student-auth-widget/forms/StudentSignUpForm";
import { ROUTES } from "../constants/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TNCModal } from "./components/modal/t&cModal";
import { PNPModal } from "./components/modal/p&pModal";
export const StudentSignUpWidget = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isHomePage = history.location.pathname === ROUTES.HOME;
  const content = t("home_page.p1_content");
  const [showTAndC, setShowTAndC] = useState(false);
  const [showPAndP, setShowPAndP] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the route changes
  }, []);
  const handleCloseModal = () => {
    setShowTAndC(false);
    setShowPAndP(false);
  };
  return (
    <>
      {showTAndC && <TNCModal handleClose={handleCloseModal} />}
      {showPAndP && <PNPModal handleClose={handleCloseModal} />}
      <header className="frontheader__banner">
        <div className="white__layerbg"></div>
        <div className="banner__contentbox no_auth">
          <div className="container">
            <div className="row">
              <div className={`col-lg-${isHomePage ? "12" : "7"}`}>
                <h1 className="banner__contentbox--title">
                  <span className="text--secondary--grad">{t("home_page.p1_heading")}</span>
                </h1>
                <p className="banner__contentbox--text mt-2 pt-1">{content}</p>
              </div>
              <div className="col-md-6 col-lg-5 position-relative">
                <div className="membership__card membership__card_mob mx-auto ms-md-auto">
                  <div className="tab-content">
                    <h3 className="tab-heading">Create Account </h3>
                    <StudentSignUpForm
                      toggleTAndC={() => setShowTAndC(!showTAndC)}
                      togglePAndP={() => setShowPAndP(!showPAndP)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
