import React from "react";
import yellow from "../../../assets/svg/emsat/yellowdot.svg";
import blue from "../../../assets/svg/emsat/sopebluedote.svg";
import { EMsatToolTip } from "./EMsatTooltip";
import green from "../../../assets/svg/emsat/greendot.svg";
import red from "../../../assets/svg/emsat/reddot.svg";
import gray from "../../../assets/svg/emsat/graydot.svg";
const EMsatTimeManagement = ({ totalTime, Avgtime, Idealtime, correctAnswers, incorrectAnswers, unanswered }) => {
  const secondsToMinutes = (seconds) => (seconds / 60).toFixed(2);
  const getAvgtime = () => {
    if (totalTime && Avgtime) {
      return `${(Avgtime / totalTime) * 100}%`;
    }
  };
  const getIdealtime = () => {
    if (totalTime && Idealtime) {
      return `${(secondsToMinutes(Idealtime) / totalTime) * 100}%`;
    }
  };
  const getAvgCorrecttime = () => {
    if (totalTime && Avgtime) {
      return `${(correctAnswers / totalTime) * 200}px`;
    }
  };
  const getAvgIncorrecttime = () => {
    if (totalTime && Idealtime) {
      return `${(incorrectAnswers / totalTime) * 200}px`;
    }
  };
  const getAvgUnanserdtime = () => {
    if (totalTime && Avgtime) {
      return `${(unanswered / totalTime) * 200}px`;
    }
  };
  return (
    <>
      <div className="Emsat-Rep_Sub_cont ">
        <div className="EMRep-subcontainer">
          <div className="EMRep-subcontainer1">
            <p className="EMRep-AvgText">
              <span>
                <img src={yellow} />
              </span>{" "}
              Your Avg. Time/Question: <span className="EMRep-value">{secondsToMinutes(Avgtime)}</span>
            </p>
            <div className="EMRep-horydashedLine"></div>
            <p className="EMRep-AvgText">
              <span>
                <img src={blue} />
              </span>{" "}
              Ideal Avg. Time/ Question: <span className="EMRep-value">{secondsToMinutes(Idealtime)} </span>
              <span>
                {" "}
                <EMsatToolTip>
                  <p className="EMRe_info">i</p>
                </EMsatToolTip>
              </span>
            </p>
          </div>
          <div className="EMRep-subcontainer2">
            <div className="EMsat-barchart " style={{ height: getAvgtime() }}></div>
            <div className="EMsat-barchart " style={{ height: getIdealtime(), backgroundColor: "#9FD6FF" }}></div>
          </div>
        </div>
        <div className="EMRep-verticalLine"></div>

        <div className="EMRep-TM-secCont">
          <div className="EMRep-TM-subcotainer">
            <div className="EMRep-TM-heading">Average Time / Question </div>
            <div className="EMRep-TM-textCont">
              <div className="EMRep-TM-INertextCont">
                <p className="EMRep-TM-text">
                  <span>
                    <img src={green} alt="green dot" />
                  </span>
                  Correct Qs:<span className="EMRep-pointer_Qus">{Math.round(correctAnswers)}m</span>
                </p>
              </div>
              <div className="EMRep-TM-INertextCont">
                <p className="EMRep-TM-text">
                  <span>
                    <img src={red} alt="green dot" />
                  </span>
                  Inorrect Qs:<span className="EMRep-pointer_Qus">{Math.round(incorrectAnswers)}m</span>
                </p>
              </div>
              <div className="EMRep-TM-INertextCont">
                <p className="EMRep-TM-text">
                  <span>
                    <img src={gray} alt="green dot" />
                  </span>
                  Unanser Qs:<span className="EMRep-pointer_Qus">{Math.round(unanswered)}m</span>
                </p>
              </div>
            </div>
          </div>
          <div className="EMRep-TM-subcotainer">
            <div className="EMRep-TM-barcont">
              <div
                className="EMsat-barprogress "
                style={{ height: getAvgCorrecttime(), backgroundColor: "#00BA88" }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{ height: getAvgIncorrecttime(), backgroundColor: "#B7295A" }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{ height: getAvgUnanserdtime(), backgroundColor: "#D9DBE9" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="Emsat-Rep_Sub_contMob ">
        <div className="EMRep-subcontainer">
          <div className="EMRep-subcontainer2">
            <div className="EMsat-barchart " style={{ height: getAvgtime() }}></div>
            <div className="EMsat-barchart " style={{ height: getIdealtime(), backgroundColor: "#9FD6FF" }}></div>
          </div>
          <div className="EMRep-subcontainer1">
            <p className="EMRep-AvgText">
              <span>
                <img src={yellow} />
              </span>{" "}
              Your Avg. Time/Question: <span className="EMRep-value">{secondsToMinutes(Avgtime)} </span>
            </p>
            <p className="EMRep-AvgText">
              <span>
                <img src={blue} />
              </span>{" "}
              Your Avg. Time/Question: <span className="EMRep-value">{secondsToMinutes(Idealtime)} </span>
              <span> </span>
            </p>
          </div>
        </div>
        <div className="EMRep-verticalLine"></div>

        <div className="EMRep-TM-secContMOb">
          <div className="EMRep-TM-heading">Average Time / Question </div>

          <div className="EMRep-TM-subcotainer">
            <div className="EMRep-TM-barcont">
              <div
                className="EMsat-barprogress "
                style={{ height: getAvgCorrecttime(), backgroundColor: "#00BA88" }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{ height: getAvgIncorrecttime(), backgroundColor: "#B7295A" }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{ height: getAvgUnanserdtime(), backgroundColor: "#D9DBE9" }}
              ></div>
            </div>
          </div>
          <div className="EMRep_graph_PMobCont">
            <p>
              <span>
                <img src={green} />
              </span>{" "}
              Correct Qs : {Math.round(correctAnswers)}m
            </p>
            <p>
              <span>
                <img src={red} />
              </span>{" "}
              Inorrect Qs : {Math.round(incorrectAnswers)}m
            </p>
            <p>
              <span>
                <img src={gray} />
              </span>{" "}
              Unattempted Qs : {Math.round(unanswered)}m
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EMsatTimeManagement;
