import React from "react";
import { Controller } from "react-hook-form";
import { phoneNumberParserUtils } from "../../../utils/phoneNumber-parser-utils";

export const PhoneInputWhite = ({ form, disabled, name = "phoneNumber", handleChange = () => {} }) => {
  const {
    control,
    formState: { errors }
  } = form;
  const onChangeHandler = (onChange) => (event) => {
    const { phone } = phoneNumberParserUtils(event.target.value);
    const result = phone.replace(/[^0-9]/gi, "");
    onChange({ ...event, target: { ...event.target } });
    handleChange(result);
  };
  function restrictNonNumericalCharacters(event) {
    const input = event.target;
    const regex = /^[0-9]*$/; // Regular expression to allow only numerical characters

    if (!regex.test(input.value)) {
      // If input doesn't match the regex, remove the last entered character
      input.value = input.value.replace(/\D/g, ""); // Remove any non-numeric characters
    }
  }
  const handleInputPaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const sanitizedText = pastedText.replace(/\s/g, "");
    event.preventDefault();
    document.execCommand("insertText", false, sanitizedText);
  };
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Controller
        control={control}
        mask="919999999999"
        name={name}
        render={({ field: { ref, onChange, ...field } }) => (
          <input
            {...field}
            ref={ref}
            type="tel"
            className={`form__input--box w-100 ${errors[name] ? "error--strike" : ""}`}
            onChange={onChangeHandler(onChange)}
            disabled={disabled}
            maxLength={10}
            onInput={restrictNonNumericalCharacters}
            onPaste={handleInputPaste}
          />
        )}
      />
    </div>
  );
};
