import React from "react";

export const PageNotFound = () => {
  return (
    <div className="page-not-found-wrapper">
      <p className="title1">404</p>
      <p className="title2">PAGE NOT FOUND</p>
    </div>
  );
};
