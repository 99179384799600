import React, { useState } from "react";
import RightDot from "../../assets/svg/reportspage/ArrowRightCircle.svg";
import WrongDot from "../../assets/svg/reportspage/ArrowWrongCircle.svg";
import Gray from "../../assets/svg/reportspage/graydot.svg";
import CircleProgressBar from "./circleProgressBar";
import persent from "../../assets/svg/reportspage/persent.svg";
import up from "../../assets/svg/reportspage/up.svg";
import down from "../../assets/svg/reportspage/down.svg";
import time from "../../assets/svg/reportspage/time.svg";
import target from "../../assets/svg/reportspage/target.svg";
const AdvanceReport = ({ advanceReport }) => {
  const subjects = advanceReport?.sections?.map((report) => report.SECTION);
  const [selectedButton, setSelectedButton] = useState(subjects[0]);
  const [expandedChapters, setExpandedChapters] = useState({});

  const handleButtonClick = (subject) => {
    setSelectedButton(subject);
  };

  const handleExpand = (index) => {
    setExpandedChapters((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const selectedReport = advanceReport?.sections?.find((report) => report.SECTION === selectedButton);

  return (
    <div>
      <h3 className="Rep_sub-head">Section-wise breakdown</h3>
      <div>
        {advanceReport?.sections?.map((value, key) => (
          <button
            key={key}
            onClick={() => handleButtonClick(value.SECTION)}
            className={`tab_subject ${selectedButton === value.SECTION ? "selected_tab" : ""}`}
          >
            {value.SECTION}
          </button>
        ))}
      </div>

      {selectedReport && (
        <div className="Rep_card-cont">
          <div className="Rep_score_card Rep_score_card3">
            <div className="saparator_v"></div>
            <div className="saparator_h"></div>
            <div className="Rep_score_card2">
              <div className="Rep_score_cardinner">
                <img src={persent} alt="percentage icon" />
                <div>
                  <h3 className="Rep_score_inner_title">Score</h3>
                  <p className="Rep_score_inner_marks">
                    {selectedReport.MARKS_ACHIEVED}/{selectedReport.TOTAL_MARKS}
                  </p>
                </div>
              </div>
              <div className="Rep_score_cardinner">
                <img src={target} style={{ width: "2.5rem" }} alt="percentage icon" />
                <div>
                  <h3 className="Rep_score_inner_title">Accuracy</h3>
                  <p className="Rep_score_inner_marks">{`${selectedReport?.ACCURACY || 0}%`}</p>{" "}
                </div>
              </div>
            </div>
            <div className="Rep_score_card2">
              <div className="Rep_score_cardinner">
                <img src={time} style={{ width: "2.5rem" }} alt="percentage icon" />
                <div>
                  <h3 className="Rep_score_inner_title">Time Taken</h3>
                  <p className="Rep_score_inner_marks">{selectedReport.TOTAL_TIME_TAKEN} M</p>
                </div>
              </div>
              <div className="Rep_score_cardinner">
                <img src={time} style={{ width: "2.5rem" }} alt="percentage icon" />
                <div>
                  <h3 className="Rep_score_inner_title">Avg Time</h3>
                  <p className="Rep_score_inner_marks">{selectedReport.AVG_TIME_TAKEN} M</p>
                </div>
              </div>
            </div>
          </div>

          <CircleProgressBar
            totalMarks={selectedReport.TOTAL_MARKS}
            correctMarks={selectedReport.CORRECT}
            incorrectMarks={selectedReport.INCORRECT}
            unattempted={selectedReport.UNATTEMPTED}
          />
        </div>
      )}

      <div className="AdRp-main-cont">
        <div className="AdRp-header-cont">
          <p className="AdRp-header-contp1">Chapter</p>
          <p className="AdRp-header-contp2">Total</p>
          <p className="AdRp-header-contp3">Correct/Wrong/Unattempted</p>
          <p className="AdRp-header-contp4">Marks Achieved</p>
        </div>
        {selectedReport?.chapters?.map((chapter, index) => {
          const chapterNameWords = chapter.CHAPTER.split(" ");
          const isLongChapterName = chapterNameWords.length > 2;
          const chapterStyle = {
            fontSize: isLongChapterName ? "12px" : "inherit"
          };

          const performanceStyle = {
            color: chapter.PERFORMANCE_METRICS === "Keep it up" ? "#2CBE6A" : "#FF5353",
            marginBottom: isLongChapterName ? "1rem" : "0rem"
          };

          return (
            <div key={index} className="AdRp-bodyitems">
              <div className="AdRp-bodyitems1">
                <h3 style={chapterStyle}>{chapter.CHAPTER}</h3>
                <p style={performanceStyle}>{chapter.PERFORMANCE_METRICS}</p>
              </div>
              <div className="AdRp-bodyitems2">{chapter.TOTAL_QUESTIONS_ASKED}</div>
              <div className="AdRp-bodyitems3">
                <img src={RightDot} alt="Correct Icon" /> <p>{chapter.QUESTION_STATUS_CORRECT}</p>
                <img src={WrongDot} alt="Wrong Icon" /> <p>{chapter.QUESTION_STATUS_WRONG}</p>
                <img src={Gray} alt="Unattempted Icon" /> <p>{chapter.QUESTION_STATUS_UNATTEMPTED}</p>
              </div>
              <div className="AdRp-bodyitems4">
                <p>
                  <span>{chapter.MARKS_SCORED}</span>/{chapter.TOTAL_MARKS}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      {selectedReport?.chapters?.map((chapter, index) => {
        const chapterNameWords = chapter.CHAPTER.split(" ");
        const isLongChapterName = chapterNameWords.length > 4;
        const chapterStyle = {
          fontSize: isLongChapterName ? "12px" : "inherit",
          lineHeight: isLongChapterName ? "20px" : "10px"
        };

        const performanceStyle = {
          color: chapter.PERFORMANCE_METRICS === "Keep it up" ? "#2CBE6A" : "#FF5353",
          marginBottom: isLongChapterName ? "1rem" : "0rem"
        };

        return (
          <div key={index} className="AdRp-main-contMob">
            <div className="AdRp-bodyitems1">
              <h3 style={chapterStyle}>{chapter.CHAPTER}</h3>
              <p style={performanceStyle}>{chapter.PERFORMANCE_METRICS}</p>
              <div className="AdRp-marksCont">
                <h4>Marks Achieved</h4>
                <p>
                  <span>{chapter.MARKS_SCORED}</span>/{chapter.TOTAL_MARKS}
                </p>
                <img
                  className="AdRp-mob-epandIcons"
                  onClick={() => handleExpand(index)}
                  src={!expandedChapters[index] ? down : up}
                  alt="arrow"
                />
              </div>
            </div>
            {expandedChapters[index] && (
              <div className="AdRp-inMob-cont">
                <div className="AdRp-header-cont">
                  <p className="AdRp-header-contp2">Qs</p>
                  <p className="AdRp-header-contp3">Correct/Wrong/Unattempted</p>
                </div>
                <div className="AdRp-bodyitems">
                  <div className="AdRp-bodyitems2">{chapter.TOTAL_QUESTIONS_ASKED}</div>
                  <div className="AdRp-bodyitems3">
                    <img src={RightDot} alt="Correct Icon" />
                    <p>{chapter.QUESTION_STATUS_CORRECT}</p>
                    <img src={WrongDot} alt="Wrong Icon" />
                    <p>{chapter.QUESTION_STATUS_WRONG}</p>
                    <img src={Gray} alt="Unattempted Icon" />
                    <p>{chapter.QUESTION_STATUS_UNATTEMPTED}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AdvanceReport;
